.video-list {
&-content{
    color: #282828;
    .parent {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, auto);
      .div1 { grid-area: 1 / 1 / 2 / 5; }
      .div2 { grid-area: 2 / 1 / 3 / 5; }
      @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(3, auto);
        .div1 { grid-area: 1 / 2 / 2 / 4; }
      .div2 { grid-area: 1 / 5 / 2 / 8; }
      }
      
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      }
      
      

      &__item{
        background-color: #fff;
        border-top:1px solid rgba(0,0,0,0.15);
          padding:100px 0px;
        &:nth-child(2n) {
          background: #fff;
        }
      }
      &__card{
        display: flex;
        flex-direction: column;
        gap: 32px;
        position: relative;
        
       
        &-title{
          font-size:32px;
          line-height: 1em;
          letter-spacing:-0.05em;
          font-family: Eina01-Bold;
          color:#282828;
          position: relative;
          margin-bottom: 32px;
          @media screen and (min-width: 1024px) {
            margin-bottom:0px;
          }
        }
        &-list{
          &__item {
            
            button {
              display: flex;
              gap: 8px;
              padding:8px 0px;
              margin:8px 0px;
              transition:all ease .2s;
              span.icon{
                svg {
                  fill:#aaa;
                transition:all ease .2s;

                }
              }
              span.label{
                transition:all ease .2s;
                font-size:18px;
                line-height: 1.3em;
                letter-spacing:-0.025em;
                font-family: Eina01;
                color:rgba(#282828,0.8);
                font-weight: 700;
                position: relative;
                top:2px;
                text-align: left;

              }
              &:hover {
                span.icon{
                  svg{
                    fill:#282828;
                  }
                }
                span.label{
                  color:#282828;
                }
              }
            }
            position: relative;
            max-width: 768px;
          }
        }
        
      }
  }
}