.get-started {
    &-bg{
        background-color: #47ccdf;;
        padding:16rem 0 8rem;
    }
    &__wrapper{

    }

    &-btn{
            transform:scale(1);
            position: relative;
            padding: 22px;
            background-color: #282828;
            font-family: Eina04;
            font-size: 16px;
            font-weight: bold;
            color: white;
            margin-top:0.5em;
            width:100%;
            max-width:320px;
            outline:none;
            transition:all ease .25s;
            &:hover{
                transform:scale(1.1);
            }
            &:focus,&:hover,&:active{
              outline:0;
              
            }
            @media screen and (min-width: 768px) {
              padding: 24px 64px;
              width:auto;
              
            }
            @media screen and (min-width: 1280px) {
              width:auto;
              
            }
    }
}

.get-started-title {
        font-size:32px;
        line-height: 1em;
        margin-bottom:4px;
        letter-spacing:-0.05em;
        font-family: Eina01-Bold;
        color:#282828;
        text-align: center;
        width:100%;
        position: relative;
        &:before{
          position: absolute;
          top: -64px;
          left: 50%;
          width: 24px;
          height: 24px;
          background-color: #282828;
          content: "";
          transform: translateX(-50%);
        }
        &:after{
          position: absolute;
          top: -68px;
          left: 51%;
          width: 16px;
          height: 16px;
          background-color: #fff;
          content: "";
          transform: translateX(-50%);
          z-index: 1;

        }
  
    
        @media screen and (min-width: 768px) {
          font-size:48px;
          line-height: 1em;
          max-width: 540px;
          margin-bottom:24px;
          letter-spacing:-0.05em;
        }
    
        @media screen and (min-width: 1024px) {
          font-size:60px;
          line-height: 1em;
          max-width: 768px;
          margin-bottom:24px;
          letter-spacing:-0.05em;
        }
    
        @media screen and (min-width: 1280px) {
          font-size:72px;
          line-height: 1em;
          max-width: 1024px;
          margin-bottom:24px;
          letter-spacing:-0.05em;
        }
        
        span.highlight{
          color:#fff;
      }
      span.highlight-gray{
        color:#aaa;
      }
}