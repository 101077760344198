.security {
  &-certificates {
    padding: 100px 0px;

    &-bg {
      background-color: #282828;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      border-top: 1px solid #282828;
      position: relative;
      color: #282828;
      padding: 64px 0px 128px;

      &__bottom {
        padding-bottom: 0px;
        overflow: hidden;
      }

      //default, large-both, small-both, large-top, large-bottom, small-both, small-top, small-bottom
      &--default {
        padding: 64px 0px 128px;
      }

      &--large-both {
        padding: 200px 0px 200px;
      }

      &--large-top {
        padding-top: 200px;
      }

      &--large-bottom {
        padding-bottom: 200px;
      }

      &--small-both {
        padding: 64px 0px 64px;
      }

      &--small-top {
        padding-top: 64px;
      }

      &--small-bottom {
        padding-bottom: 64px;
      }

      &--light {
        background-color: #fff;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        color: #282828;

        .hero-text-tout-with-list__title-primary {
          color: #282828;

        }

        .hero-text-tout-with-list__title-secondary {
          color: rgba(#282828, 0.5);
        }
      }

      &--cyan {
        background-color: #47ccdf;
        border: 0px;
        color: #282828;
        padding-bottom: 200px;

        .hero-text-tout-with-list__title-primary {
          color: #282828;

        }

        .hero-text-tout-with-list__title-secondary {
          color: rgba(#282828, 0.5);
        }
      }
    }

    &__wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;

      @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(8, 1fr);

      }

    }

    &__inner {
      grid-area: 1 / 1 / 2 / 5;

      @media screen and (min-width: 1024px) {
        grid-area: 1 / 2 / 2 / 8;
      }
    }

    &__title {
      font-size: 32px;
      color: #282828;
      font-family: Eina01-Bold;
      letter-spacing: -0.05em;
      line-height: 1.1em;
      text-align: center;
      margin-bottom: 32px;

      @media screen and (min-width: 1024px) {
        font-size: 48px;
      }

      @media screen and (min-width: 1280px) {
        font-size: 72px;
      }

    }

    &__content {

      margin: 32px 0px;
      padding: 24px;

      @media screen and (min-width: 1280px) {
        margin: 64px 0px;
        padding: 64px;

      }

      &__wrapper {}

      &-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto;
        grid-row-gap: 32px;

        @media screen and (min-width: 768px) {
          grid-row-gap: 0px;
          grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (min-width: 1280px) {
          grid-template-columns: repeat(4, 1fr);
        }

        &__wrapper {
          @media screen and (min-width: 1280px) {
            margin: 32px 0px 0px;

          }
        }

        &__item {
          position: relative;
          background-color: #fff;
          color: #282828;
          font-family: Eina01;
          font-weight: bold;
          letter-spacing: -0.025em;
          line-height: 1.3em;
          text-align: center;
          border: 1px solid #ddd;
          padding: 24px 36px 48px;
          transform: scale(1);
          transition: all ease .2s;
          z-index: 0;

          &:hover {
            transform: scale(1.1);
            z-index: 1;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);

            img {
              opacity: 1;
            }
          }

          &-image {
            margin-bottom: 32px;

          }

          &-title {
            font-size: 16px;
            color: #282828;
            font-family: Eina01-Bold;
            letter-spacing: -0.05em;
            line-height: 1.1em;
            text-align: center;
            margin-bottom: 8px;

          }

          &-desc {
            font-size: 14px;
            color: rgba(#282828, 0.6);
            font-family: Eina01;
            letter-spacing: -0.025em;
            line-height: 1.1em;
            font-weight: bold;
            text-align: center;
            margin-bottom: 8px;
          }
        }
      }
    }

  }
}