html {
  overflow-x:hidden;
}
body {
  //background: #282828;
  color: #fff;
  position: relative;
  font-family: Eina01, Helvetica, Helvetica Neue, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}


*:focus,
*:hover,
*:active {
  outline: 0;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper>div {
  position: absolute;
}

.route-wrapper {
  position: relative;
}

.route-wrapper>div {
  position: absolute;
  width: 100%;
}


.loading-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #282828;
}

@keyframes crescendo {
  0% {
    transform: scale(.8);
  }

  100% {
    transform: scale(1.5);
  }
}

.loading-inner {
  width: 10px;
  height: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #47CCDF;
  animation: crescendo 1.5s alternate infinite ease-in;
}


body:before {
  z-index: 333;
  //content: "";
  pointer-events: none;
  display: grid;
  grid-template-columns: repeat(4, minmax(25%, 1fr));
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  max-width: 80%;
  padding: 0 10%;
  margin: auto;
  background: -webkit-linear-gradient(0deg,
      rgba(0, 0, 0, 0.05) 1px,
      transparent 0px) 0 0 / 25% auto;
  //mix-blend-mode: luminosity;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  transform: translateZ(0);

  @media screen and (min-width: 640px) {
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    padding: 0;
    max-width: 512px;
    grid-template-columns: repeat(4, minmax(128px, 1fr));
    background: -webkit-linear-gradient(0deg,
        rgba(0, 0, 0, 0.05) 1px,
        transparent 0px) 128px 0 / 128px auto;
  }

  @media screen and (min-width: 768px) {
    padding: 0;
    max-width: 614.406px;
    grid-template-columns: repeat(4, minmax(153.39px, 1fr));
    background: -webkit-linear-gradient(0deg,
        rgba(0, 0, 0, 0.05) 1px,
        transparent 0px) 153.39px 0 / 153.39px auto;
  }

  @media screen and (min-width: 1024px) {
    max-width: 1024px;
    grid-template-columns: repeat(8, minmax(128px, 1fr));
    background: -webkit-linear-gradient(0deg,
        rgba(0, 0, 0, 0.05) 1px,
        transparent 0px) 128px 0 / 128px auto;
  }

  @media screen and (min-width: 1280px) {
    max-width: 1280px;
    grid-template-columns: repeat(8, minmax(160px, 1fr));
    background: -webkit-linear-gradient(0deg,
        rgba(0, 0, 0, 0.05) 1px,
        transparent 0px) 160px 0 / 160px auto;
  }

  @media screen and (min-width: 1600px) {
    max-width: 1600px;
    grid-template-columns: repeat(8, minmax(200px, 1fr));
    background: -webkit-linear-gradient(0deg,
        rgba(0, 0, 0, 0.05) 1px,
        transparent 0px) 200px 0 / 200px auto;
  }
}

body:after {
  z-index: 333;
  //content: "";
  pointer-events: none;
  display: grid;
  grid-template-columns: repeat(4, minmax(25%, 1fr));
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  max-width: 80%;
  padding: 0 10%;
  //mix-blend-mode: overlay;
  margin: auto;
  background: -webkit-linear-gradient(0deg,
      rgba(255, 255, 255, 0.05) 1px,
      transparent 0px) 0 0 / 25% auto;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  border-right: 1px solid rgba(255, 255, 255, 0.05);

  @media screen and (min-width: 640px) {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
    padding: 0;
    max-width: 512px;
    grid-template-columns: repeat(4, minmax(128px, 1fr));
    background: -webkit-linear-gradient(0deg,
        rgba(255, 255, 255, 0.05) 1px,
        transparent 0px) 128px 0 / 128px auto;
  }

  @media screen and (min-width: 768px) {
    padding: 0;
    max-width: 614.406px;
    grid-template-columns: repeat(4, minmax(153.39px, 1fr));
    background: -webkit-linear-gradient(0deg,
        rgba(255, 255, 255, 0.05) 1px,
        transparent 0px) 153.39px 0 / 153.39px auto;
  }

  @media screen and (min-width: 1024px) {
    max-width: 1024px;
    grid-template-columns: repeat(8, minmax(128px, 1fr));
    background: -webkit-linear-gradient(0deg,
        rgba(255, 255, 255, 0.05) 1px,
        transparent 0px) 128px 0 / 128px auto;
  }

  @media screen and (min-width: 1280px) {
    max-width: 1280px;
    grid-template-columns: repeat(8, minmax(160px, 1fr));
    background: -webkit-linear-gradient(0deg,
        rgba(255, 255, 255, 0.05) 1px,
        transparent 0px) 160px 0 / 160px auto;
  }

  @media screen and (min-width: 1600px) {
    max-width: 1600px;
    grid-template-columns: repeat(8, minmax(200px, 1fr));
    background: -webkit-linear-gradient(0deg,
        rgba(255, 255, 255, 0.05) 1px,
        transparent 0px) 200px 0 / 200px auto;
  }
}