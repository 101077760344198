
.product-feature-list{
    &-bg{
        background-color: #47ccdf;
        position: relative;
        color:#282828;
        padding:128px 0px;
        border-top:1px solid rgba(0,0,0,0.05);
   
    }
    &__container{
      position: relative;
    }
    &__link {
      margin-top:64px;
      border-bottom:1px solid rgba(#282828, 0.25);
      transform:scale(1);
      transition:all ease 200ms;
      &-wrapper {
        display:flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        border-bottom:1px solid #282828;
        transform:scale(1.25);
        
      }
      &-icon{
        display: inline-block;
        svg {
          height:14px;
          fill:#282828;
          position: relative;
          left:-2px;
          top:2px;
        }
      }
      &-desc{
        color:#282828;
        display: inline-block;
        font-size:14px;
        font-weight: bold;
        padding-left:12px;
      }
    }

    &__wrapper{
    }
    &__inner{
      .parent {
        display: grid;
        grid-template-columns: repeat(4, minmax(25%, 1fr));
        grid-template-rows: repeat(10, auto);
        grid-column-gap: 0px;
        grid-row-gap: 20px;
        
        .div1 { grid-area: 1 / 1 / 2 / 5; }
        .div2 { grid-area: 2 / 1 / 3 / 5; }
        .div3 { grid-area: 3 / 1 / 4 / 5; }
        .div4 { grid-area: 4 / 1 / 5 / 5; }
        .div5 { grid-area: 5 / 1 / 6 / 5; }
        .div6 { grid-area: 6 / 1 / 7 / 5; }
        .div7 { grid-area: 7 / 1 / 8 / 5; }
        .div8 { grid-area: 8 / 1 / 9 / 5; }

        @media screen and (min-width: 1024px) {
          grid-template-columns: repeat(8, 1fr);
          grid-template-rows: repeat(10, auto);
          grid-column-gap: 20px;
          grid-row-gap: 20px;
                    
          .div1 { grid-area: 1 / 3 / 2 / 8; }
          .div2 { grid-area: 2 / 2 / 6 / 5; }
          .div3 { grid-area: 2 / 5 / 5 / 8; }
          .div4 { grid-area: 5 / 5 / 6 / 8; }
          .div5 { grid-area: 6 / 2 / 7 / 4; }
          .div6 { grid-area: 7 / 2 / 8 / 4; }
          .div7 { grid-area: 8 / 2 / 9 / 4; }
          .div8 { grid-area: 6 / 4 / 9 / 8; }
        }
       
        }
 

  }
  &__title{
    font-size:32px;
    line-height: 1em;
    max-width: 380px;
    margin-bottom:48px;
    letter-spacing:-0.05em;
    font-family: Eina01-Bold;
    color:#282828;
    position: relative;
    &:after{
      position: absolute;
      content:"";
      width: 32px;
      height: 32px;
      top:-64px;
      left:0px;
      opacity: 1;
      background: #282828;
      transition: all ease .5s;
      @media screen and (min-width: 1024px) {
        top:8px;
        left:-96px;
        width: 48px;
        height: 48px;
      }
    }
    &:before {
      position: absolute;
      content:"";
      width: 16px;
      height: 16px;
      top:-40px;
      left:0px;
      opacity: 1;
      background: #fff;
      transition: all ease .5s;
      z-index: 1;
      @media screen and (min-width: 1024px) {
        width: 24px;
        height: 24px;
        top:0;
        left:-72px;
      }
    }

    @media screen and (min-width: 768px) {
      font-size:48px;
      line-height: 1em;
      max-width: 640px;
      margin-bottom:48px;
      letter-spacing:-0.05em;
    }

    @media screen and (min-width: 1024px) {
      font-size:60px;
      line-height: 1em;
      max-width: 768px;
      margin-bottom:96px;
      letter-spacing:-0.05em;
    }

    @media screen and (min-width: 1280px) {
      font-size:72px;
      line-height: 1em;
      max-width: 840px;
      margin-bottom:96px;
      letter-spacing:-0.05em;
    }
    b {
      color:rgba(40,40,40,0.5);
    }
    span.highlight{
      color:rgba(40,40,40,0.5);
    }
    &-wrapper{}
  }

  &__card{
    display: flex;
      height:100%;
      width:100%;
    //padding:40px;
    padding-left:0;
    &-content{
      background-color: rgba(0,0,0,0.05);
      padding:40px;
      padding-bottom:0;
      width: 100%;
      position: relative;
    }
    &-title{
      font-size:24px;
      letter-spacing:-0.025em;
      font-family: Eina01-Bold;
      line-height: 1.375em;
      &--smaller{
        font-size:20px;
      }
    }
    &-desc{
      font-size:20px;
      letter-spacing:-0.025em;
      font-family: Eina01-Bold;
      line-height: 1.3em;
      opacity:0.5;
      //max-width: 500px;
      margin-bottom:24px;
      margin-top:8px;
      &--smaller{
        font-size:18px;
      }
    }
    &-image{
      position: relative;
      //padding:20px;
      background-color: rgba(0,0,0,0.15);
      padding-bottom: 0px;
      //border: 1px solid rgba(255,255,255,0.5);
      border-bottom:0px;
      box-shadow: 0px -20px 25px rgba(0,0,0,0.05);
      &:after{
        position: absolute;
        content:"";
        width: 48px;
        height: 48px;
        top:-1px;
        right:0;
        opacity: 1;
        background: #47ccdf;
        transition: all ease .5s;
        z-index: 1;
      }
      &:before{
        position: absolute;
        content:"";
        width: 100%;
        height: 100%;
        opacity: 1;
        background: linear-gradient(0deg, rgba(#43C1D4,1) 10%, rgba(#47ccdf,0.25) 100%);
        transition: all ease .5s;
        z-index: 1;
      }
    
    &:hover{
        &:before{
           opacity: 0.25;
        }
    }
    }
    &-wrapper{
      display: flex;
      height:100%;
      width:100%;
    }
  }

}




