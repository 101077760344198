.solution-details-header {
    display: flex;
    min-height: 50vh;
    align-items: center;
    padding: 150px 0px 50px;
    position: relative;
    overflow: hidden;


    @media screen and (min-width: 768px) {
        padding: 150px 0px 100px;
    }

    @media screen and (min-width: 1024px) {
        padding: 200px 0px;

        background-position: top -200px center;
    }

    @media screen and (min-width: 1280px) {
        background-position: top center;
    }

    @media screen and (min-width: 1440px) {
        background-size: 100% auto;
        background-position: center center;
    }

    &__wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, auto);
        grid-column-gap: 0px;
        grid-row-gap: 32px;

        @media screen and (min-width: 768px) {
            grid-row-gap: 64px;
        }

        @media screen and (min-width: 1024px) {
            grid-template-columns: repeat(8, 1fr);
            grid-template-rows: repeat(3, auto);
            grid-row-gap: 96px;
        }

        @media screen and (min-width: 1280px) {
            grid-template-columns: repeat(8, 1fr);
            grid-template-rows: repeat(2, auto);
        }

    }

    &__title {
        &-wrapper {
            grid-area: 1 / 1 / 2 / 5;

            @media screen and (min-width: 1024px) {
                grid-area: 1 / 2 / 2 / 8;
            }

            @media screen and (min-width: 1280px) {
                grid-area: 1 / 2 / 2 / 5;
            }

        }


        color:#282828;
        font-family: Eina01-Bold;
        letter-spacing: -0.07em;
        line-height: 1em;
        font-size:32px;
        text-align: center;

        @media screen and (min-width: 768px) {
            font-size: 56px;
        }

        @media screen and (min-width: 1024px) {
            font-size: 64px;
        }

        @media screen and (min-width: 1280px) {
            text-align: left;
            font-size: 48px;
        }

        @media screen and (min-width: 1440px) {
            text-align: left;
            font-size: 72px;
        }

        span.highlight {
            color: #47ccdf;
        }

        b {
            color: #47ccdf;
        }


    }

    &__desc {
        font-size: 16px;
        color: rgba(16, 19, 20, 0.5);
        font-family: Eina01-Bold;
        letter-spacing: -0.025em;
        line-height: 1.5em;
        text-align: center;

        @media screen and (min-width: 1280px) {
            text-align: left;
        }


        @media screen and (min-width: 1280px) {
            font-size: 20px;
        }

        &-wrapper {
            grid-area: 2 / 1 / 3 / 5;
            display: flex;
            gap: 24px;
            flex-direction: column;
            justify-content: center;

            @media screen and (min-width: 1024px) {
                grid-area: 2 / 2 / 3 / 8;
            }

            @media screen and (min-width: 1280px) {
                grid-area: 1 / 5 / 2 / 8;
            }

            @media screen and (min-width: 1440px) {
                grid-area: 1 / 6 / 2 / 8;
            }
        }

    }

    &__btns {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media screen and (min-width: 768px) {
            justify-content: center;
            gap: 32px;
            flex-direction: row;
        }

        @media screen and (min-width: 1280px) {
            justify-content: space-between;
            flex-direction: column;
            button {
                width: 100%;
            }
        }


        button {
            font-size: 16px;
            padding: 16px 42px;
            background-color: transparent;
            font-family: Eina01-Bold;
            letter-spacing: -0.025em;
            border: 3px solid transparent;
            transition: all ease .2s;
            transform: scale(1);
            display: flex;
            justify-content: center;
            width: 100%;

            @media screen and (min-width: 768px) {
                //width: auto;
            }

            &.bordered {
                border-color: #282828;
                color: #282828;

                &:hover {
                    background-color: #282828;
                    color: #fff;
                    transform: scale(1.1);
                }
            }

            &.filled {
                background-color: #282828;
                color: #fff;

                &:hover {
                    background-color: #47ccdf;
                    color: #282828;
                    transform: scale(1.1);
                }
            }
        }

    }

    &__image {
        position: relative;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
        transition: all ease .5s;
        z-index: 1;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(#282828, 1) 10%, rgba(#282828, 0.25) 100%);
            transition: all ease .5s;
            opacity: 1;
            content: "";
            z-index: 1;

        }

        &:hover {
            transition: all ease .5s;

            &:before {
                opacity: 0;
            }
        }

        &-wrapper {
            position: relative;
            grid-area: 3 / 1 / 4 / 5;

            @media screen and (min-width: 1024px) {
                grid-area: 3 / 2 / 4 / 8;
            }

            @media screen and (min-width: 1280px) {
                grid-area: 2 / 2 / 3 / 8;
            }

            &:before {
                position: absolute;
                left: -50%;
                top: -50%;
                width: 200%;
                height: 200%;
                content: "";
                background-image: url(../../Assets/Images/solutions-frame-bg.svg);
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 70% auto;
                z-index: -1;
            }
        }

    }
}