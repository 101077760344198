.controls-container {
  --highlight-width: auto;
  --highlight-x-pos: 0;
  display: flex;
}

.controls {
  display: inline-flex;
  justify-content: space-between;
  //background:rgba(255,255,255,0.05);
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  max-width: 100%;
  padding: 12px;
  margin: auto;
  overflow: hidden;
  position: relative;
  background-color: #333;
}

.controls input {
  opacity: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
}

.segment {
  /* width: 100%; uncomment for each segment to have matching width */
  min-width: 120px;
  position: relative;
  text-align: center;
  z-index: 1;
}

.segment label {
  cursor: pointer;
  display: block;
  font-weight: 700;
  padding: 10px;
  transition: color 0.325s ease;
  color: rgba(#fff, 0.5);
}

.segment:hover {
  label {
    color: #fff;
  }
}

.segment.active label {
  color: #282828;
}

.controls::before {
  content: "";
  background: #47ccdf;
  border-radius: 0px;
  width: var(--highlight-width);
  transform: translateX(var(--highlight-x-pos));
  position: absolute;
  top: 12px;
  bottom: 12px;
  left: 0;
  z-index: 0;
}

/* Only allow transitions once component is ready */
.controls.ready::before {
  transition: transform 0.3s ease, width 0.3s ease;
}