
.product-other-solutions{
    &-bg{
        background-color: #282828;
        position: relative;
        color:#282828;
        padding:128px 0px;
        border-top:1px solid rgba(0,0,0,0.05);
        color:#fff;
    }
    &__container{
      position: relative;
    }
  

    &__wrapper{
    }
    &__inner{
      .parent {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(5, auto);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        .div1 { grid-area: 1 / 1 / 2 / 5; }
        .div2 { grid-area: 2 / 1 / 3 / 5; }
        .div3 { grid-area: 3 / 1 / 4 / 5; }
        .div4 { grid-area: 4 / 1 / 5 / 5; }
        .div5 { grid-area: 5 / 1 / 6 / 5; }
        @media screen and (min-width: 1024px) { 

          display: grid;
          grid-template-columns: repeat(8, 1fr);
          grid-template-rows: repeat(2, auto);
          grid-column-gap:40px;
          grid-row-gap: 40px;
          .div1 { grid-area: 1 / 2 / 2 / 6; }
          .div2 { grid-area: 1 / 6 / 2 / 8; }
          .div3 { grid-area: 2 / 2 / 3 / 4; }
          .div4 { grid-area: 2 / 4 / 3 / 6; }
          .div5 { grid-area: 2 / 6 / 3 / 8; }
        }
        }
        
        
  }

  &__title{
    font-size:32px;
    line-height: 1em;
    max-width: 380px;
    margin-bottom:0px;
    letter-spacing:-0.05em;
    font-family: Eina01-Bold;
    color:#fff;
    position: relative;
    &:after{
      position: absolute;
      //content:"";
      width: 32px;
      height: 32px;
      top:-64px;
      left:0px;
      opacity: 1;
      background: #47ccdf;
      transition: all ease .5s;
      @media screen and (min-width: 1024px) {
        top:8px;
        left:-96px;
        width: 48px;
        height: 48px;
      }
    }
    &:before {
      position: absolute;
      //content:"";
      width: 16px;
      height: 16px;
      top:-40px;
      left:0px;
      opacity: 1;
      background: #fff;
      transition: all ease .5s;
      z-index: 1;
      @media screen and (min-width: 1024px) {
        width: 24px;
        height: 24px;
        top:0;
        left:-72px;
      }
    }

    @media screen and (min-width: 768px) {
      font-size:48px;
      line-height: 1em;
      max-width: 640px;
      margin-bottom:0px;
      letter-spacing:-0.05em;
    }

    @media screen and (min-width: 1024px) {
      font-size:60px;
      line-height: 1em;
      max-width: 768px;
      margin-bottom:96px;
      letter-spacing:-0.05em;
    }

    @media screen and (min-width: 1280px) {
      font-size:72px;
      line-height: 1em;
      max-width: 840px;
      margin-bottom:96px;
      letter-spacing:-0.05em;
    }
    
    span.highlight{
      color:rgba(40,40,40,0.5);
    }
    &-wrapper{}
  }

  &__desc{
    &-title{
      font-size:18px;
      line-height: 1.5em;
      max-width: 100%;
      margin-bottom:32px;
      letter-spacing:-0.025em;
      font-family: Eina01-Bold;
      color:rgba(#fff, 0.5);
      position: relative;
    

      @media screen and (min-width: 1024px) {
        font-size:20px;
        max-width: 380px;
      }

      
      span.highlight{
        color:rgba(40,40,40,0.5);
      }
    }
    &-link{
      font-size:16px;
      line-height: 1.5em;
      letter-spacing:-0.025em;
      font-family: Eina01-Bold;
      color:#47ccdf;
      display: flex;
      cursor:pointer;
      &:hover {
        color:#fff;
        .icon {
         transform:rotate(45deg);
         top: 0px;
          left: 8px;
          svg {
            fill:#fff;
          }
        }
        .label {
          top: 0px;
          left: 2px;
         }
      }
      .label {
        position: relative;
        transform-origin: center center;
        left:0;
        transition: all ease .2s;
        
      }
      .icon{
        position: relative;
        top: -2px;
        left: 4px;
        transition: all ease .2s;
        transform-origin: center center;
        svg {
          fill:#47ccdf;
        }
      }
      
    }
    &-wrapper{
      margin-bottom:64px;
      @media screen and (min-width: 1024px) {
        margin-bottom: 0px;
      }
    }
   
  }
  
  &__card{
    display: flex;
      height:100%;
      width:100%;
    //padding:40px;
    padding-left:0;
    position: relative;
    div {
      //border:1px solid rgba(0,0,0,0.1);
      //box-shadow:0px 0px 25px rgba(29, 28, 28, 0.15);
    }
    
    &-content{
      padding:40px;
      background-color: rgba(0,0,0,0.05);
      padding-bottom:0;
      width: 100%;
      transition:all ease .2s;
      transform:scale(1);
      cursor: pointer;
      &:after {
        position: absolute;
        content:"";
        width: 16px;
        height: 16px;
        top:0;
        left:0px;
        opacity: 1;
        background: #47ccdf;
        transition: all ease .5s;
        @media screen and (min-width: 1024px) {
          top:0;
          left:0;
          width: 24px;
          height: 24px;
        }
      }
      &:before{
        position: absolute;
        content:"";
        width: 16px;
        height: 16px;
        top:0px;
        left:0px;
        opacity: 1;
        background: #fff;
        transition: all ease .5s;
        z-index: 1;
        @media screen and (min-width: 1024px) {
          width: 12px;
          height: 12px;
          top:-6px;
          left:0px;
        }
      }

      &:hover{
        transform: scale(1.05);
        .product-other-solutions__card-title {
          color:#47ccdf;
        }
        .product-other-solutions__card-desc {
          color:#47ccdf;
          opacity: 0.75;
        }
        .product-other-solutions__card-icon {
          right:24px;
          top:24px;
          svg {
            fill:#47ccdf;
          }
        }
      }
    }
    &-title{
      font-size:24px;
      letter-spacing:-0.025em;
      font-family: Eina01-Bold;
      line-height: 1.375em;
      margin-bottom:32px;
      &--smaller{
        font-size:20px;
      }
    }

    &-icon{
      position: absolute;
      right:32px;
      top:32px;
      transition:all ease .2s;
      svg {
        fill:#fff;
      }
    }
    &-desc{
      font-size:18px;
      letter-spacing:-0.025em;
      font-family: Eina01-Bold;
      line-height: 1.3em;
      opacity:0.5;
      transition: all ease .2s;
      //max-width: 500px;
      margin-bottom:24px;
      margin-top:8px;
      &--smaller{
        font-size:18px;
      }
    }
   
    &-wrapper{
      display: flex;
      height:100%;
      width:100%;
    }
  }
  





}




