.bg-integrations {
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;

}

.integrations-section {
  width: 100%;
  padding-right: 10%;
  padding-left: 10%;
  padding-top: 48px !important;
  padding-bottom: 48px !important;

  @media (min-width: 640px) {
    width: 520px !important;
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }

  @media (min-width: 768px) {
    width: 614px !important;
    padding-right: 0rem !important;
    padding-left: 0rem !important;
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }

  @media (min-width: 1024px) {
    width: 768px !important;
    padding-right: 0rem;
    padding-left: 0rem;
    padding-top: 96px !important;
    padding-bottom: 96px !important;
  }

  @media (min-width: 1280px) {
    width: 960px !important;
    padding-right: 0rem;
    padding-left: 0rem;
    padding-top: 128px !important;
    padding-bottom: 128px !important;
  }

  @media (min-width: 1600px) {
    width: 1200px !important;
  }

  &__title {
    font-family: Eina01;
    font-size: 28px;
    font-weight: bold;
    line-height: 1.5 !important;
    letter-spacing: -1px;
    color: #282828;
    text-align: center;

    span {
      color: #aaa;
    }

    @media screen and (min-width: 1024px) {
      font-size: 32px;
      line-height: 1.14;
      letter-spacing: -2px;
      text-align: center;
    }

    @media screen and (min-width: 1280px) {
      font-size: 36px;
      line-height: 1.14;
      letter-spacing: -2px;
    }

    @media screen and (min-width: 1600px) {
      font-size: 42px;
      line-height: 1.14;
      letter-spacing: -2px;
    }
  }

  &__desc {
    font-family: Eina01;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.6 !important;
    color: rgba(#282828, 0.5);
    text-align: center;
    margin-top: 32px;


    @media screen and (min-width: 1024px) {
      font-size: 14px;
    }


  }

  &__content {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;


    .parent {
      display: grid;
      grid-template-columns: repeat(9, 1fr);
      grid-template-rows: repeat(8, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;

      .div1 {
        grid-area: 2 / 3 / 3 / 4;
      }

      .div2 {
        grid-area: 4 / 5 / 5 / 6;
      }

      .div3 {
        grid-area: 3 / 4 / 4 / 5;
      }

      .div4 {
        grid-area: 3 / 6 / 4 / 7;
      }

      .div5 {
        grid-area: 2 / 7 / 3 / 8;
      }

      .div6 {
        grid-area: 5 / 6 / 6 / 7;
      }

      .div7 {
        grid-area: 6 / 7 / 7 / 8;
      }

      .div8 {
        grid-area: 5 / 4 / 6 / 5;
      }

      .div9 {
        grid-area: 6 / 3 / 7 / 4;
      }

      @media (min-width: 1024px) {
        grid-template-columns: repeat(18, 1fr);
        grid-template-rows: repeat(8, 1fr);
        grid-column-gap: 16px;
        grid-row-gap: 16px;

        .div1 {
          grid-area: 2 / 9 / 4 / 11;
        }

        .div2 {
          grid-area: 4 / 9 / 6 / 11;
        }

        .div3 {
          grid-area: 6 / 9 / 8 / 11;
        }

        .div4 {
          grid-area: 3 / 7 / 5 / 9;
        }

        .div5 {
          grid-area: 5 / 7 / 7 / 9;
        }

        .div6 {
          grid-area: 3 / 11 / 5 / 13;
        }

        .div7 {
          grid-area: 5 / 11 / 7 / 13;
        }

        .div8 {
          grid-area: 4 / 13 / 6 / 15;
        }

        .div9 {
          grid-area: 4 / 5 / 6 / 7;
        }
      }

      @media (min-width: 1280px) {
        grid-column-gap: 32px;
        grid-row-gap: 32px;
      }

      @media (min-width: 1440px) {
        grid-column-gap: 48px;
        grid-row-gap: 48px;
      }


    }





    .int-card {
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.10);
      width: 64px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      transition: all .3s ease;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.10);
        transform: scale(1.05);
      }

      &--lugath {
        background-color: #282828;

        svg {
          width: 24px;
          height: 24px;

          @media (min-width: 1024px) {
            width: 48px;
            height: 48px;
          }
        }
      }


      &-logo {
        display: flex;
        height: 32px;
        align-items: center;
        justify-content: center;

        @media (min-width: 1024px) {
          height: 64px;
        }

        svg {
          width: 100%;
          max-height: 70%;
        }
      }

      &-desc {
        display: none;
        font-size: 12px;
        font-weight: bold;
        color: #aaa;

        @media (min-width: 1024px) {
          display: flex;
        }
      }

      @media (min-width: 1024px) {
        width: 106px;
        height: 106px;
      }

      @media (min-width: 1280px) {
        width: 128px;
        height: 128px;
      }

      @media (min-width: 1440px) {
        width: 190px;
        height: 190px;
      }


    }
  }

  &__link {
    border-bottom: 1px solid rgba(#282828, 0.25);

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      border-bottom: 1px solid #47ccdf;

    }

    &-icon {
      display: inline-block;

      svg {
        height: 14px;
        fill: #282828;
        position: relative;
        left: -2px;
        top: 2px;
      }
    }

    &-desc {
      color: #282828;
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
      padding-left: 12px;
    }
  }
}