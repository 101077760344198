.static-page{
  &-header {
    padding:200px 0 100px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#282828;
    .parent {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, auto);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      .div1 { grid-area: 2 / 1 / 3 / 5; }
      .div2 { grid-area: 3 / 1 / 4 / 5; }
        @media screen and (min-width: 1024px) {
          grid-template-columns: repeat(7, 1fr);
          grid-template-rows: repeat(4, auto);
          
          .div1 { grid-area: 2 / 2 / 3 / 6; }
          .div2 { grid-area: 3 / 4 / 4 / 7; }
    
        }
      }
      
     

     

      &__title{
        font-size:48px;
        font-family: Eina01-Bold;
        color:#282828;
        letter-spacing: -0.05em;
        line-height: 1;
        span.shine{
          color:#aaa;
        }
        span.highlight-cyan{
          color:#47ccdf;
        }
        span.highlight-black{
          color:#282828;
        }
        @media screen and (min-width: 768px) {
          font-size:64px;
        }
        @media screen and (min-width: 1024px) {
          font-size:96px;
        }
        @media screen and (min-width: 1280px) {
          font-size:128px;
        }
      }
      &__content{
        &-desc{
          font-size:24px;
          font-weight: 600;
          font-family: Eina01;
          letter-spacing: -0.05em;
          line-height: 1.3em;
          color:rgba(#282828,1);
          margin-top:32px;
          margin-bottom:32px;
          @media screen and (min-width: 1024px) {
            font-size:28px;
          }
          @media screen and (min-width: 1280px) {
            font-size:32px;
          }
          span.shine{
            color:#aaa;
          }
          span.highlight-cyan{
            color:#47ccdf;
          }
          span.highlight-black{
            color:#282828;
          }
          span.subdesc{
            font-size:16px;
            display: block;
            color: #47ccdf;
            font-family: Eina01-Bold;
            letter-spacing: -0.025em;
          }
        }
        &-cta{
          margin-top:24px;
          button {
            padding:16px 48px;
            font-size:16px;
            font-weight: 600;
            font-family: Eina01;
            color:#fff;
            background-color: #282828;
            transition:all ease .2s;
            transform:scale(1);
            &:hover {
              color:#282828;
              background-color: #47ccdf;
              transform: scale(1.05);
            }
          }
        }
      }
  }
}