.navbar {
  ////display: grid;
  //grid-template-columns: repeat(4, minmax(60px, 1fr));
  grid-auto-rows: repeat(1, 1fr);
  position: relative;
  z-index: 1;

  &-inner {
    display: flex;
    height: 64px;
    justify-content: space-between;

    @media screen and (min-width: 768px) {
      height: 96px;
    }

    @media screen and (min-width: 1024px) {
      height: 72px;
    }

    @media screen and (min-width: 1178px) {
      height: 96px;
    }

  }

  &-wrapper {
    position: fixed;
    width: 96%;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(24px);
    z-index: 222;
    top: 8px;
    left:50%;
    transform:translateX(-50%);
    transition: all ease .375s;
    will-change: transform, opacity;
    
    &.show {
      top: 8px;
    }

    &.hide {
      top: -150px;
    }

    @media screen and (min-width: 768px) {
      width: 90%;
      top: 32px;
     &.show{
      top: 32px;
      }
    }
  

    @media screen and (min-width: 1024px) {
      width: 90%;
      top: 16px;
     &.show{
      top: 16px;
      }
    }
  
    @media screen and (min-width: 1178px) {
      width: 90%;
      top: 24px;
      &.show{
        top: 24px;
        }
    }

    @media screen and (min-width: 1280px) {
      width: 80%;
      top: 24px;
      &.show{
        top: 24px;
        }
    }
  
   


    &:after {
      position: absolute;
      left: 0;
      top: 0;
      //content:"";
      bottom: 0;
      right: 0;
      //box-shadow: inset 0px -200px 65px -48px rgba(40,40,40,0.8);
      background-color: #282828;
      backdrop-filter: blur(24px);
      will-change: transform, opacity;
      z-index: 0;
      filter: blur(24px);
      overflow: hidden;

    }

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      bottom: 0;
      right: 0;
      //box-shadow: inset 0px -200px 65px -48px rgba(40,40,40,0.8);


      //background: linear-gradient(180deg, rgba(40,40,40,0) 0%, rgba(40,40,40,0.5) 25%, rgba(40,40,40,0.6181066176470589) 49%, rgba(40,40,40,0.5) 75%, rgba(40,40,40,0) 100%);
      backdrop-filter: blur(24px);
      z-index: 0;
      filter: blur(24px);
      overflow: hidden;

    }

    &--dropbox {
      background-color: #0061FE !important;
    }

    &--light {
      border: 1px solid rgba(255, 255, 255, 0.05);

      &:before {
        background: transparent;
      }
    }

    &--dark {

      &:before {
        //background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 25%, rgba(255, 255, 255, 0.6181066176470589) 49%, rgba(255, 255, 255, 0.5) 75%, rgba(255, 255, 255, 0) 100%);
      }

      .navbar-links a {
        color: #282828;
      }

      .navbar-links a:hover {
        color: #282828;
      }

      .navbar-links a .arrow-down {
        svg {
          fill: #282828;
        }
      }

      .navbar-links a.active-parent {
        color: #282828;
      }

      .navbar-links a.active-parent .arrow-down {
        svg {
          fill: #282828;
        }
      }

      .navbar-links a:hover .arrow-down {
        svg {
          fill: #282828;
        }
      }

      .navbar-lang .select:hover .current {
        color: #fff;
      }

      .navbar-lang .current .option .arrow-down svg {
          fill:#282828;
      }

      .navbar-lang .current {
        color: #282828;
      }

      .navbar-lang .arrow-down {}

     

      .navbar-app-links a {
        background-color: #47ccdf;
        color: #282828;

        svg {
          path {
            fill: #282828;
          }
        }

      }

      .navbar-app-links a:hover {
        background-color: #282828 !important;
        color: #fff;

        svg {
          path {
            fill: #fff;
          }
        }

      }

      .navbar-logo {
        svg {
          height: 32px;

          path {
            fill: #282828;
          }
        }
      }

      .navbar-toggle .menu-icon span {

        background-color: #282828;
      }
    }
  }

  &::before {
    content: "";
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }






  &-logo {
    //display: grid;
    align-items: center;
    width: 25%;
    display: flex;
    justify-content: left;

    @media screen and (min-width: 640px) {
      width: 128px;
    }

    @media screen and (min-width: 768px) {
      width: 153.39px;
    }

    @media screen and (min-width: 1024px) {
      width: 128px;
      justify-content: center;
    }

    @media screen and (min-width: 1280px) {
      width: 160px;
    }

    @media screen and (min-width: 1600px) {
      width: 200px;
    }

    svg {
      height: 32px;

      path {
        fill: #47ccdf;
      }

    }
  }

  &-app-links {
    //display: grid;
    align-items: center;
    width: 25%;
    display: none;
    justify-content: end;

    @media screen and (min-width: 640px) {
      width: 128px;
    }

    @media screen and (min-width: 768px) {
      width: 153.39px;
    }

    @media screen and (min-width: 1024px) {
      width: 128px;
      display: flex;

    }

    @media screen and (min-width: 1280px) {
      width: 160px;
    }

    @media screen and (min-width: 1600px) {
      width: 200px;
    }

    a {
      opacity: 1;
      font-family: Eina01;
      font-weight: bold;
      font-size: 12px;
      color: #47ccdf;
      transition: all .175s linear;
      padding: 12px 24px;
      will-change: transform, opacity;

      @media screen and (min-width: 640px) {
        //min-width:128px;
      }

      @media screen and (min-width: 768px) {
        //min-width:153.39px;
      }

      @media screen and (min-width: 1024px) {
        //min-width:128px;
      }

      @media screen and (min-width: 1280px) {
        //min-width:160px;
        font-size: 16px;
      }

      @media screen and (min-width: 1600px) {
        //min-width:200px;
      }

      svg {
        display: inline-flex;
        position: relative;
        margin-left: 12px;
        max-width: 16px;
        top: -1px;

        path {
          fill: #47ccdf;
        }
      }


      &:hover {
        background: #47ccdf;
        padding: 12px 24px;
        color: #282828;

        svg {
          path {
            fill: #282828;
          }
        }
      }
    }
  }

  &-lang {
    //display: grid;
    align-items: center;
    width: 48px;
    display: flex;
    justify-content: center;
    position: absolute;
    top:0;
    right:96px;
    width: 48px;
    height: 100%;

    @media screen and (min-width: 640px) {
      //width: 128px;
      right:110px;
    }

    @media screen and (min-width: 768px) {
      //width: 153.39px;
      right:128px;
      
    }

    @media screen and (min-width: 1024px) {
      position: relative;
      right:auto;
      top:auto;
      height: auto;
      width: 128px;
      display: flex;
    }

    @media screen and (min-width: 1280px) {
      width: 160px;
    }

    @media screen and (min-width: 1600px) {
      width: 200px;
    }

    &__selector {
      opacity: 0;
      position: absolute;
    }

    .navbar-loading {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAtOTYwIDk2MCA5NjAiIHdpZHRoPSIyNHB4IiBmaWxsPSIjNDdjY2RmIj48cGF0aCBkPSJNNDgwLTgwcS04MiAwLTE1NS0zMS41dC0xMjcuNS04NlExNDMtMjUyIDExMS41LTMyNVQ4MC00ODBxMC04MyAzMS41LTE1NS41dDg2LTEyN1EyNTItODE3IDMyNS04NDguNVQ0ODAtODgwcTE3IDAgMjguNSAxMS41VDUyMC04NDBxMCAxNy0xMS41IDI4LjVUNDgwLTgwMHEtMTMzIDAtMjI2LjUgOTMuNVQxNjAtNDgwcTAgMTMzIDkzLjUgMjI2LjVUNDgwLTE2MHExMzMgMCAyMjYuNS05My41VDgwMC00ODBxMC0xNyAxMS41LTI4LjVUODQwLTUyMHExNyAwIDI4LjUgMTEuNVQ4ODAtNDgwcTAgODItMzEuNSAxNTV0LTg2IDEyNy41cS01NC41IDU0LjUtMTI3IDg2VDQ4MC04MFoiLz48L3N2Zz4=);
      background-position: center center;
      background-repeat: no-repeat;
      -webkit-animation: lugath_rotating 1s linear infinite;
    }

    @-webkit-keyframes lugath_rotating {
      from {
        -webkit-transform: rotate(0deg);
      }
    
      to {
        -webkit-transform: rotate(360deg);
      }
    }

    .select {
      cursor: pointer;
      position: relative;
      color: #fff;
      font-weight: bold;
      position: relative;
      font-size: 14px;
      text-transform: uppercase;
    }

    .select:hover .options {
      display: flex;
    }

    .options {
      position: absolute;
      width: 100%;
      z-index: 9;
      top: 0;
      left: 0;
      padding-top: 48px;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
      display: none;
      background-color: #282828;
    }

    .options ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .options ul li {
      position: relative;
      overflow: hidden;
      width: 100%;
    }

    .option {
      display: flex;
      align-items: center;
      padding: 8px 32px 8px 16px;

      &.selected {
        color: #47ccdf;
      }
    }

    .option:hover {
      background-color: #47ccdf;
      color: white;
    }

    .current .option .arrow-down {
      position: absolute;
      right: 10px;
      top: 6px;
      transform: rotate(0deg);
      transition: transform .2s ease;
      will-change: transform, opacity;

      svg {
        width: 16px;
        fill: #47ccdf;
      }
    }

    .current:hover .option .arrow-down {
      transform: rotate(180deg);

      svg {
        fill: #282828;
      }
    }

    .current {
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 10;
      position: relative;
      color: #fff;
      opacity: 1;
    }
  }

  &-links {
    width: 50%;
    align-items: center;
    display: none;

    @media screen and (min-width: 640px) {
      width: calc(100% - 384px);
    }

    @media screen and (min-width: 768px) {
      width: calc(100% - 460.17px);
    }

    @media screen and (min-width: 1024px) {
      width: calc(100% - 384px);
      display: flex;
    }

    @media screen and (min-width: 1280px) {
      width: calc(100% - 480px);
    }

    @media screen and (min-width: 1600px) {
      width: calc(100% - 600px);
    }

    &__item {
      display: flex;
      height: 100%;
      align-items: center;

      &:hover {

        //background-color: #0061FE;
      }

      &>a {
        //min-width:64px;
        margin-right: 48px;
        position: relative;
        opacity: 1;
        font-family: Eina01;
        font-weight: bold;
        font-size: 12px;
        color: rgba(255, 255, 255, 1);
        transition: .3s ease;
        will-change: transform, opacity;

        //border-bottom:4px solid transparent;
        @media screen and (min-width: 640px) {
          //min-width:128px;

        }

        @media screen and (min-width: 768px) {
          //min-width:153.39px;
        }

        @media screen and (min-width: 1024px) {
          //min-width:128px;
          font-size: 12px;

        }

        @media screen and (min-width: 1280px) {
          //min-width:160px;
          font-size: 16px;
          margin-right: 64px;

        }

        @media screen and (min-width: 1600px) {
          //min-width:200px;
        }


        .arrow-down {
          display: inline-block;
          opacity: 1;
          position: absolute;
          right: -16px;
          top: -3px;

          @media screen and (min-width: 1280px) {
            right: -24px;
            top: 0px;
          }

          svg {
            fill: #47ccdf;
            width: 16px;

            @media screen and (min-width: 1280px) {
              width: 24px;
            }
          }
        }

        &.active-parent {
          color: #fff;
          //border-color:#47ccdf;

          .arrow-down {
            display: inline-block;
            opacity: 1;
          }
        }

        .icon-login {
          margin-right: 15px;
          display: inline-block;
          fill: #47CCDF;

          &+span {
            color: #fff;
            transition: .3s ease;
            will-change: transform, opacity;

            &:hover {
              color: #47ccdf;
            }
          }
        }

        @media screen and (min-width: 1280px) {
          font-size: 16px;
        }

        @media screen and (min-width: 1480px) {
          font-size: 16px;
        }

        &:hover {
          opacity: 1;
          color: #FFFFFF;

          .arrow-down {
            display: inline-block;
          }
        }
      }

      a:nth-last-child(2) {
        justify-self: end;
      }

      &:hover,
      &.show {
        .sub-menu-wrapper {
          opacity: 1;
          //top:96px;
          visibility: visible;
          max-height: 600px;
          overflow: visible;
          
          @media screen and (min-width: 768px) {
            top: 96px;
          }
      
          @media screen and (min-width: 1024px) {
            top: 72px;
          }
      
          @media screen and (min-width: 1178px) {
            top: 96px;
          }
          .sub-menu-inner {
            opacity: 1;
            visibility: visible;
            background-color: #fff;
            visibility: visible;
            max-height: 600px;
            overflow: visible;

            &:before {
              //background-color: #0061FE;
            }
          }
        }
      }

      &.hide {
        .sub-menu-wrapper {
          opacity: 0;
          visibility: hidden;
          max-height: 0px;
          overflow: hidden;

          .sub-menu-inner {
            opacity: 0;
            visibility: hidden;
            visibility: hidden;
            max-height: 0px;
            overflow: hidden;

          }
        }
      }


      .sub-menu {
        &-wrapper {
          position: absolute;
          width: 100%;
          opacity: 0;
          background: transparent;
          left: 0;
          top: 96px;
          z-index: 0;
          transition: all linear 175ms;
          will-change: transform, opacity;
          visibility: hidden;
          max-height: 0px;
          overflow: hidden;
          /*&.show{
            opacity: 1;
            //background: #fff;
            top:96px;
          }
          &.hide{
            opacity: 0;
          }*/
        }

        &-inner {
          opacity: 0;
          visibility: hidden;
          max-height: 0px;
          overflow: hidden;
          transition: all linear 175ms;
          will-change: transform, opacity;
          transition-delay: 175ms;
          padding: 80px 0px 40px;
          box-shadow: 0px 25px 80px rgba(0, 0, 0, 0.25);
          border-top: 4px solid #47ccdf;
          background-image: url(../../Assets/Images/menu-promo-bg.svg);
          bottom: -64px;
          right: 0;
          background-position: bottom right;
          background-repeat: no-repeat;

          @media screen and (min-width: 1280px) {
            padding: 60px 30px 36px;
          }

          @media screen and (min-width: 1440px) {
            padding: 64px 64px;
          }

          &:before {
            position: absolute;
            left: 0px;
            top: 0px;
            content: "";
            background-color: #282828;
            width: 110px;
            height: 4px;
          }

          &.show {
            opacity: 1;
            visibility: visible;
            background-color: #fff;
          }

          &.hide {
            opacity: 0;
            visibility: hidden;
            background-color: transparent;
          }

          &__content {
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto;
            grid-column-gap: 30px;

            @media screen and (min-width: 1280px) {
              grid-template-columns: 3fr 1fr;
            }

            &-menu {
              display: flex;

              &__list {
                display: grid;
                width: 100%;
                grid-template-columns: 1fr 1fr 1fr;

                gap: 0px;

                &-item {
                  display: flex;
                  margin: 0;
                  flex-grow: 1;
                  gap: 4px;

                  &:before {
                    position: absolute;
                    content: "";
                    //background-color: #0061FE;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                  }

                  &__wrapper {
                    display: flex;
                    padding: 16px 8px;
                    cursor: pointer;
                    align-items: center;
                    transition: .2s ease all;
                    position: relative;
                    will-change: transform, opacity;

                    &:hover {
                      background-color: #282828;
                      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);

                      .sub-menu-inner__content-menu__list-item__icon {
                        img {
                          filter: invert(1);
                        }
                      }

                      .sub-menu-inner__content-menu__list-item__content-name {
                        color: #fff;
                      }

                      .sub-menu-inner__content-menu__list-item__content-desc {
                        color: rgba(#fff, 0.5);
                      }
                    }
                  }

                  &__icon {
                    width: 72px;
                    height: 72px;
                    display: flex;
                    align-items: center;
                    //background-color: #f7f7f7;
                    justify-content: center;
                    position: relative;

                    img {
                      left: 50%;
                      top: 50%;
                      transform: translate(-50%, -50%);
                    }

                    /*img + img {
                      position: absolute;
                      z-index: 0;
                      width: 100%;
                      opacity: 0.55;
                      //filter:invert(0.25);

                    }*/


                  }

                  &__content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    &-name {
                      font-size: 16px;
                      font-family: Eina01-bold;
                      letter-spacing: -0.025em;
                      color: #282828;
                      position: relative;
                    }

                    &-status {
                      font-size: 10px;
                      padding: 4px 8px;
                      color: rgba(#282828, 0.7);
                      background-color: #ebebeb;
                      font-weight: bold;
                      letter-spacing: -0.025em;
                      font-family: Eina01-Bold;
                      line-height: 1;
                      display: flex;
                      text-transform: uppercase;
                      border: 1px solid rgba(0, 0, 0, 0.025);
                      position: absolute;
                      right: 4px;
                      top: -4px;

                      &--beta {
                        background-color: #ED6238;
                        color: rgba(255, 255, 255, 0.7);
                      }

                      &--development {
                        background-color: #47ccdf;
                      }

                      &--live {
                        background-color: rgb(0, 208, 133);
                      }

                      &--upcoming {
                        background-color: rgba(244, 232, 47, 1);
                      }

                      &--new {
                        background-color: #47ccdf;
                      }
                    }

                    &-desc {
                      font-size: 13px;
                      font-family: Eina01-bold;
                      letter-spacing: -0.025em;
                      color: rgba(#282828, 0.5);

                    }
                  }
                }
              }
            }

            &-promo {
              display: none;

              @media screen and (min-width: 1280px) {
                display: flex;
              }

              &__box {
                display: flex;
                width: 100%;
                flex-direction: column;
                position: relative;
                justify-content: space-evenly;

                &:hover {
                  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
                }

                &-video {
                  display: flex;
                  width: 100%;
                  height: 150px;
                  align-items: center;
                  justify-content: center;

                  a {
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transform: scale(1);
                    transition: all ease .2s;
                    will-change: transform, opacity;

                    &:hover {
                      transform: scale(1.1);
                    }
                  }
                }

                &-content {
                  background-color: #fff;
                  color: #282828;
                  display: flex;
                  height: auto;
                  width: 100%;
                  position: relative;
                  flex-direction: column;
                  padding: 32px;

                  &__title {
                    font-family: Eina01-bold;
                    letter-spacing: -0.05em;
                    color: #282828;
                    font-size: 24px;
                    z-index: 1;
                    position: relative;

                    span {
                      color: #47ccdf;
                    }
                  }

                  &__desc {
                    position: relative;
                    font-size: 16px;
                    font-family: Eina01;
                    letter-spacing: -0.05em;
                    font-weight: bold;
                    color: rgba(#282828, 0.5);
                    z-index: 1;

                  }

                  &:before {
                    position: absolute;
                    background-color: #fff;
                    //content:"";
                    width: 263px;
                    height: 184px;
                    bottom: -64px;
                    right: 0;
                    background-position: bottom right;
                    background-repeat: no-repeat;
                    mix-blend-mode: multiply;
                  }

                }
              }
            }
          }
        }
      }
    }

    /*a {
      opacity: 0.2;
      font-family: Eina01-Bold;
      font-size: 12px;
      letter-spacing: 1.1px;
      color: white;
      text-transform: uppercase;
      transition: .3s ease;
      .arrow-down {
        display: inline-block;
        opacity:0;
      }
      &:hover {
        opacity: 1;
        color:#47CCDF;
        .arrow-down {
          display: inline-block;
          opacity:.6;
        }
      }
    }
    a:nth-last-child(2) {
      justify-self: end;
    }*/
    &--languages {
      //display: grid;
      justify-items: end;
    }
  }

  :root {
    //--bar-bg: #212529;
  }

  &-toggle {
    align-items: center;
    justify-items: end;
    display: flex;

    .menu-icon {
      position: absolute;
      width: 32px;
      height: 32px;
      cursor: pointer;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      div {
        margin: auto;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 22px;
        height: 12px;
      }

      span {
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        background-color: #fff;
        border-radius: 1px;
        transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
        will-change: transform, opacity;
        &:first-of-type {
          top: 0;
        }

        &:last-of-type {
          bottom: 0;
        }
      }

      &.active,
      .menu-icon>div {
        span {
          &:first-of-type {
            transform: rotate(45deg);
            top: 5px;
          }

          &:last-of-type {
            transform: rotate(-45deg);
            bottom: 5px;
          }
        }
      }

      &.active:hover span:first-of-type,
      &.active:hover span:last-of-type,
      &:hover .menu-icon__cheeckbox:checked+div span:first-of-type,
      &:hover .menu-icon__cheeckbox:checked+div span:last-of-type {
        width: 22px;
      }

      &:hover {

        // no need hover effect on mobile.
        @media (min-width: 1024px) {
          span:first-of-type {
            width: 26px;
          }

          span:last-of-type {
            width: 12px;
          }
        }
      }
    }

  }

  &-sublinks {
    //padding: 4.375rem 7.5rem;
    display: flex;
    justify-content: space-around;

    .ul-wrapper {
      padding: 2rem;

      @media screen and (min-width: 1280px) {
        padding: 2rem;
      }

      &.help {
        min-width: 50%;
        background: #47CCDF;

        ul li a {
          color: #282828;
          font-weight: bold;
          line-height: 2.2rem;
          opacity: 0.5;
          padding-left: 0;
          font-size: 1.4rem;

          &:before {
            display: none;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    h6 {
      font-family: Eina01;
      font-size: 1.6rem;
      font-weight: bold;
      color: #282828;
      margin-bottom: 1.2rem;
      margin-top: 1rem;

      @media screen and (min-width: 1024px) {
        font-size: 1.4rem;
        margin-bottom: 1.6rem;
      }

      @media screen and (min-width: 1280px) {
        font-size: 2rem;
        margin-bottom: 1.6rem;
      }
    }

    ul {
      li {
        padding: 10px 0 0;
        position: relative;

        &:hover {
          span.navbar-item-logo {
            svg {
              fill: #282828 !important;
            }
          }
        }

        span.navbar-item-logo {
          position: absolute;
          display: inline-block;
          top: 50%;
          transform: translateY(-50%);

          &:before {
            position: absolute;
            right: 0;
            top: 0;
            width: 8px;
            height: 8px;
            background-color: #282828;
            //content:"";
            z-index: 1;
            //border-radius:28% 72% 46% 54% / 69% 68% 32% 31%  ;
          }

          &:after {
            position: absolute;
            right: 0;
            top: 0;
            width: 16px;
            height: 16px;
            background-color: #ddd;
            //content:"";
            z-index: 0;
            //border-radius:28% 72% 46% 54% / 69% 68% 32% 31%  ;
          }

          /*width:4px;
          height:4px;
          background-color: #47ccdf;
          top:-1px;*/

          svg {
            width: 24px;
            position: relative;
            z-index: 1;
            fill: #47ccdf;
            //display: none;

          }

        }

        a {
          padding-left: 32px;
          font-family: Eina01;
          font-size: 1rem;
          font-weight: bold;
          line-height: 1.6;
          color: #999999;
          transition: 0.3s ease;
          will-change: transform, opacity;
          position: relative;

          @media screen and (min-width: 1024px) {
            font-size: 1rem;
          }

          @media screen and (min-width: 1280px) {
            font-size: 1rem;
          }

          //padding-left:20px;
          /*&:before{
            position: absolute;
            content:"";
            width:3px;
            height:3px;
            background: #ccc;
            left:0px;
            top:50%;
            transform:translateY(-50%);
          }*/
          &:hover {
            color: #282828;
            /*&:before{
              background: #282828;
            }*/
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    //grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (min-width: 1024px) {
    //grid-template-columns: repeat(8, 1fr);
  }

  @media screen and (min-width: 1280px) {
    //grid-template-columns: repeat(8, 1fr);
  }

  @media screen and (min-width: 1600px) {
    //grid-template-columns: repeat(8, 1fr);
  }
}