.security {
  &-header {
    padding: 200px 0 200px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #282828;
    position: relative;

    &:before {
      position: absolute;
      height: 50%;
      width: 50%;
      right: 0;
      background-color: #47ccdf;

      @media screen and (min-width: 1024px) {
        content: "";
      }
    }

    &__wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;

      @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(8, 1fr);

      }

    }

    &__inner {
      grid-area: 1 / 1 / 2 / 5;

      @media screen and (min-width: 1024px) {
        grid-area: 1 / 2 / 2 / 8;
      }
    }

    .parent {


      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(6, auto);
      grid-column-gap: 0px;
      grid-row-gap: 0px;

      .div1 {
        grid-area: 1 / 1 / 2 / 5;
      }

      .div2 {
        grid-area: 2 / 1 / 3 / 5;
      }

      .div3 {
        grid-area: 3 / 3 / 4 / 5;
      }

      .div4 {
        grid-area: 3 / 1 / 4 / 3;
      }

      .div5 {
        grid-area: 4 / 1 / 5 / 3;
      }

      .div6 {
        grid-area: 4 / 3 / 5 / 5;
      }

      .div7 {
        grid-area: 5 / 3 / 6 / 5;
      }

      .div8 {
        grid-area: 5 / 1 / 6 / 3;
      }

      .div9 {
        grid-area: 6 / 1 / 7 / 3;
      }

      @media screen and (min-width: 1024px) {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(8, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        .div1 {
          grid-area: 1 / 1 / 3 / 7;
        }

        .div2 {
          grid-area: 3 / 1 / 5 / 5;
        }

        .div3 {
          grid-area: 3 / 5 / 7 / 9;
        }

        .div4 {
          grid-area: 5 / 3 / 7 / 5;
        }

        .div5 {
          grid-area: 5 / 1 / 7 / 3;
        }

        .div6 {
          grid-area: 7 / 1 / 9 / 3;
        }

        .div7 {
          grid-area: 7 / 3 / 9 / 5;
        }

        .div8 {
          grid-area: 7 / 5 / 9 / 7;
        }

        .div9 {
          grid-area: 7 / 7 / 9 / 9;
        }

      }
    }



    &__title {
      font-size: 32px;
      color: #282828;
      font-family: Eina01-Bold;
      letter-spacing: -0.05em;
      line-height: 1.1em;
      text-align: center;

      @media screen and (min-width: 1024px) {
        font-size: 48px;
        text-align: left;
      }

      @media screen and (min-width: 1280px) {
        font-size: 72px;
      }

      @media screen and (min-width: 1440px) {
        font-size: 96px;
      }

      &__wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        @media screen and (min-width: 1024px) {
          justify-content: flex-start;
        }
      }
    }

    &__desc {
      font-size: 16px;
      color: rgba(#282828, 0.6);
      font-family: Eina01-Bold;
      letter-spacing: -0.025em;
      line-height: 1.2em;
      margin-top: 32px;
      text-align: center;

      @media screen and (min-width: 1024px) {
        font-size: 21px;
        margin-top: 0px;
        text-align: left;
      }

      @media screen and (min-width: 1280px) {
        font-size: 32px;
        margin-top: 0px;
      }

      &__wrapper {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        margin-bottom: 32px;

        @media screen and (min-width: 1024px) {
          width: 80%;
          margin-bottom: 0;
        }
      }
    }

    &__cta {
      margin: 64px 0px 128px;
      text-align: center;

      @media screen and (min-width: 1024px) {
        text-align: left;
        margin-top: 24px;
        margin-bottom: 24px;
      }

      button {
        background-color: #47ccdf;
        color: #282828;
        padding: 24px 48px;
        font-size: 16px;
        font-family: Eina01-Bold;
        letter-spacing: -0.025em;
        line-height: 1.3em;
        transform: scale(1);
        transition: all ease .2s;

        &:hover {
          background-color: #282828;
          color: #fff;
          transform: scale(1.1);
        }
      }
    }


    &__card {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      background-color: #fff;
      transform: scale(1);
      transition: all ease .2s;
      z-index: 0;

      &:hover {
        transform: scale(1.1);
        z-index: 1;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);

        img {
          opacity: 1;
        }
      }

      &:before {
        border: 1px solid #eee;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }

      &--cyan {
        &:before {
          border-color: transparent
        }

        background-color: #47ccdf;
        pointer-events: none;

        img {
          filter: invert(0) !important;
          opacity: 1 !important;
          width: 64px;

          @media screen and (min-width: 768px) {
            width: 128px;
          }

          @media screen and (min-width: 1024px) {
            width: 256px;
          }
        }
      }

      &--empty {
        pointer-events: none;

        &:before {
          border-color: transparent;
        }

        background-color: transparent;
      }

      img {
        position: absolute;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
        filter: invert(1);
        opacity: 0.25;
        transition: all ease .2s;
      }
    }

  }
}