.integrations-details-page {
  &__hero {
    background-color: #282828;
    padding: 200px 0 0px;
    position: relative;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);

    &:before {
      position: absolute;
      content: "";
      width: 256px;
      height: 100%;

      left: 0;
      top: 0;
    }

    &-header {
      display: flex;
      height: 100%;
      width: 100%;
      grid-area: 1 / 1 / 2 / 5;
      gap: 16px;
      align-items: center;

      @media screen and (min-width: 1024px) {
        grid-area: 1 / 2 / 2 / 6;
        gap: 32px;
      }

      &__wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, auto);
        grid-column-gap: 0px;
        grid-row-gap: 32px;

        @media screen and (min-width: 1024px) {
          grid-template-columns: repeat(8, 1fr);
          grid-template-rows: auto;
          grid-row-gap: 0px;
        }
      }

      &__logo {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        //border:1px solid #0061fe;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);

        img {
          max-width: 50% !important;
          top: 50% !important;
          left: 50% !important;
          transform: translate(-50%, -50%) !important;
        }

        @media screen and (min-width: 768px) {
          width: 128px;
          height: 128px;
        }
      }

      &__content {
        display: flex;
        align-items: left;
        flex-direction: column;
        justify-content: center;

        &-name {
          color: #fff;
          font-size: 24px;
          font-family: Eina01-Bold;
          letter-spacing: -0.05em;
          line-height: 1.1em;

          @media screen and (min-width: 1024px) {
            font-size: 32px;
          }

          @media screen and (min-width: 1280px) {
            font-size: 36px;
          }

          @media screen and (min-width: 1440px) {
            font-size: 48px;
          }
        }

        &-category {
          color: rgba(255, 255, 255, 0.5);
          font-size: 14px;
          font-family: Eina01-Bold;
          letter-spacing: -0.025em;
          line-height: 1.1em;

          @media screen and (min-width: 1024px) {
            font-size: 16px;
          }

          @media screen and (min-width: 1280px) {
            font-size: 21px;
          }

          @media screen and (min-width: 1440px) {
            font-size: 24px;
          }
        }
      }

    }

    &-actions {
      position: relative;

      &__label {
        font-size: 10px;
        padding: 4px 8px;
        color: rgba(#282828, 0.7);
        background-color: #ebebeb;
        font-weight: bold;
        letter-spacing: -0.025em;
        font-family: Eina01-Bold;
        line-height: 1;
        display: flex;
        text-transform: uppercase;
        position: absolute;
        top: 32px;
        right: 32px;
        z-index: 1;
        border: 1px solid rgba(0, 0, 0, 0.025);

        &--beta {
          background-color: #ED6238;
          color: rgba(255, 255, 255, 0.7);
        }

        &--development {
          background-color: #47ccdf;
        }

        &--live {
          background-color: rgb(0, 208, 133);
        }

        &--upcoming {
          background-color: rgba(244, 232, 47, 1);
        }

        &--new {
          background-color: #47ccdf;
        }
      }

      grid-area: 2 / 1 / 3 / 5;

      @media screen and (min-width: 1024px) {
        grid-area: 1 / 6 / 2 / 8;
      }

      display: flex;
      align-items: left;
      flex-direction: column;
      padding:32px;
      gap:24px;
      background-color: rgba(255, 255, 255, 0.05);
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);

      &-box {
        &__title {
          font-size: 12px;
          font-weight: bold;
          color: rgba(#fff, 0.2);
          font-family: Eina01-Bold;
        }

        &__content {
          font-size: 12px;
          font-weight: bold;
          color: rgba(#fff, 1);
          position: relative;

          a {
            color: #47ccdf;
            text-decoration: underline;
            transition: all ease .2s;

            &:hover {
              color: #fff;
            }

            &.install-now-btn {

              background-color: #0061fe;
              color: #fff;
              position: absolute;
              width: 100%;
              text-align: center;
              text-decoration: none;
              font-size: 21px;
              font-weight: bold;
              font-family: Eina01-Bold;
              letter-spacing: -0.5px;
              top: 0;
              padding: 32px 0px;

              &:hover {
                background-color: #47ccdf;
                color: #282828;
              }
            }
          }
        }
      }

    }
  }

  &__cover {
    padding: 200px 0 0px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #282828;
    //border-top:1px solid rgba(255,255,255,0.1);
    //border-bottom:1px solid rgba(255,255,255,0.1);
    background-color: #282828;

    .parent {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, auto);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      position: relative;
      .spot {
        position: absolute;
        right:0;
        bottom:0;
        display: flex;
        align-items: end;
        justify-content: end;
        flex-direction: column;
        .credit-badge {
          text-transform: uppercase;
          font-weight: 600;
          opacity: 0.75;
        }
        .credit-amount {
          font-size:64px;
          color:#fff;
          font-weight: bold;
          letter-spacing: -2.5px;
        }

      }
      .div1 {
        grid-area: 2 / 1 / 3 / 5;
      }

      .div2 {
        grid-area: 3 / 1 / 4 / 5;
      }

      @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(4, auto);

        .div1 {
          grid-area: 2 / 2 / 3 / 6;
        }

        .div2 {
          grid-area: 3 / 4 / 4 / 7;
        }

      }
    }





    &__title {
      font-size: 48px;
      font-family: Eina01-Bold;
      color: #fff;
      letter-spacing: -0.05em;
      line-height: 1;

      span.shine {
        color: #aaa;
      }

      @media screen and (min-width: 768px) {
        font-size: 64px;
      }

      @media screen and (min-width: 1024px) {
        font-size: 96px;
      }

    }

    &__content {
      &-desc {
        font-size: 24px;
        font-weight: 600;
        font-family: Eina01;
        letter-spacing: -0.05em;
        line-height: 1.3em;
        color: rgba(#fff, 0.5);
        margin-top: 32px;
        margin-bottom: 32px;

        @media screen and (min-width: 1024px) {
          font-size: 28px;
        }

        @media screen and (min-width: 1280px) {
          font-size: 32px;
        }

        span.date {
          font-size: 16px;
          display: block;
          color: #47ccdf;
          font-family: Eina01-Bold;
          letter-spacing: -0.025em;
        }
      }

      &-cta {
        margin-top: 24px;
        font-size: 16px;
        font-weight: 600;
        font-family: Eina01;
        color: #fff;
        transition: all ease .2s;
        transform: scale(1);
        text-decoration: underline;
        display: flex;
        gap: 8px;

        svg {
          fill: #fff;
          transition: all ease .2s;
        }

        &:hover {
          color: #47ccdf;
          transform: scale(1.05);

          svg {
            fill: #47ccdf;
          }
        }
      }
    }
  }
}