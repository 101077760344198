.form-popup {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  -webkit-overflow-scrolling: touch;
  top:-100%;
  transition:all ease .300s;
  visibility: hidden;

  &.show{
    top: 0;
    visibility: visible;
  }

  &--container {
    opacity:0;
    visibility:hidden;
    transition:opacity linear .2s;
    transition-delay: .5s;
    .show & {
      opacity:1;
      visibility: visible;
    }
    display: grid;
    grid-template-columns: repeat(4, minmax(60px, 1fr));
    grid-auto-rows: 1fr;
    &::before {
      content: "";
      width: 0;
      padding-bottom: 100%;
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }
    .close-icon {
      padding-top: 100px;
      padding-bottom: 50px;
      svg{
        cursor: pointer;
        .bg-black & {
          stroke:#fff;
        }
      }
      
    }
    .heading {
      h1 {
        font-family: Eina01-Bold;
        font-size: 42px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.1em;
        letter-spacing: -0.075em;
        color: #282828;
        b {
          color:#47ccdf;
        }
        .bg-black & {
          color:#fff;
        }
        max-width: 75%;
        @media screen and (min-width: 768px) {
          font-size: 56px;
        }
      }
      p {
        padding-top: 1.6rem;
        padding-bottom: 1.75rem;
        line-height: 1.3em;
        letter-spacing: -0.025em;
        font-weight: bold;
        color:rgba(#282828,0.65);
        font-family: Eina03;
        font-size: 14px;
        b{
          color:#47ccdf;
          opacity: 1 !important;
        }
        color: #282828;
        .bg-black & {
          color:#fff;
        }

        @media screen and (min-width: 768px) {
          font-size: 24px;
          padding-top: 50px;
          padding-bottom: 60px;
        }
      }
    }
    .form-wrapper {
      h2 {
        font-family: Eina01;
        font-size: 42px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: -2.33px;
        color: #282828;
        .bg-black & {
          color:#fff;
        }
        max-width: 75%;
        @media screen and (min-width: 768px) {
          font-size: 56px;
        }
      }
      h2 + p {
        padding-top: 1.6rem;
        padding-bottom: 1.75rem;
        opacity: 0.4;
        font-family: Eina03;
        font-size: 14px;
        line-height: 1.5;
        color: #282828;
        .bg-black & {
          color:#fff;
        }

        @media screen and (min-width: 768px) {
          font-size: 24px;
          padding-top: 50px;
          padding-bottom: 60px;
        }
      }
        form {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 3rem;
            @media screen and (min-width: 768px) {
              padding-bottom: 6.25rem;
            }
        }
      .input-wrapper {
        position: relative;
        height: 72px;
        border-bottom:1px solid rgba(0,0,0,0.05);
        margin-bottom:24px;
        width: 100%;
        box-sizing: border-box;
        .bg-black & {
          border-bottom:1px solid rgba(255,255,255,0.05);
        }
        label{
          //visibility: hidden;
        }
        input, select {
          background-color: transparent;
          .bg-black & {
            background:#282828;
          }
          position: absolute;
          height: 100%;
          width: 100%;
          font-family: Eina01;
          font-weight: bold;
          font-size: 1rem;
          line-height: 68px;
          color: rgba(0, 0, 0, 0.25);
          outline: none;
          z-index: 1;
          &::placeholder {
            opacity: .6;
          }
          &:focus {
             .bg-black & {
                color:#fff;
              }

            color: #282828;
          }
          &:valid {
            color: #282828;
            .bg-black & {
                color:#fff;
            }
          }
          &:invalid {
            color: #fa6400;
          }
          @media screen and (min-width: 768px) {
            font-size: 2rem;
          }
        }

        label {
          width: 100%;
          height: 100%;
          display: block;
          position: relative;
          //display: none;
          z-index: 0;
          
          &::before {
            content: "";
            width: 10px;
            height: 10px;
            background-color: black;
            position: absolute;
            left: 0px;
            bottom: 0;
            //transform: translateY(-50%);
          }
        }
        select:focus + label, input:focus + label {
          display: block;
        }
        select:invalid + label::before, input:invalid + label::before {
          background-color: #fa6400;
          display: block;
        }
        
        p {
            color: #FA6400;
            font-family: Eina01;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            top:10px;
            position: relative;
            @media screen and (max-width: 768px) {
              top:4px;
              z-index: 1;
              position: relative;
            }
            //position: relative;
            //z-index: 1;
        }
      }
      button {
        position: relative;
        .bg-black & {
                color: #282828;
          }
        &:disabled{
          
          .btn-loading-wrapper {
            opacity:1;
          }

          span {
            opacity:0.2;
          }
          
        }
        span {
            opacity:1;
            transition:opacity linear .2s;
        }
        padding: 1rem 3rem;
        background-color: #282828;
        .bg-black & {
                background-color:#fff;
         }
        font-family: Eina04;
        font-size: 12px;
        font-weight: bold;
        color: white;
        @media screen and (min-width: 768px) {
          padding: 1.3rem 4.3rem;
          font-size: 24px;
        }

       
      }
    }
    p.terms-content{
      margin-top: 27px;
      font-family: Eina03;
      font-size: 12px;
      color: #282828;
      .bg-black & {
            color: #FFF;
      }
      padding-bottom: 100px;
      a {
        color: #47ccdf;
      }
    }
    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media screen and (min-width: 1024px) {
      grid-template-columns: repeat(8, 1fr);
    }
    @media screen and (min-width: 1280px) {
      grid-template-columns: repeat(8, 1fr);
    }
    @media screen and (min-width: 1600px) {
      grid-template-columns: repeat(8, 1fr);
    }
  }
}


@-webkit-keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.btn-loading-spinner {
  -webkit-animation-duration: 0.75s;
  -moz-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: rotate-forever;
  -moz-animation-name: rotate-forever;
  animation-name: rotate-forever;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  animation-timing-function: linear;
  height: 24px;
  width: 24px;
  border: 4px solid #ffffff;
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
  .bg-black & {
       border: 4px solid #282828;
  }
}
.btn-loading-wrapper{
  position: absolute;
  top: 52%;
  right: 53px;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity linear .2s;
}

.btn-loading-spinner {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 50%;
  margin: -15px 0 -15px;
}
