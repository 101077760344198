
.supported-formats{
    &-bg{
        background-color: #f7f7f7;
        position: relative;
        color:#282828;
        padding:128px 0px 64px;
        border-top:1px solid rgba(0,0,0,0.05);
        color:#282828;
        &:before {
          position: absolute;
          width:50%;
          //background-color: #fff;
          //background-image: url(../../../Assets/Images/supported-files-bg.svg);
          //background-position:center center;
          //background-size:auto 100%;
          background-size: cover;
          height: calc(100% + 1px);
          opacity: 0.8;
          background-repeat: no-repeat;
          top:-1px;
          right:0;
          z-index: 0;
          @media screen and (min-width: 1024px) {
            content:"";
          }
        }
    }
    &__container{
      position: relative;
    }
    &__menu {
      font-size:18px;
      font-weight: bold;
      width: 60%;
      text-align: center;
      margin: 0 auto 32px;
      @media screen and (min-width: 1024px) {
        text-align: left;
        margin:0;
        margin-bottom:64px;
      }
      &-item {
        padding: 8px;
        color:#aaa;
        cursor: pointer;
        letter-spacing: -0.025em;
        &.active, &:hover {
          color:#282828;
          font-family: Eina01-Bold;
        }
      }
    }
    &__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(3, 72px);
      grid-column-gap: 20px;
      grid-row-gap: 0px;
      color:#fff;
      .item1 { grid-area: 1 / 2 / 2 / 3; }
      .item2 { grid-area: 2 / 1 / 3 / 2; }
      .item3 { grid-area: 2 / 3 / 3 / 4; }
      .item4 { grid-area: 3 / 2 / 4 / 3; }
      .item5 { grid-area: 1 / 4 / 2 / 5; }
      .item6 { grid-area: 3 / 4 / 4 / 5; }
      
      @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(4, 128px);
        grid-template-rows: repeat(4, 128px);
        grid-column-gap: 20px;
        .item1 { grid-area: 1 / 2 / 2 / 3; }
        .item2 { grid-area: 2 / 3 / 3 / 4; }
        .item3 { grid-area: 3 / 1 / 4 / 2; }
        .item4 { grid-area: 4 / 3 / 5 / 4; }
        .item5 { grid-area: 3 / 2 / 4 / 3; }
        .item6 { grid-area: 3 / 4 / 4 / 5; }
  

      }
      
      &.show {
      
        .supported-formats__list-item {
          opacity:1;
          top:0;
        }
      }
      &.hide {
        .supported-formats__list-item {
          opacity:0;
          top:200%;
        }
      }
      
      
      &-item{
        &__wrapper{
          $n: 8;
          @for $x from 1 through $n{
            &:nth-child(#{$x}) {
              .supported-formats__list-item {
                transition-delay: 50ms * ($x - 1) / $x * $n;
              }
            }
          }
        }
        position: absolute;
        width:100%;
        height:100%;
        top:-200%;
        left:0;
        padding:16px 8px;
        box-shadow:0px 0px 25px rgba(0,0,0,0.10);
        //border:1px solid rgba(0,0,0,0.05);
        transition: all ease .3s;
        color:#282828;
        opacity: 0;
        display: flex;
        flex-direction: column;
        background-color: #47ccdf;
        justify-content: flex-end;
        &__icon{
          display: flex;
          justify-content: center;
          position: absolute;
          top:0;
          right:0;
        }
        &__extension{
          font-size:16px;
          font-family: Eina01-Bold;
          letter-spacing: -0.05em;
          color:rgba(40,40,40,0.6);
          mix-blend-mode: darken;
          padding-left:8px;
          position: relative;
        }
        &.supported{
          background-color: #282828;
          color: #47ccdf;
        }
        
      }
    }

    &__wrapper{
      .parent {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, auto);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        .div1 { grid-area: 1 / 1 / 2 / 5; }
        .div2 { grid-area: 2 / 1 / 3 / 5; }

        @media screen and (min-width: 1024px) {
          grid-template-columns: repeat(8, 1fr);
grid-template-rows: 1fr;
          .div1 { grid-area: 1 / 2 / 2 / 5; }
.div2 { grid-area: 1 / 5 / 2 / 8; }

          .div2 {
            display: flex; align-items: center; justify-content: flex-end;
          }
        }
        }
        
     
        
    }
    &__title{
      &-wrapper{
        width:100%;
        display: flex;
        justify-content: left;
        align-items: left;
      }
      @media screen and (min-width: 1024px) {
        justify-content: left;
        align-items: left;
        max-width: 80%;
      }
      align-items: left;
      justify-content: left;
      font-size:32px;
      line-height: 1em;
      
      margin-bottom:24px;
      letter-spacing:-0.05em;
      font-family: Eina01-Bold;
      color:#282828;
      text-align: center;
      position: relative;
      @media screen and (min-width: 1024px) {
        text-align: left;
      }
   
  
      @media screen and (min-width: 768px) {
        font-size:48px;
        line-height: 1em;
        margin-bottom:24px;
        letter-spacing:-0.05em;
      }
  
      @media screen and (min-width: 1024px) {
        font-size:60px;
        line-height: 1em;
        margin-bottom:24px;
        letter-spacing:-0.05em;
      }
  
      @media screen and (min-width: 1280px) {
        font-size:72px;
        line-height: 1em;
        margin-bottom:24px;
        letter-spacing:-0.05em;
      }
      
      span.highlight{
        color:rgba(40,40,40,0.5);
      }
      b {
        color:rgba(40,40,40,0.5);
      }
      &-wrapper{}
    }
   
}




