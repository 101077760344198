.product-details-header{
  background-color: #47ccdf;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:100px 0;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    //padding-top:0;
  }
  @media screen and (min-width: 1280px) {
    padding:200px 0;
  }

  &__top{
    display: grid;
    grid-template-columns: repeat(4, 25%);
    grid-template-rows: repeat(1, auto);
    padding:32px 0px 0px;
    @media screen and (min-width: 1024px) {
      padding:0px 0px;
      grid-template-columns: repeat(8, 1fr);
    }
    &-inner{
      grid-area: 1 / 1 / 2 / 5;
      @media screen and (min-width: 1024px) {
        grid-area: 1 / 2 / 2 / 8;
      }
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__pen{
      font-size:14px;
      color:rgba(40,40,40,0.25);
      font-weight: bold;
      letter-spacing:0;
      font-family: Eina01-Bold;
      line-height: 1;
      mix-blend-mode:multiply;
      position: relative;
      text-transform: uppercase;
      display: flex;
   
    }
    &__status{
      background-color: #ebebeb;
      font-size:14px;
      padding:6px 12px 3px;
      color: rgba(#282828, 0.7);
      font-weight: bold;
      letter-spacing: -0.05em;
      font-family: Eina01-Bold;
      text-transform: uppercase;
      line-height: 1;
      mix-blend-mode:multiply;
      border: 1px solid rgba(0,0,0,0.025);
      @media screen and (min-width: 1024px) {
        font-size:24px;
        padding:8px 24px 5px;
      }
      //border-radius: 24px;



        &--beta{
          background-color: #ED6238;
          color: rgba(255,255,255,0.7);
        }
        &--development{
          background-color: #47ccdf;
        }
        &--live{
          background-color: rgb(0, 208, 133);
        }
        &--upcoming{
          background-color: rgba(244,232,47, 1);
        }
        &--new{
          background-color: #47ccdf;
        }



     
    }
  }

  .parent {
    display: grid;
    grid-template-columns: repeat(4, minmax(25%, 1fr));
    grid-template-rows: repeat(3, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .div1 { grid-area: 1 / 1 / 2 / 5; }
    .div2 { grid-area: 1 / 1 / 2 / 5; }
    .div3 { grid-area: 2 / 1 / 3 / 5; }
    .div4 { grid-area: 3 / 1 / 4 / 5; }
    .div5 { grid-area: 4 / 1 / 5 / 2; }
    .div6 { grid-area: 4 / 4 / 5 / 5; }
    .div7 { grid-area: 4 / 2 / 5 / 3; }


      @media screen and (min-width: 640px) {
      grid-template-columns: repeat(4, minmax(128px, 1fr));
      }

      @media screen and (min-width: 768px) {
        .div1 { grid-area: 1 / 1 / 2 / 2; }
        .div2 { grid-area: 1 / 2 / 2 / 5; }
        .div3 { grid-area: 2 / 1 / 3 / 2; }
        .div4 { grid-area: 2 / 1 / 3 / 5; }
        .div5 { grid-area: 3 / 1 / 4 / 2; }
        .div6 { grid-area: 1 / 4 / 2 / 5; }
        .div7 { grid-area: 3 / 4 / 4 / 5; }
      grid-template-columns: repeat(4, minmax(153.39px, 1fr));
      grid-template-rows: repeat(3, auto);
    

      }
      @media screen and (min-width: 1024px) {
      grid-template-rows: repeat(6, 100px);
      grid-template-columns: repeat(8, minmax(100px, 1fr));
     
      .div1 { grid-area: 2 / 2 / 3 / 4; }
      .div2 { grid-area: 2 / 4 / 3 / 8; }
      .div3 { grid-area: 3 / 3 / 4 / 4; }
      .div4 { grid-area: 4 / 3 / 5 / 7; }
      .div5 { grid-area: 5 / 2 / 6 / 3; }
      .div6 { grid-area: 3 / 7 / 4 / 8; }
      .div7 { grid-area: 5 / 7 / 6 / 8; }

      }
      @media screen and (min-width: 1280px) {        
      grid-template-columns: repeat(8, minmax(160px, 1fr));
      }
      @media screen and (min-width: 1600px) {
      grid-template-columns: repeat(8, minmax(200px, 1fr));
      
      }


      }


      .product-icon {
        mix-blend-mode: multiply;
        width: 128px;
        height: 128px;
        
        @media screen and (min-width: 768px) {
          width: 256px;
          height: 256px;
        }
        @media screen and (min-width: 1024px) {
          width: 256px;
          height: 256px;
        }
        
          
        &__wrapper{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
      }
      .product-alias {
        position: relative;
        &__name {
          font-size:28px;
          color:rgba(40,40,40,0.25);
          font-weight: bold;
          letter-spacing: -2px;
          font-family: Eina01-Bold;
          line-height: 1;
          mix-blend-mode:multiply;
          position: relative;
          &:after{
            position: absolute;
            content:"";
            width:24px;
            height:24px;
            background-color: #282828;
            right:50%;
            top:-48px;
            @media screen and (min-width: 768px) {
              right:-48px;
              bottom:21px;
              top:auto;
            }
          }
          &:before{
            position: absolute;
            content:"";
            width:16px;
            height:16px;
            background-color: #47ccdf;
            right:50%;
            top:-48px;
            z-index: 1;
            @media screen and (min-width: 768px) {
              right:-64px;
              bottom:6px;
              top:auto;
            }
          }
          @media screen and (min-width: 768px) {
            font-size:48px;
            letter-spacing: -2px;
          }
          @media screen and (min-width: 1024px) {
            font-size:64px;
            letter-spacing: -3px;
          }
          @media screen and (min-width: 1280px) {
            font-size:96px;
            letter-spacing: -4px;
          }
        }
        
        &__wrapper{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          text-align: center;
          @media screen and (min-width: 768px) {
            justify-content: flex-start;
            align-items: center;
            text-align: left;
          }
          
        }
      }

      .product-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap:24px;
        margin-top:32px;
        
        @media screen and (min-width: 768px) {
          margin-top:96px;
        }
        @media screen and (min-width: 1024px) {
          gap:32px;
        }

        @media screen and (min-width: 1024px){
          margin-top:0px;
        }

        &__name{
          font-size:32px;
          color:#282828;
          font-weight: bold;
          letter-spacing: -1px;
          font-family: Eina01-Bold;
          line-height: 1.3;
          display: block;
          max-width: 420px;
          text-align: center;
          mix-blend-mode: multiply;
          @media screen and (min-width: 1024px) {
            font-size:48px;
            letter-spacing: -3px;
            max-width: 540px;
          }
          span.highlight{
            color:#47ccdf;
          }
          span.shine{
            color:#aaa;
          }
        }
        &__description{
          font-size:14px;
          color:rgba(40,40,40,0.5);
          font-weight: bold;
          font-family: Eina01;
          display: block;
          max-width: 480px;
          text-align: center;
          @media screen and (min-width: 1024px) {
            max-width: 600px;
            font-size: 16px;
          }
        }
    
        &__cta-btn{
          padding:12px 48px;
          font-family: Eina01;
          font-size:1em;
          color:#282828;
          font-weight: bold;
          border:2px solid #282828;
          transition: all ease .2s;
          transform:scale(1);
          width: 100%;
          justify-content: center;
          
          @media screen and (min-width: 540px) {
            width:auto;
          }
          &:hover{
            background-color: #282828 !important;
            transform: scale(1.1);
            color:#fff !important;
          }
          &__wrapper{
            display: flex;
            gap:16px;
            flex-direction: column;
            @media screen and (min-width: 768px) {
              flex-direction: row;
            }
            div + div {
              .product-content__cta-btn  {
                background-color: #282828;
                color:#fff;
            }
            }
            
          }
        }

        &__wrapper {          
        }
      }
      .product-shape-square-left {
        position: relative;
        left: -75px;
        top: 75px;
        mix-blend-mode: hard-light;
        z-index: -1;
        display: none;
        @media screen and (min-width: 768px) {
          z-index: 0;
          display: flex;
        }
        &__wrapper{
          
        }
      }
      .product-shape-linear-right {
        display: flex;
          justify-content: flex-end;
        &__wrapper{
          
          
        }
      }
      .product-shape-linear-left {
        display: flex;
        justify-content: flex-start;
        &__wrapper{
          
          
        }
      }
      .product-shape-square-right {
        position: relative;
        left: -75px;
        top: 75px;
        &__wrapper{
          
        }
      }

    
    
    
}