
.hero-text-tout-with-list{
    &-bg{
        background-color: #282828;
        border-bottom:1px solid rgba(255,255,255,0.1);
        border-top:1px solid #282828;
        position: relative;
        color:#282828;
        padding:64px 0px 128px;
        &__bottom{
          padding-bottom:0px;
          overflow: hidden;
        }

        //default, large-both, small-both, large-top, large-bottom, small-both, small-top, small-bottom
        &--default{
          padding:64px 0px 128px;
        }
        &--large-both{
          padding:200px 0px 200px;
        }
        &--large-top{
          padding-top:200px;
        }
        &--large-bottom{
          padding-bottom:200px;
        }
        &--small-both{
          padding:64px 0px 64px;
        }
        &--small-top{
          padding-top:64px;
        }
        &--small-bottom{
          padding-bottom:64px;
        }

        &--light{
          background-color: #fff;
          border-top:1px solid rgba(0,0,0,0.1);
          color:#282828;
          .hero-text-tout-with-list__title-primary{
            color:#282828;
            
          }
          .hero-text-tout-with-list__title-secondary{
            color:rgba(#282828, 0.5);
          }
        }
        &--cyan{
          background-color: #47ccdf;
          border:0px;
          color:#282828;
          padding-bottom:200px;
          .hero-text-tout-with-list__title-primary{
            color:#282828;
            
          }
          .hero-text-tout-with-list__title-secondary{
            color:rgba(#282828, 0.5);
          }
        }
    }
    
    &__wrapper{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, auto);
      
      grid-column-gap: 0px;
      grid-row-gap: 64px;
      @media screen and (min-width: 1024px) { 
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(2, auto);
      }
    }
    &__title{
      grid-area: 1 / 1 / 2 / 5;
      &-primary{
        font-size:32px;
        color:#fff;
        font-family: Eina01-Bold;
        font-weight: bold;
        line-height: 1.1em;
        letter-spacing: -0.05em;
        margin-bottom:32px;
        b, span.highlight{ color:#47ccdf;}
        strong, span.highlight-gray{color:#aaa;}
        @media screen and (min-width: 1024px) { 
          font-size:48px;
        }
        @media screen and (min-width: 1024px) { 
          font-size:72px;
        }
      }
      &-secondary{
        font-size:18px;
        color:#fff;
        font-family: Eina01;
        font-weight: bold;
        line-height: 1.3em;
        letter-spacing: -0.05em;

      

        b, span.highlight{ color:#47ccdf;}
        strong, span.highlight-gray{color:#aaa;}
        @media screen and (min-width: 1024px) { 
          font-size:24px;
        }
        @media screen and (min-width: 1024px) { 
          font-size:28px;
        }
      }
      @media screen and (min-width: 1024px) { 
        grid-area: 1 / 2 / 2 / 8; 
      }
    }
    &__content{
      grid-area: 2 / 1 / 3 / 5;
      @media screen and (min-width: 1024px) { 
        grid-area: 2 / 2 / 3 / 8;
      }
      &-list{
        display: grid;
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: auto;
          grid-column-gap: 32px;
          grid-row-gap:32px;
          @media screen and (min-width: 1024px) { 
            grid-template-columns: repeat(2, 1fr);
            grid-row-gap:32px;
          }

          @media screen and (min-width: 1280px) { 
            grid-template-columns: repeat(4, 1fr);
            &[data-column="3"] {
              grid-template-columns: repeat(3, 1fr);
            }
            grid-row-gap:64px;
            grid-column-gap:64px;
          }
         
        
        &__wrapper{
          
        }
        &__item{
          display: flex;
          flex-direction: column;
          gap: 24px;
          max-width: 100%;
          padding:32px;
          box-shadow:0px 0px 50px rgba(0,0,0,0.15);
          background-color: #fff;
          justify-content: space-between;
          transition:all ease .2s;
          transform:scale(1);
          cursor: pointer;
          @media screen and (min-width: 1024px) { 
            //max-width: 90%;
          }

          &--cyan{
            background-color: #47ccdf;
            
            
          }
          &--dark{
            background-color: #282828;
            .hero-text-tout-with-list__content-list__item-image{
              img {
                filter:invert(1);
              }
            }
            .hero-text-tout-with-list__content-list__item-title {
              filter:invert(1);
            }
            .hero-text-tout-with-list__content-list__item-desc {
              filter:invert(1);
            }
            .hero-text-tout-with-list__content-list__item-link {
              color:#fff;
              span.label{
                color:#fff;
              }
              span.icon{
               filter:invert(1);
              }
            }
            &:hover {
              .hero-text-tout-with-list__content-list__item-image{
                img {
                  filter:invert(0);
                }
              }
              .hero-text-tout-with-list__content-list__item-title {
                filter:invert(0);
              }
              .hero-text-tout-with-list__content-list__item-desc {
                filter:invert(0);
              }
              .hero-text-tout-with-list__content-list__item-link {
                color:#282828;
                span.label{
                  color:#282828;
                }
                span.icon{
                 filter:invert(0);
                }
              }
            }
            
          }
          &:hover {
            transform:scale(1.1);
            background-color: #47ccdf;
            .hero-text-tout-with-list__content-list__item-link{
              text-decoration: underline;
            }
          }
          &-image{
            max-width: 64px;
            max-height: 64px;
            img {
              width: 50%;
            }
          }
          &-title{
            font-size:21px;
            color:#282828;
            font-family: Eina01-Bold;
            letter-spacing: -0.05em;
            line-height: 1.1em;
            max-width: 70%;
          }
          &-desc{
            font-size:14px;
            font-family: Eina01-Bold;
            letter-spacing: -0.025em;
            color:rgba(#282828, 0.6)
          }
          &-link{
            display: flex;
            gap: 8px;
            span.icon{
              svg {
                height: 18px;
              }
            }
            span.label{
              font-size:14px;
              font-family: Eina01-Bold;
              letter-spacing: -0.025em;
              color:rgba(#282828, 1)
            }
          } 
        }
      }
    }
}


