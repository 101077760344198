.language-tester {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #333;
  color: #fff;
  z-index: 333333333;
}

.homepage {

  &-solutions {
    &__cover {
      padding: 200px 0 0px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #282828;
      background-color: #47ccdf;

      &--dark {
        background-color: #282828;

        .homepage-solutions__cover__title {
          color: #fff;
        }

        .homepage-solutions__cover__content-desc {
          color: #fff;
        }
      }

      &--light {
        background-color: #fff;

        .homepage-solutions__cover__title {
          color: #282828;

          span {
            color: #47ccdf;
          }
        }

        .homepage-solutions__cover__content-desc {
          //color:#fff;
        }
      }

      .parent {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, auto);
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        .div1 {
          grid-area: 2 / 1 / 3 / 5;
        }

        .div2 {
          grid-area: 3 / 1 / 4 / 5;
        }

        @media screen and (min-width: 1024px) {
          grid-template-columns: repeat(8, 1fr);
          grid-template-rows: repeat(4, auto);

          .div1 {
            grid-area: 2 / 2 / 3 / 6;
          }

          .div2 {
            grid-area: 3 / 4 / 4 / 7;
          }

        }
      }

      &__title {
        font-size: 32px;
        font-family: Eina01-Bold;
        color: #282828;
        letter-spacing: -0.05em;
        line-height: 1;

        span {
          color: #fff;
        }

        @media screen and (min-width: 640px) {
          font-size: 48px;
        }

        @media screen and (min-width: 768px) {
          font-size: 64px;
        }

        @media screen and (min-width: 1024px) {
          font-size: 96px;
        }

      }

      &__content {
        &-desc {
          font-size: 21px;
          font-weight: 600;
          font-family: Eina01;
          letter-spacing: -0.05em;
          line-height: 1.3em;
          color: rgba(#282828, 0.5);
          margin-top: 32px;
          margin-bottom: 32px;

          @media screen and (min-width: 768px) {
            font-size: 24px;
          }

          @media screen and (min-width: 1024px) {
            font-size: 28px;
          }

          @media screen and (min-width: 1280px) {
            font-size: 32px;
          }

          span.date {
            font-size: 16px;
            display: block;
            color: #47ccdf;
            font-family: Eina01-Bold;
            letter-spacing: -0.025em;
          }
        }

        &-cta {
          margin-top: 24px;
          font-size: 16px;
          font-weight: 600;
          font-family: Eina01;
          color: #fff;
          transition: all ease .2s;
          transform: scale(1);
          text-decoration: underline;
          display: flex;
          gap: 8px;

          svg {
            fill: #fff;
            transition: all ease .2s;
          }

          &:hover {
            color: #47ccdf;
            transform: scale(1.05);

            svg {
              fill: #47ccdf;
            }
          }
        }
      }
    }
  }

  .home-feature-wrapper {
    position: relative;

    .shadow {
      position: absolute;
      content: "";
      bottom: 0;
      width: 100%;
      height: 400px;
      left: 0;
      opacity: 1;
      z-index: 1;
      transition: opacity linear .275s;
      background: url('../../Assets/Images/shadow.svg');
      cursor: pointer;

      &:hover {
        //z-index: -1;
      }
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, minmax(60px, 1fr));
    grid-auto-rows: 1fr;

    &::before {
      content: "";
      width: 0;
      padding-bottom: 100%;
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }
  }

  .exploreBox {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #47ccdf;
    font-weight: bold;
    font-family: Eina01;
    text-align: center;
    color: #181818;
    padding: 2rem;
    transition: transform .175s ease;

    &:hover {
      transform: scale(1.1);
    }

    button {
      outline: none;
      position: absolute;
      width: 100%;
      height: 100%;
      font-size: 12px;
      font-weight: 900;


      @media screen and (min-width: 768px) {
        font-size: 14px;
      }

      @media screen and (min-width: 1024px) {
        font-size: 16px;
      }
    }

    svg {
      position: absolute;
      margin: auto;
      bottom: 0.5rem;
      stroke: #333;

      @media screen and (min-width: 768px) {
        bottom: 1rem;
      }
    }

    @media screen and (min-width: 1024px) {
      padding: 3rem;
    }
  }

  &-firstSection {
    position: relative;

    .the {
      font-size: 30px;
      top: 50%;
      position: relative;
      transform: translateY(-50%);
    }

    button {

      &:focus,
      &:hover,
      &:active {
        outline: 0;
      }
    }

    .heading-wrapper {
      position: relative;
      height: 100%;
      margin-bottom: 6rem;

      .phase {
        opacity: 0.2;
        font-family: Eina01-Bold;
        font-size: 12px;
        color: white;
        text-transform: uppercase;
        margin-bottom: 1.2rem;
      }

      h1 {
        font-family: Eina01;
        font-size: 32px;
        font-weight: 600;
        line-height: 1.2;
        max-width: 100%;
        text-align: center;
        margin-bottom: 1rem;
      }

      .shortcut {
        //position: absolute;
        font-family: Eina01;
        font-size: 12px;
        text-align: center;
        color: white;
        margin-top: 20px;

        span {
          padding: 4px 6px 2px 6px;
          border-radius: 2px;
          background-color: rgba(255, 255, 255, 0.2);
          margin: 0.3rem;
        }
      }

      .mobile-cta--wrapper {
        text-align: left;
        margin-top: 2rem;
        margin-bottom: 3rem;
      }

      .mobile-cta--text {
        text-align: left;
        color: #666;
      }
    }

    .mobile-cta--get-access {
      background: #202020;
      padding: 1.5rem 4rem;
      font-family: Eina01-Bold;
      font-size: 14px;
      margin-top: 3rem;
      text-transform: uppercase;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(1);
      transition-property: background-color, transform, opacity;
      transition-duration: 0.275s;
      transition-timing-function: cubic-bezier(1, 1, 1, 1.01);
      z-index: 3;
      width: 320px;
      max-width: 100%;

      @media screen and (min-width: 1280px) {
        font-size: 16px;
      }

      &:before {
        position: absolute;
        content: "";
        width: 32px;
        height: 32px;
        background: #fff;
        top: -16px;
        left: -16px;
        border-radius: 50%;
        box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.23);
        //border: 4px solid rgba(255, 255, 255, 0.2) !important;
      }

      &.light {
        background: #47ccdf;
        color: #282828;
      }

      &:hover {
        transform: translate(-50%, -50%) scale(1.05);
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.23);
      }
    }

    .aboutus-text-wrapper {
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      &::before {
        content: "|";
        color: white;
        position: absolute;
        font-size: 14px;
        top: -3px;

        @media screen and (min-width: 1280px) {
          font-size: 20px;
        }
      }

      p {
        margin-left: 20px;
      }

      p:first-child {
        font-family: Eina01;
        font-size: 10px;
        font-weight: bold;
        margin-bottom: 10px;
        text-transform: uppercase;

        @media screen and (min-width: 1280px) {
          font-size: 14px;
        }
      }

      p:nth-child(2) {
        opacity: 0.66;
        font-family: Eina04;
        font-size: 10px;
        line-height: 1.5;
        max-width: 90%;

        @media screen and (min-width: 1280px) {
          font-size: 14px;
        }
      }
    }
  }

  .pulsating-circle {
    position: absolute;
    left: 0%;
    top: 0%;
    transform: translateX(-50%) translateY(-50%);
    width: 30px;
    height: 30px;

    &:before {
      content: '';
      position: relative;
      display: block;
      width: 300%;
      height: 300%;
      box-sizing: border-box;
      margin-left: -100%;
      margin-top: -100%;
      border-radius: 45px;
      background-color: #47ccdf;
      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: white;
      border-radius: 15px;
      box-shadow: 0 0 8px rgba(0, 0, 0, .3);
      animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
    }
  }

  @keyframes pulse-ring {
    0% {
      transform: scale(.33);
    }

    80%,
    100% {
      opacity: 0;
    }
  }

  @keyframes pulse-dot {
    0% {
      transform: scale(.8);
    }

    50% {
      transform: scale(1);
    }

    100% {
      transform: scale(.8);
    }
  }

  &-secondSection {
    .addBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p:nth-child(1) {
        font-family: Eina01;
        font-weight: bold;
        font-size: 14px;
        color: white;

        span {
          opacity: 0.4;
        }

        @media screen and (min-width: 480px) {
          font-size: 16px;
        }


        @media screen and (min-width: 1024px) {
          font-size: 24px;
        }

        @media screen and (min-width: 1024px) {
          font-size: 24px;
        }

        @media screen and (min-width: 1280px) {
          font-size: 32px;
        }
      }

      p:nth-child(2) {
        font-family: Eina01;
        font-size: 8px;
        font-weight: bold;

        @media screen and (min-width: 480px) {
          font-size: 14px;
        }

        @media screen and (min-width: 1024px) {
          font-size: 14px;
        }

        @media screen and (min-width: 1280px) {
          font-size: 16px;
        }
      }
    }

    .text-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      position: relative;
      padding-left: 0;

      h1 {
        font-family: Eina01;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.3;
        color: #181818;

        @media screen and (min-width: 480px) {
          font-size: 32px;
        }

        @media screen and (min-width: 1024px) {
          font-size: 32px;
          line-height: 1.3;
        }

        @media screen and (min-width: 1280px) {
          font-size: 42px;
        }
      }

      h1+p {
        font-family: Eina01;
        font-size: 14px;
        line-height: 1.6;
        color: rgba(0, 0, 0, 0.4);
        font-weight: bold;
        margin-top: 1rem;

        @media screen and (min-width: 480px) {
          font-size: 16px;
          margin-top: 2rem;
        }

        @media screen and (min-width: 768px) {
          font-size: 24px;
        }

        @media screen and (min-width: 1024px) {
          font-size: 16px;
          margin-top: 1rem;

        }

        @media screen and (min-width: 1280px) {
          font-size: 18px;
          margin-top: 2rem;
        }
      }


    }

    &--boxContainer {
      div:nth-child(1) {
        grid-area: a;
      }

      div:nth-child(2) {
        grid-area: b;
      }

      div:nth-child(3) {
        grid-area: c;
      }

      div:nth-child(4) {
        grid-area: d;
      }

      div:nth-child(5) {
        grid-area: e;
      }

      div:nth-child(6) {
        grid-area: f;
      }

      div:nth-child(7) {
        grid-area: g;
      }

      div:nth-child(8) {
        grid-area: j;
      }

      &:nth-child(1) {
        grid-template-areas:
          "b a c d"
          "f e g j";

        div:nth-child(2) {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #282828;
        }

        div:nth-child(3),
        div:nth-child(5) {
          opacity: 0.05;
          background-color: #282828;
        }

        div:nth-child(5) {
          opacity: 0.05;
          background-color: #282828;
        }

        div:nth-child(8) {
          background: #47ccdf;
        }
      }

      &:nth-child(3) {
        grid-template-areas:
          "a b d j"
          "e c g f";

        div:nth-child(1),
        div:nth-child(4),
        div:nth-child(6) {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #282828;
        }

        div:nth-child(3) {
          opacity: 0.05;
          background-color: #282828;
        }
      }
    }
  }

  &-thirdSection {
    padding-top: 4rem;
    padding-bottom: 5rem;

    @media screen and (min-width: 1024px) {
      padding-top: 12.5rem;
      padding-bottom: 12.5rem;
    }

    @media screen and (min-width: 1280px) {
      font-size: 32px;
    }

    &--heading {
      width: 300px;
      max-width: 100%;
      margin: 0 auto 2rem;

      @media screen and (min-width: 768px) {
        margin: 0 auto 5rem;
        width: 300px;
        max-width: 100%;
      }

      @media screen and (min-width: 1024px) {
        width: 540px;
      }

      h1 {
        font-family: Eina01;
        font-size: 28px;
        font-weight: bold;
        line-height: 1.3;
        letter-spacing: -1px;
        margin-bottom: 1rem;

        @media screen and (min-width: 768px) {
          margin-bottom: 1rem;
        }

        @media screen and (min-width: 1024px) {
          margin-bottom: 2.65rem;
        }
      }

      h2 {
        opacity: 0.4;
        font-family: Eina01;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.3;
      }
    }


    .box-container {
      width: 100%;
      margin: auto;
      display: grid;
      grid-template-columns: repeat(4, minmax(60px, 1fr));
      grid-auto-rows: 1fr;

      &::before {
        content: "";
        width: 0;
        padding-bottom: 100%;
        grid-row: 1 / 1;
        grid-column: 1 / 1;
      }
    }

    .information-box {
      position: relative;

      &.disabled {
        opacity: 0.2;

        &>.bg-dot::before {
          display: none;
        }
      }

      .solution-hover-content {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;

        .left-top {
          position: absolute;
          background: #fff;
          width: 50%;
          height: 50%;
          top: 0;
          left: 0;
        }

        .right-top {
          position: absolute;
          background: #fff;
          width: 50%;
          height: 50%;
          right: 0;
          top: 0;
        }

        .left-bottom {
          position: absolute;
          background: #fff;
          width: 50%;
          height: 50%;
          bottom: 0;
          left: 0;
        }

        .right-bottom {
          position: absolute;
          background: #fff;
          width: 50%;
          height: 50%;
          right: 0;
          bottom: 0;
        }
      }

      &:nth-child(1) {
        grid-column-start: 1;
        grid-row-start: 1;
      }

      .bg-dot {
        position: relative;
      }

      .bg-dot::before {
        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        background-color: #47ccdf;
        top: 0;
        left: 0;
      }

      h3 {
        font-family: Eina01;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        max-width: 50px;

        @media screen and (min-width: 1024px) {
          font-size: 28px;
        }

        @media screen and (min-width: 1280px) {
          font-size: 32px;
        }
      }

      h3:nth-child(-n + 1) {
        max-width: max-content;
      }

      h5 {
        position: absolute;
        left: -2rem;
        font-size: 14px;
        font-weight: bold;
        text-align: right;
        color: #797979;
        bottom: 5px;

        @media screen and (min-width: 1024px) {
          bottom: 8px;
        }

        @media screen and (min-width: 1280px) {
          bottom: 12px;
        }
      }

      a {
        margin-top: 20px;
        font-family: Eina04;
        font-size: 14px;
        font-weight: bold;
        color: #47ccdf;

        .readmore-icon {
          display: inline-block;
          position: relative;
          top: 4px;

          svg {
            fill: #47ccdf;
            height: 16px;
          }
        }

        @media screen and (min-width: 1024px) {
          font-size: 17px;
        }
      }
    }
  }

  &-fourthSection {
    .news-item {
      a {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }

    h1 {
      font-family: Eina01;
      font-size: 28px;
      font-weight: bold;
      line-height: 2.67;
      letter-spacing: -1px;
      color: #282828;

      @media screen and (min-width: 1024px) {
        font-size: 56px;
        line-height: 1.14;
        letter-spacing: -2.33px;
      }
    }

    h4 {
      font-family: Eina01;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.63;
      color: #bbbbbb;
      max-width: 100%;

      @media screen and (min-width: 1024px) {
        font-size: 33px;
        line-height: 1.27;
      }

      @media screen and (min-width: 1280px) {
        max-width: 85%;
        font-size: 33px;
        line-height: 1.27;
      }
    }

    p {
      font-family: Eina04;
      font-size: 14px;
      font-weight: bold;
      text-align: right;
      color: #47ccdf;

      @media screen and (min-width: 1280px) {
        font-size: 18px;
      }
    }
  }

}

@media screen and (min-width: 768px) {
  .homepage {
    .grid-container {
      grid-template-columns: repeat(4, minmax(96px, 1fr));
    }

    &-firstSection {
      .heading-wrapper {
        h1 {
          font-size: 42px;
          font-weight: 600;
          line-height: 1.2;
          max-width: 100%;
          text-align: center;

        }
      }
    }

    &-secondSection {}
  }
}

@media screen and (min-width: 1024px) {
  .homepage {
    .grid-container {
      grid-template-columns: repeat(8, minmax(128px, 1fr));
    }

    &-firstSection {
      .heading-wrapper {
        h1 {
          font-size: 32px;
          font-weight: 600;
          line-height: 1.2;

        }
      }
    }

    &-secondSection {
      &--boxContainer {
        &:nth-child(1) {
          grid-template-areas:
            "a b c d"
            "e f g j";
        }

        &:nth-child(3) {
          grid-template-areas:
            "a b c d"
            "e f g j";
        }
      }
    }

    &-thirdSection {
      &--heading {
        h1 {
          font-family: Eina01;
          font-size: 56px;
          line-height: 1.14;
          letter-spacing: -2.33px;
        }

        h2 {
          font-size: 24px;
          line-height: 1.5;
        }
      }

      .box-container {
        width: 75%;
        grid-template-columns: repeat(6, minmax(128px, 1fr));
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .homepage {
    .grid-container {
      grid-template-columns: repeat(8, minmax(160px, 1fr));
    }

    &-firstSection {
      .heading-wrapper {
        h1 {
          font-size: 42px;
          font-weight: 600;
          line-height: 1.2;
          //max-width: 700px;
        }
      }
    }

    &-thirdSection {
      .information-box {
        h3 {
          font-size: 42px;
        }

        a {
          font-size: 17px;
        }
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .homepage {
    .grid-container {
      grid-template-columns: repeat(8, minmax(200px, 1fr));
    }
  }
}