.cookie-settings{
  color:#282828;
  &-page{
    &-hero{
      background-color: #47ccdf;
      min-height: 50vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #282828;
      .parent {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        }
        
        .div1 { grid-area: 2 / 2 / 3 / 8; }


    &__header{
      font-size:32px;
        line-height: 1em;
        letter-spacing:-0.05em;
        font-family: Eina01-Bold;
        color:#282828;
        position: relative;
        &:after{
          position: absolute;
          content:"";
          width: 32px;
          height: 32px;
          top:-64px;
          left:0px;
          opacity: 1;
          background: #282828;
          transition: all ease .5s;
          @media screen and (min-width: 1024px) {
            top:8px;
            left:-96px;
            width: 48px;
            height: 48px;
          }
        }
        &:before {
          position: absolute;
          content:"";
          width: 16px;
          height: 16px;
          top:-40px;
          left:0px;
          opacity: 1;
          background: #fff;
          transition: all ease .5s;
          z-index: 1;
          @media screen and (min-width: 1024px) {
            width: 24px;
            height: 24px;
            top:0;
            left:-72px;
          }
        }
    
        
        @media screen and (min-width: 1024px) {
          font-size:32px;
        }
  
        @media screen and (min-width: 1280px) {
          font-size:48px;
        }
    }

    &__desc{
      font-size:20px;
      letter-spacing:-0.025em;
      font-family: Eina01-Bold;
      line-height: 1.3em;
      opacity:0.5;
      color: #282828;
      //max-width: 500px;
      margin-top:24px;
      @media screen and (min-width: 1024px) {
        max-width: 768px;
      }
      &--smaller{
        font-size:18px;
      }
    }

    }
    &-content{
      color: #282828;
      .parent {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(3, auto);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        }
        
        .div1 { grid-area: 2 / 2 / 3 / 8; }
        &__item{
          background-color: #fff;
          &:nth-child(2n) {
            background: #f7f7f7;
          }
        }
        &__card{
          display: flex;
          flex-direction: column;
          gap: 32px;
          position: relative;
          &-wrapper{
            padding:150px 0px;
          }
          &-actions{
            position: relative;
            left: 0;
            display: flex;
            justify-content: left;
            top:0;
            display: flex;
            align-items: center;
            
            flex-direction: row;
            gap: 8px;
            


            input[type=checkbox]{
              height: 0;
              width: 0;
              visibility: hidden;
            }
            .input {
              display: flex;
            }
            .state {
              color:rgb(135, 146, 162);
              font-family: Eina01-Bold;
              font-size:14px;
              display: flex;
              position: relative;
              top:1px;
              letter-spacing: -0.025em;
              &.checked {
                color:rgb(21, 190, 83);
              }
              &.required {
               color: rgb(66, 84, 102);
              }
            }
            .mandatory {
             svg {
              fill:rgba(66, 84, 102, 0.25);
             }
             .tooltip {
              min-width: 200px;
              background: #fff;
              color: rgba(66, 84, 102, 0.75);;
              box-shadow: 0px 0px 10px rgba(0,0,0,0.15);
              padding: 20px;
              border-radius: 0px;
              right: 0 !important;
              font-size:12px;
              font-weight: bold;
              left:auto;
              right:0;
              z-index: 111;
             }
            }
            label {
              cursor: pointer;
              text-indent: -9999px;
              width: 36px;
              height: 24px;
              background: rgb(66, 84, 102);
              display: block;
              border-radius: 24px;
              position: relative;
            }
            
            label:after {
              content: '';
              position: absolute;
              top: 3px;
              left: 3px;
              width: 18px;
              height: 18px;
              background: #fff;
              border-radius: 18px;
              transition: 0.3s;
              box-shadow: 0px 0px 10px rgba(0,0,0,0.25);
            }
            
            input:checked + label {
              background: rgb(21, 190, 83);
            }
            
            input:checked + label:after {
              left: calc(100% - 3px);
              transform: translateX(-100%);
            }
            
          }
          &-title{
            font-size:32px;
            line-height: 1em;
            letter-spacing:-0.05em;
            font-family: Eina01-Bold;
            color:#282828;
            position: relative;
          }
          &-desc{
            font-size:16px;
            line-height: 1.3em;
            letter-spacing:-0.025em;
            font-family: Eina01-Bold;
            color:rgba(#282828,0.5);
            position: relative;
            max-width: 768px;
          }
          &-link{
            font-size:16px;
            line-height: 1.3em;
            letter-spacing:-0.025em;
            font-family: Eina01-Bold;
            color:#47ccdf;
            position: relative;
            cursor:pointer;
            &:hover {
              color:#282828;
            }
          }
        }
    }
  }

}