
.hero-image{
    &-bg{
        background-color: #282828;
        border-bottom:1px solid rgba(255,255,255,0.1);
        //border-bottom:1px solid rgba(0,0,0,0.1);
        position: relative;
        color:#282828;
        padding:128px 0px;
        &__bottom{
          padding-bottom:0px;
          overflow: hidden;
        }
    
    }
    
    &__wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 32px;
    }
    
    &__title{
      font-size:32px;
      line-height: 1em;
      max-width: 380px;
      margin-bottom:48px;
      letter-spacing:-0.05em;
      font-family: Eina01-Bold;
      color:#282828;
  
      @media screen and (min-width: 768px) {
        font-size:48px;
        line-height: 1em;
        max-width: 640px;
        margin-bottom:48px;
        letter-spacing:-0.05em;
      }
  
      @media screen and (min-width: 1024px) {
        font-size:60px;
        line-height: 1em;
        max-width: 768px;
        margin-bottom:96px;
        letter-spacing:-0.05em;
      }
  
      @media screen and (min-width: 1280px) {
        font-size:72px;
        line-height: 1em;
        max-width: 840px;
        margin-bottom:96px;
        letter-spacing:-0.05em;
      }
      
      span.highlight-gray{
        color:rgba(40,40,40,0.5);
      }
      span.highlight-cyan{
        color:#47ccdf;
      }
      span.highlight-dropbox{
        color:#0061FE;
      }

      span.highlight{
        color:rgba(40,40,40,0.5);
      }

      &-wrapper{}
    }
    &__title{
      font-size:32px;
      line-height: 1em;
      max-width: 380px;
      margin-bottom:24px;
      letter-spacing:-0.05em;
      font-family: Eina01-Bold;
      color:#fff;
      text-align: center;

  
      @media screen and (min-width: 768px) {
        font-size:48px;
        line-height: 1em;
        max-width: 540px;
        margin-bottom:32px;
        letter-spacing:-0.05em;
      }
  
      @media screen and (min-width: 1024px) {
        font-size:60px;
        line-height: 1em;
        margin-bottom:48px;
        letter-spacing:-0.05em;
      }
  
      @media screen and (min-width: 1280px) {
        font-size:72px;
        line-height: 1em;
        max-width: 768px;
        margin-bottom:48px;
        letter-spacing:-0.05em;
      }
      
      span.highlight{
        color:#47ccdf;
    }
    span.highlight-gray{
      color:#aaa;
    }
      &-wrapper{}
    }
    
    &__frame{
      width: 100%;
      @media screen and (min-width: 1024px) {
        width: 75%;
      }
      &__bottom{
        position: relative;
        top:16px;
      }
    }
  
    &__desc {
      font-family: Eina01;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5em !important;
      font-weight: bold;
      color: rgba(#fff,0.5); 
      text-align: center;
      max-width: 100%;
      margin-bottom:0px;
  
  
      @media screen and (min-width: 540px) {
        font-size: 20px;
        max-width: 75%;
        margin-bottom:64px;
      }

      @media screen and (min-width: 1024px) {
        max-width: 768px;
      }
    }

    &__sub {
      font-family: Eina01;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5em !important;
      color: rgba(#fff,0.5); 
      text-align: center;
      letter-spacing: -0.025em;
      max-width: 100%;
      margin-top:32px;
      
  
      @media screen and (min-width: 540px) {
        margin-top:64px;
        font-size: 20px;
        max-width: 75%;
      }
      @media screen and (min-width: 1024px) {
        margin-top:64px;
        font-size: 20px;
        max-width: 768px;
      }
    }
}




.close-video-btn {
  position: absolute;
  right: 0;
  top: -48px;
  transform:scale(1);
  transition:all ease 200ms;
  display: flex;
  flex-direction: row;
  span.label{
    opacity: 0;
    font-size: 10px;
    font-weight: bold;
    padding-right: 8px;
    top: 6px;
    position: relative;
    color: #fff;
    font-family: 'Eina01-Bold';
    position: absolute;
    right:64px;
    transition:all ease .2s;
  }
  svg{
    fill:#fff;
  
  }
  &:hover {
    transform: scale(1.25);
    span.label{
      opacity: 1;
      right:21px;
      color:#fff;
    }
    svg{
      fill:#fff;
    
    }
  }
}
