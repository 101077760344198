.integrations-page {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &-hero {
    background-color: #47ccdf;
    padding: 150px 0 50px;
    position: relative;

    .container {
      position: relative;
      z-index: 1;
    }

    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      content: "";
      background-image: url(../../Assets/Images/integration-bg.svg);
      background-position: center center;
      background-size: 100% auto;
      background-repeat: no-repeat;
      filter: blur(20px);
      z-index: 0;
    }

    @media (min-width: 1024px) {
      padding: 200px 0 100px;

      &:before {
        background-image: url(../../Assets/Images/integration-bg.svg);
        background-position: center center;
        background-size: 100% auto;
        background-repeat: no-repeat;
      }
    }

    .parent {
      display: grid;
      grid-template-columns: repeat(4, 25%);
      grid-template-rows: repeat(4, auto);
      grid-column-gap: 0px;
      grid-row-gap: 20px;

      .div1 {
        grid-area: 2 / 1 / 3 / 5;
      }

      .div2 {
        grid-area: 3 / 1 / 4 / 5;
      }

      .div3 {
        grid-area: 4 / 1 / 4 / 5;
      }

      @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(3, auto);
        grid-column-gap: 20px;
        grid-row-gap: 20px;

        .div1 {
          grid-area: 2 / 2 / 3 / 5;
        }

        .div2 {
          grid-area: 2 / 6 / 3 / 8;
        }

        .div3 {
          grid-area: 3 / 2 / 4 / 8;
        }
      }

    }

    &__search {
      position: relative;

      &-input {
        position: relative;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.25);
        padding: 16px 48px;
        background-color: #47ccdf;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
        mix-blend-mode: darken;
        height: 64px;

        input {
          background-color: #47ccdf;
          font-size: 16px;
          font-family: Eina01-Bold;
          line-height: 100%;
          color: #282828;
          position: absolute;
          left: 0;
          width: 100%;
          top: 0;
          height: 100%;
          padding-left: 64px;
        }
      }

      &-icon {
        position: absolute;
        width: 32px;
        height: 32px;
        left: 16px;
        top: 16px;
        z-index: 111;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          fill: #282828;
        }
      }

      &-summary {
        margin: 12px 0px;
        font-size: 16px;
        font-family: Eina01-Bold;
        color: #aaa;

        span.highlight {
          color: #282828;
        }
      }

      &-wrapper {}
    }

    &-title {
      font-size: 32px;
      line-height: 1em;
      letter-spacing: -0.05em;
      font-family: Eina01-Bold;
      color: #282828;
      position: relative;

      &:after {
        position: absolute;
        content: "";
        width: 32px;
        height: 32px;
        top: -64px;
        left: 0px;
        opacity: 1;
        background: #282828;
        transition: all ease .5s;

        @media screen and (min-width: 1024px) {
          top: 8px;
          left: -96px;
          width: 48px;
          height: 48px;
        }
      }

      &:before {
        position: absolute;
        content: "";
        width: 16px;
        height: 16px;
        top: -40px;
        left: 0px;
        opacity: 1;
        background: #fff;
        transition: all ease .5s;
        z-index: 1;

        @media screen and (min-width: 1024px) {
          width: 24px;
          height: 24px;
          top: 0;
          left: -72px;
        }
      }


      @media screen and (min-width: 1024px) {
        font-size: 32px;
      }

      @media screen and (min-width: 1280px) {
        font-size: 48px;
      }


      span.highlight {
        color: rgba(40, 40, 40, 0.5);
      }
    }

    &-desc {
      font-size: 20px;
      letter-spacing: -0.025em;
      font-family: Eina01-Bold;
      line-height: 1.3em;
      opacity: 0.5;
      color: #282828;
      //max-width: 500px;
      margin-top: 8px;

      &--smaller {
        font-size: 18px;
      }
    }

    &-tips {
      font-size: 16px;
      letter-spacing: -0.025em;
      font-family: Eina01;
      font-weight: 600;
      line-height: 1.3em;
      color: rgba(#282828, 0.5);
      max-width: 90%;

      a.mailto {
        color: rgba(#282828, 0.5);
        text-decoration: underline;
        transition: all ease .2s;

        &:hover {
          color: #282828;
        }
      }

      &__wrapper {
        background-color: #47ccdf;
        padding: 40px;
        display: flex;
        align-items: center;
        justify-content: left;

      }
    }
  }

  &-heading {
    h1 {
      font-family: Eina03;
      font-size: 2.4rem;

      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: white;

      padding-top: 100px;
      padding-bottom: 80px;

      @media screen and (min-width: 768px) {
        font-size: 3rem;
      }
    }
  }

  &-list {
    padding-top: 64px;
    padding-bottom: 64px;


    &__wrapper {
      color: #282828;
      padding-top: 64px;
      padding-bottom: 64px;

      .parent {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, auto);
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        .div1 {
          grid-area: 1 / 1 / 2 / 5;
        }

        .div2 {
          grid-area: 2 / 1 / 3 / 5;
        }

        @media (min-width: 1024px) {
          grid-template-columns: repeat(8, 1fr);
          grid-template-rows: 1fr;

          .div1 {
            grid-area: 1 / 2 / 2 / 4;
          }

          .div2 {
            grid-area: 1 / 4 / 2 / 8;
          }
        }
      }
    }

    &__empty {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      &-title {
        font-size: 32px;
        letter-spacing: -0.05em;
        font-family: Eina01-Bold;
        color: rgba(#282828, 0.65);
        text-align: center;
      }

      &-tips {
        font-size: 16px;
        font-family: Eina01-Bold;
        color: rgba(#282828, 0.4);
        letter-spacing: -0.025em;
        text-align: center;

        a.mailto {
          color: #47ccdf;
          text-decoration: underline;
          transition: all ease .2s;
          padding: 4px 8px;

          &:hover {
            color: #282828;
            background-color: #47ccdf;
          }
        }
      }

      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 50vh;
        width: 100%;
        //background-color: #f7f7f7;

      }
    }

    &__menu {
      &-header {
        font-size: 32px;
        line-height: 1em;
        letter-spacing: -0.05em;
        font-family: Eina01-Bold;
        color: #47ccdf;
        position: relative;
        margin-bottom: 32px;
      }

      &-filters {
        padding: 64px 0px 0;
        display: flex;
        flex-direction: column;
        gap: 12px;

        &__item {
          display: flex;
          flex-direction: row;
          gap: 12px;
          cursor: pointer;

          &:hover {
            .integrations-page-list__menu-filters__item-label {
              color: #282828;
            }
          }

          &-icon {
            display: flex;
            width: 32px;
            height: 32px;
            align-items: center;
            justify-content: center;
            background-color: #f7f7f7;
            position: relative;

            input {
              position: absolute;
              width: 100%;
              height: 100%;
              visibility: hidden;
            }

            svg {
              width: 18px;
              height: 18px;
            }
          }

          &--checked {
            .integrations-page-list__menu-filters__item-icon {
              background-color: #47ccdf;

              svg {
                fill: #282828;
              }
            }

            .integrations-page-list__menu-filters__item-label {
              color: #282828;
            }
          }

          &-label {
            padding: 8px 0px;
            font-size: 16px;
            letter-spacing: -0.025em;
            line-height: 1.3em;
            font-family: Eina01-Bold;
            cursor: pointer;
            color: #aaa;
            position: relative;
          }
        }
      }

      &-wrapper {
        position: relative;

        @media (min-width: 1024px) {
          position: sticky;
          top: 0;
        }
      }

      &-inner {
        position: absolute;
        width: 100%;
        top: 48px;
        left: 32px;
        visibility: hidden;
        pointer-events: none;

        @media (min-width: 1024px) {
          visibility: visible;
          position: sticky;
          top: 0;
          pointer-events: all;
        }

        &.show {
          visibility: visible;
          pointer-events: all;
        }
      }

      &-toggle {
        display: flex;
        cursor: pointer;
        max-width: 240px;
        padding: 16px;
        background-color: rgba(40, 40, 40, 0.05);
        border: 1px solid rgba(0, 0, 0, 0.05);

        &__icon {
          position: absolute;
          width: 32px;
          height: 32px;
          cursor: pointer;
          left: 24px;
          top: 50%;
          transform: translateY(-50%);

          div {
            margin: auto;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: 22px;
            height: 12px;
          }

          span {
            position: absolute;
            display: block;
            width: 100%;
            height: 2px;
            background-color: #282828;
            border-radius: 1px;
            transition: all 0.2s ease;

            &:first-of-type {
              top: 0;
            }

            &:last-of-type {
              bottom: 0;
            }
          }

          &.active,
          .menu-icon>div {
            span {
              &:first-of-type {
                transform: rotate(45deg);
                top: 5px;
              }

              &:last-of-type {
                transform: rotate(-45deg);
                bottom: 5px;
              }
            }
          }

          &.active:hover span:first-of-type,
          &.active:hover span:last-of-type,
          &:hover .menu-icon__cheeckbox:checked+div span:first-of-type,
          &:hover .menu-icon__cheeckbox:checked+div span:last-of-type {
            width: 22px;
          }

          &:hover {

            // no need hover effect on mobile.
            @media (min-width: 1024px) {
              span:first-of-type {
                width: 26px;
              }

              span:last-of-type {
                width: 12px;
              }
            }
          }

        }

        &__label {
          font-size: 16px;
          letter-spacing: -0.025em;
          font-family: Eina01;
          font-weight: 600;
          line-height: 1.3em;
          color: #282828;
          padding-left: 56px;
          position: relative;
          top: 1px;
        }
      }

      width:100%;
      max-width: 360px;
      top: 0;
      border-top:1px solid rgba(40, 40, 40, 0.05);
      position: absolute;
      background-color: #fff;
      padding:40px;
      box-shadow:0px 0px 25px rgba(40, 40, 40, 0.05);
      z-index: 11;

      @media (min-width: 1024px) {
        box-shadow: none;
        width: 80%;
        height: auto;
        position: sticky;
        padding: 0;
        top: 0;
        margin-top: 32px;
        margin-bottom: 32px;
        border-top: 1px solid rgba(40, 40, 40, 0.05);
        padding-top: 32px;
      }

      li {
        display: flex;
        cursor: pointer;

        a {
          padding: 8px 0px;
          font-size: 16px;
          letter-spacing: -0.025em;
          line-height: 1.3em;
          font-family: Eina01-Bold;
          cursor: pointer;
          color: #aaa;
          position: relative;

          @media (min-width: 1280px) {
            padding: 16px 0px;
            font-size: 20px;
          }

          &.always-active {
            color: #47ccdf;
          }

          &.active,
          &:hover {
            color: #282828;

            &:after {
              position: absolute;
              width: 8px;
              height: 8px;
              top: -24px;
              left: -8px;
              opacity: 1;
              background: #282828;
              transition: all ease .5s;

              @media screen and (min-width: 1024px) {
                top: 8px;
                content: "";
                left: -16px;
                width: 8px;
                height: 8px;
              }
            }
          }
        }
      }
    }

    &__container {
      //padding:64px 0;

    }


    &__header {
      font-size: 32px;
      line-height: 1em;
      margin-bottom: 32px;
      letter-spacing: -0.05em;
      font-family: Eina01-Bold;
      color: #282828;
      position: relative;

      &:after {
        position: absolute;
        content: "";
        width: 32px;
        height: 32px;
        top: -48px;
        right: 0px;
        opacity: 1;
        background: #282828;
        transition: all ease .5s;

        @media screen and (min-width: 1024px) {
          top: 0px;
          right: 0px;
          width: 24px;
          height: 24px;
        }
      }

      &:before {
        position: absolute;
        content: "";
        width: 16px;
        height: 16px;
        top: -24px;
        right: 0px;
        opacity: 1;
        background: #47ccdf;
        transition: all ease .5s;
        z-index: 1;

        @media screen and (min-width: 1024px) {
          width: 12px;
          height: 12px;
          top: 0;
          right: 0px;
        }
      }

    }

    &__items {
      display: grid;
      grid-gap: 40px;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(1, auto);

      @media (min-width: 768px) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }
    }

    &__item {
      border: 1px solid rgba(0, 0, 0, 0.05);
      width: 100%;
      aspect-ratio: 2;
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
      background-color: #fff;
      padding: 40px;
      display: flex;
      flex-direction: column;
      transform: scale(1);
      transition: all ease .2s;
      cursor: pointer;
      gap: 20px;
      height: 100%;
      justify-content: space-between;
      position: relative;

      &:hover {
        transform: scale(1.05);
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
        border: 1px solid rgba(0, 0, 0, 0.15);
      }

      &-image {
        width: 64px;
        height: 64px;
        background-color: #fafafa;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 32px;
          max-height: 32px;
          left: 50% !important;
          top: 50% !important;
          transform: translate(-50%, -50%) !important;
        }

      }

      &-beta {
        position: absolute;
        font-size: 10px;
        background-color: #ED6238;
        padding: 8px 16px;
        color: #fff;
        font-weight: bold;
        letter-spacing: -0.05em;
        font-family: Eina01-Bold;
        line-height: 1;
        top: 4px;
        opacity: 1;
        right: 4px;

      }

      &-top {
        display: flex;
        gap: 24px;
        width: 100%;
      }

      &-bottom {
        display: flex;
        width: 100%;
      }

      &-content {
        display: flex;
        height: 100%;
        align-items: baseline;
        flex-direction: column;
        justify-content: space-evenly;

        &-name {
          font-size: 21px;
          line-height: 1em;
          letter-spacing: -0.05em;
          font-family: Eina01-Bold;
          color: #282828;
          position: relative;
        }

        &-author {
          font-size: 14px;
          letter-spacing: -0.05em;
          font-family: Eina01-Bold;
          color: rgba(#282828, 0.2);
          position: relative;
        }

        &-desc {
          font-size: 14px;
          letter-spacing: -0.025em;
          font-family: Eina01-Bold;
          line-height: 1.3em;
          opacity: 0.5;
          //max-width: 500px;
        }

        &-keywords {
          margin-top: 12px;
          display: inline-flex;
          gap: 8px;

          &__item {
            font-size: 10px;
            padding: 4px 8px;
            color: rgba(#282828, 0.7);
            background-color: #ebebeb;
            font-weight: bold;
            letter-spacing: -0.025em;
            font-family: Eina01-Bold;
            line-height: 1;
            display: flex;
            text-transform: uppercase;
            border: 1px solid rgba(0, 0, 0, 0.025);

            &--beta {
              background-color: #ED6238;
              color: rgba(255, 255, 255, 0.7);
            }

            &--development {
              background-color: #47ccdf;
            }

            &--live {
              background-color: rgb(0, 208, 133);
            }

            &--new {
              background-color: #47ccdf;
            }

            &--upcoming {
              background-color: rgba(244, 232, 47, 1);
            }
          }
        }
      }


    }





  }

  &-content {
    padding-top: 100px;
    padding-bottom: 80px;


    &--categories {
      width: 100%;
      height: auto;
      position: sticky;
      top: 0;

      ul {
        li {
          a {
            display: block;
            font-family: Eina03;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.67;
            letter-spacing: normal;
            color: #cccccc;
            cursor: pointer;
            width: 100%;

            &.active {
              position: relative;
              color: #282828;

              &::before {
                content: "";
                background-color: #47ccdf;
                width: 10px;
                height: 10px;
                position: absolute;
                top: 25px;
                right: 0px;
              }
            }
          }
        }
      }
    }

    h2 {
      font-family: Eina03;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
      color: #282828;
      position: relative;
      left: -5px;
      font-size: 3rem;

      @media screen and (min-width: 768px) {
        font-size: 6rem;
      }

      @media screen and (min-width: 1024px) {
        font-size: 5rem;
      }

      &::before {
        content: "";
        width: 32px;
        height: 1px;
        background-color: #282828;
        position: absolute;
        bottom: 0;
        left: 4px;
      }
    }

    .category-group:not(:first-child) {
      padding-top: 130px;
    }

    .category-group:last-child {
      padding-bottom: 130px;
    }

  }
}


.step-title-wrapper {
  display: inline-block;
  width: 100%;
  text-align: center;

  h3 {
    max-width: 80%;
    color: #AAA;
    font-weight: bold;
    font-size: 24px;

    @media screen and (min-width: 1024px) {
      font-size: 32px;
    }

    @media screen and (min-width: 1280px) {
      font-size: 32px;
    }
  }
}

