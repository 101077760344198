.generic-faqs {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  padding-top:128px;
    padding-bottom: 128px;
  &-heading {
    h1 {
      font-family: Eina03;
      font-size: 2.4rem;

      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #282828;

      @media screen and (min-width: 768px) {
        font-size: 3rem;
      }
    }
  }
  &-content {
    
    &--categories {
      width: 100%;
      height: auto;
      position: sticky;
      top: 0;

      ul {
        li {
          a {
            display: block;
            font-family: Eina03;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.67;
            letter-spacing: normal;
            color: rgba(40,40,40,0.5);
            cursor: pointer;
            width: 100%;
            &.active {
              position: relative;
              color: #282828;
              &::before {
                content: "";
                background-color: #47ccdf;
                width: 10px;
                height: 10px;
                position: absolute;
                top: 25px;
                right: 0px;
              }
            }
          }
        }
      }
    }

    h2 {
      font-family: Eina03;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: -0.05em;
      color: #282828;
      position: relative;
      left:-5px;
      font-size: 3rem;
      margin-bottom: 64px;

      @media screen and (min-width: 768px) {
        font-size: 6rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 5rem;
      }
      &::before {
        content: "";
        width: 32px;
        height: 1px;
        background-color: #282828;
        position: absolute;
        bottom: 0;
        left:4px;
      }
    }

    .category-group:not(:first-child) {
      padding-top: 130px;
    }

    .category-group:last-child{
      padding-bottom:0px;
    }

  }
  &-more{
    padding-top:64px;
    &__message {
      font-weight: bold;
      font-size: 18px;
      letter-spacing: -0.5px;
      display: flex;
      gap:24px;
      align-items: center;
      line-height: 1;
      flex-direction: column;
      @media screen and (min-width: 768px) {
       flex-direction: row;
       gap:8px;
      }
      &-icon {
        display: flex;
        align-items: center;
        svg {
          width: 48px;
        height: 48px;
        top: -1px;
        fill: #666;
        position: relative;
        @media screen and (min-width: 768px) {
          width: 18px;
          height: 18px;
        }
        }
      }
      &-text {
        color:#666;
        display: flex;
        align-items: center;
        
      }
      &-link {
        color:darken(#47ccdf, 10%);
        cursor: pointer;
        transition:color ease-in-out .3s;
        &:hover {
          color:darken(#47ccdf, 0%);
          text-decoration: underline;
        }
      }
    }
  }
}
