.categorical-faqs {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &-heading {
      h1 {
        font-family: Eina03;
        font-size: 2.4rem;
  
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: white;
  
        padding-top: 250px;
        padding-bottom: 80px;
  
        @media screen and (min-width: 768px) {
          font-size: 3rem;
        }
      }
    }
    &--categories {
      width: 100%;
      height: 300px;
      position: sticky;
      top: 96px;
  
      ul {
        li {
          a {
            display: block;
            font-family: Eina03;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.67;
            letter-spacing: normal;
            color: #cccccc;
            cursor: pointer;
            width: 100%;
            &.active {
              position: relative;
              color: #282828;
              &::before {
                content: "";
                background-color: #47ccdf;
                width: 10px;
                height: 10px;
                position: absolute;
                top: 25px;
                right: 0px;
              }
            }
          }
        }
      }
    }
    &-container {
      padding-top: 100px;
      padding-bottom: 80px;
      border-top:1px solid rgba(0,0,0,0.1);
  
      
  
      h2{
        font-family: Eina03;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: -0.05em;
        color: #282828;
        position: relative;
        left:-5px;
        font-size: 3rem;
  
        @media screen and (min-width: 768px) {
          font-size: 6rem;
        }
        @media screen and (min-width: 1024px) {
          font-size: 5rem;
        }
        &::before {
          content: "";
          width: 32px;
          height: 1px;
          background-color: #282828;
          position: absolute;
          bottom: 0;
          left:4px;
        }
      }
  
      .category-group:not(:first-child) {
        padding-top: 130px;
      }
  
      .category-group:last-child{
        padding-bottom:130px;
      }
  
    }
  }
  