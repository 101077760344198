.not-found-page {
    &-bg{
        background-color: #47ccdf;
    }
    .logo {
        padding-bottom: 5rem;
        width: 128px;
        height: 128px;
        svg {
            width: 100%;
            height: 100%;
            fill:#282828;
            path {
                fill:#282828;
            }
        }
    }
    h1 {
        font-size:10em;
        font-family: Eina01-Bold;
        line-height: 1em;
        letter-spacing: -0.05em;
        color:#282828;
    }
    h6 {
        font-size:32px;
        font-family: Eina01-Bold;
        line-height: 1em;
        letter-spacing: -0.05em;
        color:rgba(#282828, 1);
        margin-top: 0.5em;
        margin-bottom: 1em;
        text-align: center;

    }
    &__content{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 80%;
        min-height: 100vh;
    }
    
    button {
        margin-top: 2em;
        background-color: #47ccdf;
        border:2px solid #282828;
        font-family: Eina01-Bold;
        font-size: 16px;
        letter-spacing: -0.025em;
        line-height: 1;
        padding: 21px 36px;
        color:#282828;
        transition:all ease .2s;
        transform:scale(1);
        &:hover{
            color:#47ccdf;
            background-color: #282828;
            transform:scale(1.1);
        }
    }
}


 
