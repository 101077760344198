.pricing-page {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #282828;

  &-hero {
    min-height: 20vh;
    display: flex;
    align-items: center;
    padding-top: 180px;
    justify-content: center;
  }

  &-heading {
    h1 {
      font-family: Eina01;
      font-size: 24px;
      text-align: center;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -1px;
      color: #aaa;
      max-width: 60%;
      margin: 0 auto;

      @media screen and (min-width: 1024px) {
        font-size: 4rem;
        max-width: auto;
        letter-spacing: -3px;
      }
      span.highlight{
        color:#fff;
      }
      span.highlight-cyan{
        color:#47ccdf;
      }
    }

    h2 {
      font-family: Eina01;
      font-size: 16px;
      text-align: center;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.1;
      letter-spacing: -1px;
      color: white;
      padding-top:24px;
      padding-bottom: 0px;
      max-width: 60%;
      margin: 0 auto;
      margin-bottom: 64px;

      @media screen and (min-width: 768px) {
        font-size: 1.6rem;
        max-width: auto;
        letter-spacing: -1px;
      }
      span.highlight{
        color:#fff;
      }
      span.highlight-cyan{
        color:#47ccdf;
      }
    }

    /*button {
      font-family: Eina01;
      font-size: 14px;
      text-align: center;
      color: rgba(#282828, 1);
      text-decoration: underline;
      font-weight: bold;
      transform: scale(1);
      transition: all .2s ease-in-out;
      background-color: #47ccdf;
      width: max-content;
      padding: 24px 48px;
      position: relative;
      margin-bottom: 64px;

      &:hover {
        transform: scale(1.05);
        color: rgba(#fff, 1);
      }

      @media screen and (min-width: 768px) {
        font-size: 16px;
      }
    }

    span.credit-card-info {
      font-family: Eina01;
      font-size: 12px;
      text-align: center;
      position: absolute;
      left: 0;
      bottom: -24px;
      width: 100%;
      background-color: orange;
      padding: 8px;


    }*/

    .segment-container {
      display: inline-flex;
      width: 100%;
      padding-bottom: 32px;
      justify-content: center;

    }
  }

  &-content__wrapper {
    position: relative;
    padding-bottom: 128px;

    &:before {
      position: absolute;
      height: 256px;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      background-color: #282828;
    }
  }

  &-notes {
    color: #282828;
    //top:-256px;
    padding-top: 80px;
    padding-bottom: 0px;
    position: relative;
  }

  &-enterprise {
    color: #333;
    //top:-256px;
    position: relative;
    padding: 48px;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    transform: scale(1.05);
    border: 1px solid rgba(0, 0, 0, 0.075);
    max-width: 1280px;
    margin: 0 auto;

    @media screen and (min-width: 1280px) {
      display: flex;
      align-items: center;
      padding: 50px 60px;
    }

    &__title {
      width: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;

      @media screen and (min-width: 1280px) {
        width: 25%;
        justify-content: left;
        align-items: center;
        text-align: left;
      }

      &-first {
        color: #282828;
        font-weight: bold;
        font-size: 24px;
        letter-spacing: -1px;
        width: 100%;
      }

      &-second {
        color: #282828;
        font-weight: bold;
        font-size: 16px;
        letter-spacing: -0.25px;
        width: 100%;
        opacity: .5;
        margin-bottom: 24px;

        @media screen and (min-width: 1280px) {
          margin-bottom: 0px;
          justify-content: left;
          align-items: center;
          text-align: left;
        }
      }


    }

    &__desc {
      width: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      text-align: center;
      font-size: 16px;
      opacity: .5;
      margin-bottom: 48px;

      @media screen and (min-width: 1280px) {
        width: 50%;
        justify-content: left;
        align-items: center;
        text-align: left;
        margin-bottom: 0px;
      }

    }

    &__cta {
      width: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      button {
        font-family: Eina01;
        padding: 24px 64px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: #fff;
        background-color: #282828;
        cursor: pointer;
        transition: all ease .2s;
        letter-spacing: -0.1px;

        &:hover {
          color: #fff;
          transform: scale(1.05);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        }
      }

      @media screen and (min-width: 1280px) {
        width: 25%;
        justify-content: right;
        align-items: center;
      }
    }
  }

  &-list {
    padding-top: 80px;
    padding-bottom: 80px;
    color: #282828;
    position: relative;
    //top:-256px;
    margin-bottom: 0;

    &__pricing-box {
      position: relative;
      cursor: pointer;
      flex: 1;
      padding: 48px 48px 64px;
      background-color: #fff;
      border: 1px solid rgba(#282828, 0.05);
      //border-left:48px solid #fff;
      //border-bottom:48px solid #fff;
      transition: transform ease-in-out .2s;
      z-index: 0;

      &:hover {
        //border-color:#47ccdf;
        background-color: #f7f7f7;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
        transform: scale(1.05);
        border: 1px solid rgba(0, 0, 0, 0.075);
        z-index: 3;

        .currency-period {
          color: #282828;
        }

        .pricing-page-list__pricing-box__cta-btn {
          background-color: #282828;
          border: 2px solid #282828;
          color: #fff;
          font-weight: bold;
          font-size: 14px;
          padding: 16px 16px;
          letter-spacing: 0px;
          width: 100%;
        }
      }

      &--selected {
        //border-color:#47ccdf;
        background-color: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
        transform: scale(1.02);
        border: 1px solid #47ccdf;
        z-index: 2;
        .currency-period {
          color: #282828;
        }

        &:hover {
          background-color: #eee;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
          border: 1px solid rgba(#47ccdf, 0.75);
        }
      }

      &--disabled {
        &:before {
          background-color: #282828;
          opacity: 0.15;
          cursor: not-allowed !important;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          content: "";
          z-index: 1;
          pointer-events: none;
        }

        .currency-period {
          color: #282828 !important;
        }

        background-color: #ddd;

        &:hover {
          background-color: #ddd;
        }
      }

      &__wrapper {
        width: 100%;
        display: inline-flex;
        justify-content: center;
        //border:1px solid #ddd;
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        height: auto;

        @media screen and (min-width: 768px) {
          width:50%;
        }

        @media screen and (min-width: 1280px) {
          width: 25%;
        }

        @media screen and (min-width: 1440px) {
          width: 25%;
        }
      }

      &__title {
        font-size: 24px;
        font-weight: bold;
        color: #282828;
        letter-spacing: -1px;
        font-family: 'Eina01-Bold';
        text-align: left;
      }

      &__label {
        font-weight: bold;
        text-transform: uppercase;
        position: absolute;
        top:32px;
        right:32px;
        display: flex;
        span {
          font-weight: bold;
          padding:4px 8px 3px;
          letter-spacing: 0px;
          border-width: 1.5px;
          border-radius: 0.5px;
        }
      }

      &__price {
        font-size: 48px;
        color: #444;
        letter-spacing: -4px;
        text-align: left;
        position: relative;
        font-weight: bold;
        font-family: "JetBrains Mono";
        .currency-period {
          color: rgba(0, 0, 0, 0.25);
          font-size: 12px;
          padding-left: 2px;
          display: block;
          letter-spacing: -0.5px;
          font-weight: bold;
          position: relative;

        }

        .currency-symbol {
          font-family: "JetBrains Mono";
          opacity: 0.25;
          font-size: 32px;
          font-weight: 600;
          padding-right: 4px;
        }

        .currency-note {
          font-family: Eina01;
          padding-top:8px;
          color:#aaa;
          font-size: 14px;
          padding-left: 2px;
          display: block;
          letter-spacing: -0.5px;
          font-weight: bold;
          position: relative;
          top: -12px;
          .highlight {
            color:#666 !important;
          }
        }
        
      }

      &__cta-btn {
        background-color: #fff;
        border: 2px solid #282828;
        color: #282828;
        font-weight: bold;
        font-size: 14px;
        padding: 16px 16px;
        letter-spacing: 0px;
        width: 100%;
      }

      &__popular {
        background-color: #282828;
        position: absolute;
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        padding: 16px 48px;
        bottom: -1px;
        right: -1px;
        letter-spacing: 1px;

      }

      &__for-who {
        color: #282828;
        font-weight: bold;
        font-size: 16px;
        letter-spacing: -0.5px;
      }

      &__features {
        position: relative;
        padding: 8px 0 32px;

        &:before,
        &:after {
          position: absolute;
          content: "";
          width: 24px;
          height: 1px;
          background-color: rgba(0, 0, 0, 0.15);
        }

        ul {
          list-style-type: square;
          list-style-position: inside;
          margin: 24px 0 24px;

          li {
            padding: 4px 0px;
            justify-content: space-between;
            cursor: pointer;
            display: none;

            &:nth-child(-n+13) {
              display: flex;
            }

            span.label {
              color: rgba(0, 0, 0, 0.5);
              font-weight: bold;
              font-size: 14px;
              letter-spacing: -0.5px;
            }

            span.icon {
              svg {
                fill: rgba(0, 0, 0, 0.5);
                width: 16px;
              }
            }

            &.highlighted {
              span {
                color: #282828 !important;

                svg {
                  fill: #282828;
                }
              }

            }

            &:hover {
              span.label {
                color: #282828;
              }

              span.icon {
                svg {
                  fill: #282828;
                }

              }
            }

          }
        }
      }


    }
  }
}


.security-page {
  background-color: #fff;
}
