.product-beta-access {
  &-bg {
    background-color: #fff;
    position: relative;
    color: #282828;
    padding: 128px 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);

  }

  &__container {
    position: relative;
  }


  &__wrapper {}

  &__inner {
    .parent {
      display: grid;
      grid-template-columns: repeat(4, minmax(25%, 1fr));
      grid-template-rows: repeat(3, auto);
      grid-column-gap: 0px;
      grid-row-gap: 0px;

      .div1 {
        grid-area: 2 / 1 / 3 / 5;
      }

      .div2 {
        grid-area: 1 / 1 / 2 / 5;
      }

      .div3 {
        grid-area: 3 / 1 / 4 / 5;
      }

      @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(8, auto);
        grid-column-gap: 20px;
        grid-row-gap: 20px;

        .div1 {
          grid-area: 2 / 2 / 8 / 6;
        }

        .div2 {
          grid-area: 2 / 6 / 6 / 8;
        }

        .div3 {
          grid-area: 6 / 6 / 8 / 8;
        }


      }
    }




  }


  &__form {
    position: relative;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
    z-index: 1;
    padding: 32px;
    display: flex;
    background-color: #282828;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 1024px) {
      padding: 24px;
    }

    &:after {
      position: absolute;
      content: "";
      width: 32px;
      height: 32px;
      top: 0;
      right: 0;
      opacity: 1;
      background: #282828;
      transition: all ease .5s;
      transform: translateZ(0);

      @media screen and (min-width: 768px) {
        width: 48px;
        height: 48px;
      }
    }

    &:before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      opacity: 1;
      background: linear-gradient(0deg, rgba(#282828, 1) 10%, rgba(#282828, 0.25) 100%);
      transition: all ease .5s;
      transform: translateZ(0);
    }

    &:hover {
      &:before {
        opacity: 0.25;
      }
    }

    &-wrapper {
      display: flex;
      width: 100%;
      height: 100%;
      position: relative;

      @media screen and (min-width: 1024px) {
        //min-height: 400px;
      }

      &:before {
        position: absolute;
        //content:"";
        width: 100%;
        height: 100%;
        background-image: url(../../Assets/Images/form-bg.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        mix-blend-mode: darken;
        filter: blur(2px);
        transition: all ease .5s;
        transform: translateZ(0);
      }

      &:hover {
        &:before {
          filter: blur(0);
        }
      }
    }

    &-inner {
      display: flex;
      width: 100%;
      height: 100%;
    }

    &-btn {
      transform: scale(1);
      position: relative;
      padding: 22px;
      background-color: #282828;
      font-family: Eina04;
      font-size: 16px;
      font-weight: bold;
      color: white;
      margin-top: 0.5em;
      width: 100%;
      max-width: 320px;
      outline: none;
      transition: all ease .25s;
      transform: translateZ(0);
      text-decoration: underline;

      &:hover {
        transform: scale(1.1);
      }

      &:focus,
      &:hover,
      &:active {
        outline: 0;

      }

      @media screen and (min-width: 768px) {
        padding: 24px 64px;
        width: auto;

      }

      @media screen and (min-width: 1280px) {
        width: auto;

      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 32px;
    justify-content: center;

    @media screen and (min-width: 1024px) {
      padding: 64px;
    }

    &-title {
      font-size: 32px;
      line-height: 1em;
      margin-bottom: 32px;
      letter-spacing: -0.05em;
      font-family: Eina01-Bold;
      color: #fff;
      position: relative;
      text-align: center;

      @media screen and (min-width: 1024px) {
        text-align: left;
      }



      @media screen and (min-width: 1024px) {
        font-size: 48px;
        margin-bottom: 32px;
      }

      @media screen and (min-width: 1280px) {
        font-size: 72px;
        margin-bottom: 48px;
      }


      span.highlight {
        color: rgba(40, 40, 40, 0.5);
      }

    }

    &-desc {
      font-size: 18px;
      line-height: 1em;
      max-width: 480px;
      letter-spacing: -0.05em;
      font-family: Eina01-Bold;
      color: #fff;
      position: relative;
      text-align: center;

      @media screen and (min-width: 1024px) {
        text-align: left;
      }

      span.highlight {
        color: rgba(255, 255, 255, 0.5);
      }

      span.highlight-cyan {
        color: #47ccdf;
      }

      @media screen and (min-width: 1024px) {
        font-size: 36px;
        line-height: 1em;
        max-width: 640px;
        letter-spacing: -0.05em;
      }

      @media screen and (min-width: 1280px) {
        font-size: 48px;
        line-height: 1em;
        max-width: 640px;
        letter-spacing: -0.05em;
      }

    }

    &-wrapper {
      display: flex;
      height: 100%;

    }

    &-inner {
      display: flex;
      height: 100%;
      width: 100%;
      background-color: #282828;
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
    }
  }

  &__subscriber {
    padding: 32px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 1024px) {
      padding: 32px;
    }

    @media screen and (min-width: 1280px) {
      padding: 48px;
    }

    &-wrapper {
      background-color: #47ccdf;
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-inner {
      display: flex;
      width: 100%;
    }

    &-count {
      font-size: 32px;
      line-height: 1em;
      letter-spacing: -0.05em;
      font-family: Eina02-Bold;
      color: rgba(#282828, 1);
      position: relative;
      display: block;


      @media screen and (min-width: 768px) {
        line-height: 1em;
        letter-spacing: -0.05em;
      }

      @media screen and (min-width: 1024px) {
        font-size: 32px;
      }

      @media screen and (min-width: 1280px) {
        font-size: 60px;

      }

      span.highlight {
        color: rgba(40, 40, 40, 0.5);
      }

    }

    &-label {
      font-size: 16px;
      line-height: 1em;
      max-width: 380px;
      letter-spacing: -0.05em;
      font-family: Eina01-Bold;
      color: rgba(40, 40, 40, 0.5);
      position: relative;
      display: block;

      @media screen and (min-width: 768px) {
        font-size: 16px;
        line-height: 1em;
        max-width: 640px;
        letter-spacing: -0.05em;
      }


    }

  }



}