.image {
    &-skeleton {
        &__placeholder {
          width:100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: calc(var(--height) / var(--width) * 100%);
          position: relative;
          .image{
            position: absolute;
            top: 50%;
            //left: 50%;
            left: 0%;
            max-width: 100%;
            height: auto;
            vertical-align: middle;
            //transform: translate(-50%, -50%);
            transform: translateY(-50%);
          }
          
          &.loading {
            padding-bottom: calc(var(--height) / var(--width) * 100%);
          }
        }
      
        &__loading{
          //background-color: rgba(0,0,0,0.05);
          width: 100%;
          overflow: hidden;
          position: relative;
          z-index: 10;
          height: 100%;
          display: flex;
          position: absolute;
          top:0;
          lef:0;
          &:after {
            position: absolute;
            content:"";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
            background: linear-gradient(
                100deg,
                rgba(40, 40, 40, 0) 20%,
                rgba(71, 204, 223, 0.25) 50%,
                rgba(40, 40, 40, 0) 80%
              )
              transparent;
            background-size: 200% 100%;
            background-position-x: 180%;
            animation: 2s loading ease-in-out infinite;
          }
        }
      }
      
      @keyframes loading {
        to {
          background-position-x: -20%;
        }
      }
      
    
}