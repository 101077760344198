.menu-sm {
  &-wrapper {
    opacity: 0;
    visibility: hidden;
    transition: opacity .1s linear;
    position: relative;
    z-index: 44444 !important;

    &.show {
      opacity: 1;
      visibility: visible;

    }
  }

  width: 100%;

  @media screen and (min-width: 360px) {
    width: 85%;
  }

  height:100vh;
  top: 0;
  right:-100%;
  background-color: white;
  color: black;
  position: fixed;
  opacity:0;
  transition:all ease .3s;
  transition-delay: .5s;

  .show & {
    opacity: 1;
    right: 0;
  }


  &-content {
    -webkit-overflow-scrolling: touch;

    &--top {
      margin: 13% 13% 5%;

      .navbar-lang {
        width: auto !important;
        display: flex !important;
        background-color: #282828 !important;
        position: relative;
        right: auto;
        top:-8px;

        .options {
          background-color: #333;
        }
      }

      .language {
        font-family: Eina01;
        font-size: 12px;
        font-weight: bold;
        color: white;
        letter-spacing: 0.73px;
        background-color: #282828;
        text-transform: uppercase;
        padding: 0.5rem 1rem;
        position: relative;
        top: 0px;
        &:hover {}
      }

      .close-icon {
        cursor: pointer;
        position: relative;
        top: -1px;
        right: 0px;
      }

      .back {
        font-family: Eina01;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1.28px;
        color: #282828;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          margin-right: 6px;
        }
      }
    }

    &--body {
      margin-left: 13%;
      margin-right: 13%;

      &_sublinks {
        h6 {
          /*
          font-family: Eina01-Bold;
              font-size: 24px;
              font-weight: bold;
              letter-spacing: -0.05em;
              color: #282828;*/

          padding-top: 0rem;
          font-family: Eina01-Bold;
          font-size: 24px;
          color: #282828;
          padding-bottom: 1rem;
          letter-spacing: -0.05em;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          margin-bottom: 1rem;

          @media screen and (min-width: 580px) {
            font-size: 24px;
          }
        }

        ul {
          li {
            a {
              font-family: Eina01;
              font-size: 16px;
              color: #999999;
              font-weight: bold;
              display: flex;
              padding: 0.75em 0;
              letter-spacing: -0.05em;
              gap: 12px;
              align-items: center;

              @media screen and (min-width: 580px) {
                font-size: 1.2rem;

              }

              &:hover {
                color: #282828;
              }

              span.icon {
                //background-color: #47ccdf;
                width: 24px;
                height: 24px;
                align-items: center;
                justify-content: center;
                display: flex;

                //border-radius: 50%;
                img {
                  max-width: 80%;
                  //filter:invert(1);
                }
              }

              span.label {}
            }
          }

          margin-bottom:2rem;
        }
      }

      &_links {
        ul {
          padding-top: 1rem;

          li {
            display: flex;
            align-items: center;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            width: max-content;

            span {
              display: none;
              margin-left: 0.6rem;
            }

            &:hover {
              span {
                display: block;

                svg {
                  path {
                    stroke: #47ccdf;
                  }
                }
              }
            }

            a {
              font-family: Eina01-Bold;
              font-size: 24px;
              font-weight: bold;
              letter-spacing: -0.05em;
              color: #282828;

              &:hover {
                color: #47ccdf;
              }
            }
          }
        }

        &__login {
          background-color: #181818;
          height: 48px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 0;
          font-family: Eina01;
          color: white;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 1.28px;
          text-transform: uppercase;

          span {
            margin-right: 1rem;
          }

        }
      }

      &__video {
        position: relative;
        display: flex;
        position: absolute;
        bottom: 64px;
        height: 150px;
        width: 100%;
        display: none;

        @media screen and (min-height:640px) {
          display: flex;
        }

        &-promo {

          width: 100%;

          &__box {
            display: flex;
            width: 100%;
            flex-direction: column;
            position: relative;
            justify-content: space-evenly;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);


            &-video {
              display: flex;
              width: 100%;
              height: 75px;

              @media screen and (min-height:760px) {
                height: 100px;
              }

              align-items: center;
              justify-content: center;

              svg {
                max-width: 48px;
                max-height: 48px;
              }

              a {
                width: 100%;
                height: 100%;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                transform: scale(1);
                transition: all ease .2s;

                &:hover {
                  transform: scale(1.1);
                }
              }
            }

            &-content {
              background-color: #fff;
              color: #282828;
              display: flex;
              height: auto;
              width: 100%;
              position: relative;
              flex-direction: column;
              padding: 24px;
              align-items: center;

              &__title {
                font-family: Eina01-bold;
                letter-spacing: -0.05em;
                color: #282828;
                line-height: 1.1em;
                font-size: 24px;
                z-index: 1;
                position: relative;
              }

              &__desc {
                position: relative;
                font-size: 16px;
                font-family: Eina01;
                letter-spacing: -0.05em;
                font-weight: bold;
                color: rgba(#282828, 0.5);
                z-index: 1;

              }

              &:before {
                position: absolute;
                background-color: #fff;
                //content:"";
                width: 263px;
                height: 184px;
                bottom: -64px;
                right: 0;
                background-position: bottom right;
                background-repeat: no-repeat;
                mix-blend-mode: multiply;
              }

            }
          }
        }
      }
    }
  }
}