.translation-defined {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, minmax(60px, 1fr));
    grid-auto-rows: 1fr;
    &::before {
      content: "";
      width: 0;
      padding-bottom: 100%;
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }
    @media screen and (min-width: 640px) {
      grid-template-columns: repeat(4, minmax(60px, 1fr));
    }
    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(4, minmax(96px, 1fr));
    }
    @media screen and (min-width: 1024px) {
      grid-template-columns: repeat(8, minmax(128px, 1fr));
    }
    @media screen and (min-width: 1280px) {
      grid-template-columns: repeat(8, minmax(160px, 1fr));
    }
    @media screen and (min-width: 1600px) {
      grid-template-columns: repeat(8, minmax(200px, 1fr));
    }
  }

  h1 {
    font-family: Eina03;
    font-size: 48px;
    font-weight: bold;
    line-height: 1;
    color: #ffffff;
    padding-top: 16.5rem;
    padding-bottom:5rem;
    position: relative;
  }
  h1::before {
    content: url('../../Assets/Icons/tm-dots.svg');
    position: absolute;
    left: -55px;
    bottom:35px;
  }
  .char-wrapper{
    position: relative;
  }
  .char-inner{
    position: sticky;
    top:100px;
    @media screen and (min-width: 640px) {
      //top:100px;
    }
  }
  .character-wrapper{
    p {
      font-family: Eina02;
      font-size: 10px;
      text-align: center;
      color: black;
      margin-bottom: 0.25rem;
      transition:color linear .2s;
      @media screen and (min-width: 640px) {
        font-size: 14px;
        margin-bottom: 0.5rem;
      }
    }
    .active {
      p {
        color: #47ccdf;
        font-weight: bold;
      }
    }
    .empty {
      p {
        color: #ddd;
      }
    }
    .active::after {
      content:"";
      width: 5px;
      height: 5px;
      background-color: #47ccdf;
      margin-top: 7px;
    }
  }
  h3 {
    opacity: 0.1;
    font-family: Eina02;
    font-size:48px;
    text-align: center;
    color: #282828;
    margin-top: -2.5rem;
    @media screen and (min-width: 640px) {
      font-size: 140px;
    }
    @media screen and (min-width: 768px) {
    }
    @media screen and (min-width: 1024px) {
    }
    @media screen and (min-width: 1280px) {
    }
    @media screen and (min-width: 1600px) {
      font-size: 140px;
    }
  }
  h2 {
    font-family: Eina03;
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
    color: #282828;
    padding-bottom: 6.25rem;
    @media screen and (min-width: 640px) {
      font-size: 48px;
    }
    @media screen and (min-width: 768px) {
    }
    @media screen and (min-width: 1024px) {
    }
    @media screen and (min-width: 1280px) {
    }
    @media screen and (min-width: 1600px) {
      font-size: 48px;
    }
    
  }
  h2+p {
    max-width: 100%;
    font-family: Eina03;
    font-size: 14px;
    color: #000000;
    padding-bottom: 9rem;
    @media screen and (min-width: 640px) {
      font-size: 24px;
    }
    @media screen and (min-width: 768px) {
    }
    @media screen and (min-width: 1024px) {
    }
    @media screen and (min-width: 1280px) {
    }
    @media screen and (min-width: 1600px) {
      line-height: 1.5;
      font-size: 24px;
      max-width: 825px;
    }
  }
}


