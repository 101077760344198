
.text-tout{
  background-color: #282828;
  border-top:1px solid rgba(255,255,255,0.1);
  position: relative;
  padding:100px 0px 100px;
  @media screen and (min-width: 1024px) {
    padding:200px 0px 200px;
  }
    &--light{
      background-color: #fff;
      border-top:1px solid rgba(40,40,40,0.1);
      .text-tout__content-title{
        color:#282828;
      }
      .text-tout__desc{
        color:rgba(#282828, 0.65);
      }
    }
    
    &__wrapper{
      
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, auto);
      grid-column-gap: 0px;
      grid-row-gap: 32px;
      @media screen and (min-width: 1024px) {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
      }
      .div1 {
        grid-area: 1 / 1 / 2 / 5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media screen and (min-width: 1024px) {
          grid-area: 1 / 2 / 2 / 5; 
        }
      }
      .div2 {
        grid-area: 2 / 1 / 3 / 5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media screen and (min-width: 1024px) {
          grid-area: 1 / 5 / 2 / 8;
        }
        .text-tout__content-title{
          @media screen and (min-width: 1024px) {
            padding-left:20%;
          }
          max-width: 100%;
        }
      }
    }
    
    &__content{
      
      &-title{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size:72px;
      color:#fff;
      font-family: Eina01-Bold;
      letter-spacing: -0.05em;
      line-height: 1.1em;
      text-align: center;
      @media screen and (min-width: 1024px) {
        max-width: 80%;
      }
        &--right {
          @media screen and (min-width: 1024px) {
            text-align: left;
            align-items: start;
          }
        }
        &--left {
          @media screen and (min-width: 1024px) {
            text-align: right;
            align-items: end;
          }
        }
        
      }
    }
    
    &__desc{
        position: relative;
        font-size:32px;
        color:rgba(#fff, 0.65);
        font-weight: bold;
        letter-spacing: -0.025em;
        line-height: 1.3em;
        text-align: center;
        
        &--right{
          @media screen and (min-width: 1024px) {
            text-align: left;
          }
        }

        &--left{
          @media screen and (min-width: 1024px) {
            text-align: right;
          }
        }
    }
}


