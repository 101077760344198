.customer-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 32px;
  position: relative;
  max-width: 100%;
  &-bg{

    max-width: 100%;
    //default, large-both, small-both, large-top, large-bottom, small-both, small-top, small-bottom
    &--default{
      padding:64px 0px 128px;
    }
    &--large-both{
      padding:200px 0px 200px;
    }
    &--large-top{
      padding-top:200px;
    }
    &--large-bottom{
      padding-bottom:200px;
    }
    &--small-both{
      padding:64px 0px 64px;
    }
    &--small-top{
      padding-top:64px;
    }
    &--small-bottom{
      padding-bottom:64px;
    }

    &--light{
      background-color: #fff;
      border-top:1px solid rgba(0,0,0,0.1);
      border-bottom:1px solid rgba(0,0,0,0.1);
      color:#282828;
      .hero-text-tout-with-list__title-primary{
        color:#282828;
        
      }
      .hero-text-tout-with-list__title-secondary{
        color:rgba(#282828, 0.5);
      }
    }
    &--cyan{
      background-color: #47ccdf;
      border:0px;
      color:#282828;
      padding-bottom:200px;
      border-top:1px solid rgba(0,0,0,0.1);
      border-bottom:1px solid rgba(0,0,0,0.1);
      .hero-text-tout-with-list__title-primary{
        color:#282828;
        
      }
      .hero-text-tout-with-list__title-secondary{
        color:rgba(#282828, 0.5);
      }
    }
}
  &-align {
    &--center {
      align-items: center;
      justify-content: center;
    }

    &--left {
      align-items: start;
      justify-content: start;

    }
  }

  &__title {
    font-family: Eina01;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5 !important;
    letter-spacing: -0.5px;
    color: #fff;
    margin-bottom: 16px;

    @media screen and (min-width: 1024px) {
      margin-bottom: 32px;
      font-size: 24px;
      line-height: 1.1em;
      letter-spacing: -0.025em;
    }


    &--large {
      margin-bottom: 32px;
        font-size: 24px;
        line-height: 1.1em;
        letter-spacing: -0.025em;
      @media screen and (min-width: 1024px) {
      font-family: Eina01-Bold;
      font-size: 48px;
      font-weight: bold;
      line-height: 1.5 !important;
      letter-spacing: -2.5px;
    }
    }



    span {
      color: #aaa;
    }
  }

  &__content {
    max-width: 100%;
    display: flex;

    .marquee {
      justify-content: space-around !important;
    }

    &-item {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: space-around;
      opacity: 0.5;
    }
  }
}