p {
    color:#282828;
}
.shaper {
    &-wrapper{}
    &-inner{}
    &__list{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap:24px;
        &-item{
            color:#282828;
            max-height:64px;
            max-width:256px;
            align-items: center;
            justify-content: center;
            display: flex;
            font-family:Eina01-Bold;
            font-size:32px;
            letter-spacing: -1px;
            text-transform: capitalize;
            svg {
                max-width: 50%;
                max-height: 50%;
            }

            &.bg-teal{
                background-color: #47ccdf;
            }
            
            &.bg-orange{
                background-color: #ED6238;
            }
            &.bg-yellow{
                background-color: #FBFB78;
            }
            &.bg-black{
                background-color: #282828;
            }
            
            
            &.circle{
                width:64px;
                height:64px;
                border-radius: 100%;
            }

            &.wf{
                width:256px;
                height:64px;
            }
            
            

            &.text-white{
                color:#fff;
            }
        }
    }
}
