.accordion {
  cursor: pointer;
  margin-top:32px;
  @media screen and (min-width: 1024px) {
    margin-top: 70px;
  }

  &-question {
    display: flex;
    position: relative;
    align-items: center;

    &:hover {
      h3 {
        color: #282828;
      }
      button.button {
        &:before,
          &:after{
            background-color: #282828;
          }
      }
    }
    @media screen and (min-width: 1280px) {
      align-items: start;
    }
    h3 {
      font-family: Eina04;
      font-size: 1.6rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1em;
      letter-spacing: -0.05em;
      color: rgba(40,40,40,0.4);
      transition: color 0.2s;
      max-width: 90%;

      @media screen and (min-width: 768px) {
        font-size: 2rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 3rem;
      }
    }
    h3.active {
      color: rgba(40,40,40,1);
    }
    svg {
      position: absolute;
      right: -16px;
      transition: stroke 0.4s !important;
    }
  }

  &-icon {
    &__wrapper {
      position: absolute;
      right: -12px;
      //transition: stroke 0.4s !important;
      width:24px;
      top:0;
      height:24px;
    }
    &__inner {
    }
    .button {
          position: relative;
          width: 24px;
          height: 24px;

          &:before,
          &:after{
              content: "";
              position: absolute;
              background-color: rgba(40,40,40,0.4);
              transition: transform 0.25s ease-out;

              .active & {
                background-color: rgba(40,40,40,1);
              }


          }

          /* Vertical line */
          &:before{
              top: 0;
              left: 50%;
              width: 2px;
              height: 100%;
              margin-left: -1px;
          }

          /* horizontal line */
          &:after{
              top: 50%;
              left: 0;
              width: 100%;
              height: 2px;
              margin-top: -1px;
          }
          .active & {
            &:before{ transform: rotate(90deg); }
              &:after{ transform: rotate(180deg); }
          }
          &:hover {
              cursor: pointer;
              
              &:before{ transform: rotate(90deg); }
              &:after{ transform: rotate(180deg); }
          }
      }
  }

  &-answer {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.7s cubic-bezier(0, 1, 0, 1);
    padding-top: 32px;

    @media screen and (min-width: 1024px) {
    padding-top: 60px;
    }
    &.show {
      max-height: 1000px;
      transition: max-height 0.7s ease-in-out;
    }

    &.hide {
      max-height: 0;
      transition: max-height 0.7s cubic-bezier(0, 1, 0, 1);
      overflow: hidden;
    }
    
    .content {
      font-family: Eina03;
      font-size: 0.9rem;
      padding-left:32px;
      border-left:1px solid rgba(40,40,40,1);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      color:rgba(40,40,40,1);

      @media screen and (min-width: 768px) {
        font-size: 1.2rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 1.6rem;
      }

      a {
        font-family: Eina03;
        font-weight: bold;
        position: relative;
        transition:color ease .3s;
        color:#47ccdf;
        text-decoration: underline;
        &:hover{
          color:#282828;
        }
      }

    }
  }
}
