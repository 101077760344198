.policy-page {
  &-bg {
    background-color: #282828;
  }

  &-date {
    margin: 1rem 0 0;
    display: inline-block;
    font-size: 14px;
    font-family: Eina01-Bold;
    color: rgba(255, 255, 255, 0.5) !important;

    strong {
      color: #fff;
    }
  }
}

.padding-t-10 {
  padding-top: 1rem !important;
}

.contract-content {
  //max-width:1200px;
  display: grid;
  grid-template-columns: repeat(4, minmax(60px, 1fr));

  @media screen and (min-width: 640px) {
    grid-template-columns: repeat(4, minmax(60px, 1fr));
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(4, minmax(96px, 1fr));
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(8, minmax(128px, 1fr));
  }

  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(8, minmax(160px, 1fr));
  }

  @media screen and (min-width: 1600px) {
    grid-template-columns: repeat(8, minmax(200px, 1fr));
  }


  padding-bottom:10rem;

  .btn {
    padding: 10px;
  }

  h1 {
    font-family: Eina01-Bold;
    font-size: 32px;
    font-weight: bold;
    line-height: 1;
    color: #ffffff;
    padding-top: 16.5rem;
    letter-spacing: -0.025em;

    @media screen and (min-width: 640px) {
      letter-spacing: -0.05em;
    }

    @media screen and (min-width: 1024px) {
      font-size: 60px;
    }
  }

  h2 {
    font-family: Eina01-Bold;
    font-size: 16px;
    font-weight: bold;
    line-height: 2;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 2rem;
    letter-spacing: -0.05em;

    @media screen and (min-width: 640px) {
      font-size: 18px;
    }

    @media screen and (min-width: 1024px) {
      font-size: 24px;
    }
  }

  h3 {
    font-family: Eina01-Bold;
    font-size: 18px;
    font-weight: bold;
    line-height: 2;
    color: #ffffff;
    letter-spacing: -0.05em;
    margin: 3rem 0 1rem;

    @media screen and (min-width: 640px) {
      font-size: 24px;
    }

    @media screen and (min-width: 1024px) {
      font-size: 32px;

    }
  }

  h2#google {
    font-size: 18px !important;
  }

  h2#contact {
    color: #47ccdf;
  }

  p {
    opacity: 0.9;
    font-family: Eina01;
    font-size: 14px;
    line-height: 1.3em;
    color: rgba(255, 255, 255, 0.6);
    letter-spacing: -0.025em;

    @media screen and (min-width: 640px) {
      font-size: 16px;
    }

    @media screen and (min-width: 1024px) {
      font-size: 21px;
      line-height: 1.5em;
    }
  }

  a.external-link {
    position: relative;
    color: #fff;
    display: inline-block;
    font-weight: 600;
    font-family: Eina01;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 40%;
      background: #47ccdf;
      bottom: 10%;
      z-index: -1;
      transition: all .2s ease;
    }

    &:hover {
      &::before {
        height: 90%;
      }
    }
  }

  span {
    color: #47ccdf;
  }

  .paragraph-paddings {
    padding-top: 2rem;
    padding-bottom: 0.625rem;

    @media screen and (min-width: 640px) {
      padding-top: 4rem;
      padding-bottom: 1.25rem;
    }

    @media screen and (min-width: 1024px) {
      padding-top: 8rem;
      padding-bottom: 2.5rem;
    }
  }

  ul {
    list-style-type: square;
    padding: 1.2rem;

    li {
      p {
        margin-left: 0.75rem;
        margin-bottom: 0.5rem;

        @media screen and (min-width: 640px) {
          margin-left: 1.75rem;
          margin-bottom: 1rem;
        }

        @media screen and (min-width: 1024px) {
          margin-left: 3.5rem;
          margin-bottom: 2rem;
        }
      }
    }

    li::marker {
      color: #47ccdf;
      width: 10px;
      height: 10px;

    }
  }

  ol {
    list-style-type: square;
    padding: 1.2rem;

    li {
      font-size: 32px;
    }
  }
}