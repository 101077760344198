@font-face {
    font-family: 'JetBrains Mono';
    src: url('../../Fonts/subset-JetBrainsMono-Regular.eot');
    src: url('../../Fonts/subset-JetBrainsMono-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/subset-JetBrainsMono-Regular.woff2') format('woff2'),
        url('../../Fonts/subset-JetBrainsMono-Regular.woff') format('woff'),
        url('../../Fonts/subset-JetBrainsMono-Regular.ttf') format('truetype'),
        url('../../Fonts/subset-JetBrainsMono-Regular.svg#JetBrainsMono-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: url('../../Fonts/subset-JetBrainsMono-Medium.eot');
    src: url('../../Fonts/subset-JetBrainsMono-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/subset-JetBrainsMono-Medium.woff2') format('woff2'),
        url('../../Fonts/subset-JetBrainsMono-Medium.woff') format('woff'),
        url('../../Fonts/subset-JetBrainsMono-Medium.ttf') format('truetype'),
        url('../../Fonts/subset-JetBrainsMono-Medium.svg#JetBrainsMono-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: url('../../Fonts/subset-JetBrainsMono-Bold.eot');
    src: url('../../Fonts/subset-JetBrainsMono-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/subset-JetBrainsMono-Bold.woff2') format('woff2'),
        url('../../Fonts/subset-JetBrainsMono-Bold.woff') format('woff'),
        url('../../Fonts/subset-JetBrainsMono-Bold.ttf') format('truetype'),
        url('../../Fonts/subset-JetBrainsMono-Bold.svg#JetBrainsMono-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eina01-Bold';
    src: url('../../Fonts/subset-Eina01-Bold.eot');
    src: local('☞Eina01-Bold'), local('Eina01-Bold'),
        url('../../Fonts/subset-Eina01-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/subset-Eina01-Bold.woff2') format('woff2'),
        url('../../Fonts/subset-Eina01-Bold.woff') format('woff'),
        url('../../Fonts/subset-Eina01-Bold.ttf') format('truetype'),
        url('../../Fonts/subset-Eina01-Bold.svg#Eina01-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eina04-Light';
    src: url('../../Fonts/subset-Eina04-Light.eot');
    src: local('☞Eina04-Light'), local('Eina04-Light'),
        url('../../Fonts/subset-Eina04-Light.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/subset-Eina04-Light.woff2') format('woff2'),
        url('../../Fonts/subset-Eina04-Light.woff') format('woff'),
        url('../../Fonts/subset-Eina04-Light.ttf') format('truetype'),
        url('../../Fonts/subset-Eina04-Light.svg#Eina04-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eina04-Bold';
    src: url('../../Fonts/subset-Eina04-Bold.eot');
    src: local('☞Eina04-Bold'), local('Eina04-Bold'),
        url('../../Fonts/subset-Eina04-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/subset-Eina04-Bold.woff2') format('woff2'),
        url('../../Fonts/subset-Eina04-Bold.woff') format('woff'),
        url('../../Fonts/subset-Eina04-Bold.ttf') format('truetype'),
        url('../../Fonts/subset-Eina04-Bold.svg#Eina04-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eina04-Regular';
    src: url('../../Fonts/subset-Eina04-Regular.eot');
    src: local('☞Eina04-Regular'), local('Eina04-Regular'),
        url('../../Fonts/subset-Eina04-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/subset-Eina04-Regular.woff2') format('woff2'),
        url('../../Fonts/subset-Eina04-Regular.woff') format('woff'),
        url('../../Fonts/subset-Eina04-Regular.ttf') format('truetype'),
        url('../../Fonts/subset-Eina04-Regular.svg#Eina04-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eina02-Light';
    src: url('../../Fonts/subset-Eina02-Light.eot');
    src: local('☞Eina02-Light'), local('Eina02-Light'),
        url('../../Fonts/subset-Eina02-Light.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/subset-Eina02-Light.woff2') format('woff2'),
        url('../../Fonts/subset-Eina02-Light.woff') format('woff'),
        url('../../Fonts/subset-Eina02-Light.ttf') format('truetype'),
        url('../../Fonts/subset-Eina02-Light.svg#Eina02-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eina03-Bold';
    src: url('../../Fonts/subset-Eina03-Bold.eot');
    src: local('☞Eina03-Bold'), local('Eina03-Bold'),
        url('../../Fonts/subset-Eina03-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/subset-Eina03-Bold.woff2') format('woff2'),
        url('../../Fonts/subset-Eina03-Bold.woff') format('woff'),
        url('../../Fonts/subset-Eina03-Bold.ttf') format('truetype'),
        url('../../Fonts/subset-Eina03-Bold.svg#Eina03-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eina02-Bold';
    src: url('../../Fonts/subset-Eina02-Bold.eot');
    src: local('☞Eina02-Bold'), local('Eina02-Bold'),
        url('../../Fonts/subset-Eina02-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/subset-Eina02-Bold.woff2') format('woff2'),
        url('../../Fonts/subset-Eina02-Bold.woff') format('woff'),
        url('../../Fonts/subset-Eina02-Bold.ttf') format('truetype'),
        url('../../Fonts/subset-Eina02-Bold.svg#Eina02-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eina02-SemiBold';
    src: url('../../Fonts/subset-Eina02-SemiBold.eot');
    src: local('☞Eina02-SemiBold'), local('Eina02-SemiBold'),
        url('../../Fonts/subset-Eina02-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/subset-Eina02-SemiBold.woff2') format('woff2'),
        url('../../Fonts/subset-Eina02-SemiBold.woff') format('woff'),
        url('../../Fonts/subset-Eina02-SemiBold.ttf') format('truetype'),
        url('../../Fonts/subset-Eina02-SemiBold.svg#Eina02-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eina03-Light';
    src: url('../../Fonts/subset-Eina03-Light.eot');
    src: local('☞Eina03-Light'), local('Eina03-Light'),
        url('../../Fonts/subset-Eina03-Light.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/subset-Eina03-Light.woff2') format('woff2'),
        url('../../Fonts/subset-Eina03-Light.woff') format('woff'),
        url('../../Fonts/subset-Eina03-Light.ttf') format('truetype'),
        url('../../Fonts/subset-Eina03-Light.svg#Eina03-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eina03-Regular';
    src: url('../../Fonts/subset-Eina03-Regular.eot');
    src: local('☞Eina03-Regular'), local('Eina03-Regular'),
        url('../../Fonts/subset-Eina03-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/subset-Eina03-Regular.woff2') format('woff2'),
        url('../../Fonts/subset-Eina03-Regular.woff') format('woff'),
        url('../../Fonts/subset-Eina03-Regular.ttf') format('truetype'),
        url('../../Fonts/subset-Eina03-Regular.svg#Eina03-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eina01-SemiBold';
    src: url('../../Fonts/subset-Eina01-SemiBold.eot');
    src: local('☞Eina01-SemiBold'), local('Eina01-SemiBold'),
        url('../../Fonts/subset-Eina01-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/subset-Eina01-SemiBold.woff2') format('woff2'),
        url('../../Fonts/subset-Eina01-SemiBold.woff') format('woff'),
        url('../../Fonts/subset-Eina01-SemiBold.ttf') format('truetype'),
        url('../../Fonts/subset-Eina01-SemiBold.svg#Eina01-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eina03-SemiBold';
    src: url('../../Fonts/subset-Eina03-SemiBold.eot');
    src: local('☞Eina03-SemiBold'), local('Eina03-SemiBold'),
        url('../../Fonts/subset-Eina03-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/subset-Eina03-SemiBold.woff2') format('woff2'),
        url('../../Fonts/subset-Eina03-SemiBold.woff') format('woff'),
        url('../../Fonts/subset-Eina03-SemiBold.ttf') format('truetype'),
        url('../../Fonts/subset-Eina03-SemiBold.svg#Eina03-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eina02-Regular';
    src: url('../../Fonts/subset-Eina02-Regular.eot');
    src: local('☞Eina02-Regular'), local('Eina02-Regular'),
        url('../../Fonts/subset-Eina02-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/subset-Eina02-Regular.woff2') format('woff2'),
        url('../../Fonts/subset-Eina02-Regular.woff') format('woff'),
        url('../../Fonts/subset-Eina02-Regular.ttf') format('truetype'),
        url('../../Fonts/subset-Eina02-Regular.svg#Eina02-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eina01-Regular';
    src: url('../../Fonts/subset-Eina01-Regular.eot');
    src: local('☞Eina01-Regular'), local('Eina01-Regular'),
        url('../../Fonts/subset-Eina01-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/subset-Eina01-Regular.woff2') format('woff2'),
        url('../../Fonts/subset-Eina01-Regular.woff') format('woff'),
        url('../../Fonts/subset-Eina01-Regular.ttf') format('truetype'),
        url('../../Fonts/subset-Eina01-Regular.svg#Eina01-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eina01-Light';
    src: url('../../Fonts/subset-Eina01-Light.eot');
    src: local('☞Eina01-Light'), local('Eina01-Light'),
        url('../../Fonts/subset-Eina01-Light.eot?#iefix') format('embedded-opentype'),
        url('../../Fonts/subset-Eina01-Light.woff2') format('woff2'),
        url('../../Fonts/subset-Eina01-Light.woff') format('woff'),
        url('../../Fonts/subset-Eina01-Light.ttf') format('truetype'),
        url('../../Fonts/subset-Eina01-Light.svg#Eina01-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

