  .compact-header{
      background-color: #fff;
      min-height: 50vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #282828;
      .parent {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        }
        
        .div1 { grid-area: 2 / 2 / 3 / 8; }


    &__header{
      font-size:32px;
        line-height: 1em;
        letter-spacing:-0.05em;
        font-family: Eina01-Bold;
        color:#282828;
        position: relative;
        &:after{
          position: absolute;
          content:"";
          width: 32px;
          height: 32px;
          top:-64px;
          left:0px;
          opacity: 1;
          background: #282828;
          transition: all ease .5s;
          @media screen and (min-width: 1024px) {
            top:8px;
            left:-96px;
            width: 48px;
            height: 48px;
          }
        }
        &:before {
          position: absolute;
          content:"";
          width: 16px;
          height: 16px;
          top:-40px;
          left:0px;
          opacity: 1;
          background: #47ccdf;
          transition: all ease .5s;
          z-index: 1;
          @media screen and (min-width: 1024px) {
            width: 24px;
            height: 24px;
            top:0;
            left:-72px;
          }
        }
    
        
        @media screen and (min-width: 1024px) {
          font-size:32px;
        }
  
        @media screen and (min-width: 1280px) {
          font-size:80px;
        }
    }

    &__desc{
      font-size:32px;
      letter-spacing:-0.025em;
      font-family: Eina01-Bold;
      line-height: 1.3em;
      opacity:0.5;
      color: #282828;
      //max-width: 500px;
      margin-top:24px;
      
      @media screen and (min-width: 1024px) {
        max-width: 768px;
      }
      &--smaller{
        font-size:18px;
      }
    }

    }