


.bg-testimonials {
  //background-color: rgb(247,247,247);
  background-color: #fff;
  border-bottom:1px solid rgba(0,0,0,0.05);
  position: relative;
  &-icon {
    position: absolute;
    top:0;
    height: 100%;
    width:100%;
    opacity: 0.05;
    top: 0;
    left:0;
    svg {
      height:50%;
      width: 50%;
      left: -12px;
      position: relative;
    }
  }
}

.testimonial-section {
  width: 100%;
  padding-right: 10%;
  padding-left: 10%;
  padding-top:48px !important;
      padding-bottom:48px !important;

  @media (min-width: 640px) {
      width: 520px !important;
      padding-right: 0rem !important;
      padding-left: 0rem !important;
  }

  @media (min-width: 768px) {
      width: 614px !important;
      padding-right: 0rem !important;
      padding-left: 0rem !important;
      padding-top:64px !important;
      padding-bottom:64px !important;
  }

  @media (min-width: 1024px) {
      width: 768px !important;
      padding-right: 0rem;
      padding-left: 0rem;
      padding-top:96px !important;
      padding-bottom:96px !important;
  }

  @media (min-width: 1280px) {
      width: 960px !important;
      padding-right: 0rem;
      padding-left: 0rem;
      padding-top:128px !important;
      padding-bottom:128px !important;
  }

  @media (min-width: 1600px) {
      width: 1200px !important;
      padding-right: 0rem;
      padding-left: 0rem;
      padding-top:200px !important;
      padding-bottom:200px !important;
  }

  &__title {
      font-family: Eina01;
      font-size: 28px;
      font-weight: bold;
      line-height: 1.5 !important;
      letter-spacing: -1px;
      color: #282828; 
      text-align: center;

      @media screen and (min-width: 1024px) {
        font-size:32px;
        line-height: 1.14;
        letter-spacing: -2px;
        text-align: left;
      }

      @media screen and (min-width: 1280px) {
        font-size:36px;
        line-height: 1.14;
        letter-spacing: -2px;
      }
      @media screen and (min-width: 1600px) {
        font-size:42px;
        line-height: 1.14;
        letter-spacing: -2px;
      }
  }
  &__content {
    max-width: 100%;
    margin-top:48px;

    @media screen and (min-width: 1024px) { 
      margin-top:50px;
    }
  
    @media screen and (min-width: 1280px) { 
      margin-top:100px;
    }
    .testimonials {
      @media screen and (min-width: 768px) {
        max-width: 800px;
      }
      @media screen and (min-width: 1024px) { 
        width: 100%;
        max-width: 1054px;
        padding: 64px 64px 64px 0;
      }

      &-slider {
      position: relative;
      transition:all ease .3s;
      }

      &-active {
        opacity: 1;
        transform: translateX(0) scale(1);
        transition-delay: 0.4s;
      }

      &-buttons {
        z-index: 1;
        //top: 261px;
        width: 80px;
        height: 40px;
        position: relative;
        border-radius: 0px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        
        &-wrapper{
          display: flex;
          justify-content: center;
          margin:24px 0px;
          @media screen and (min-width: 1024px) {
            //bottom: -200px;
            justify-content: left;
          }
          @media screen and (min-width: 1600px) {
            //bottom: -200px;
            position: relative;
            //bottom:50%;
            //transform:translateY(-50%);
            margin-top:128px;
          }
        }

        svg {
          fill:#fff;
        }
        @media screen and (min-width: 768px) {
          //bottom: -200px;
        }
        @media screen and (min-width: 1024px) { 
          left: 0;
        }
        @media screen and (min-width: 1280px) { 
          left: 0;
        }
        
      }
      &-next, &-previous {
        width: 50%;
        height: 100%;
        background-color: #282828;
        transition: all ease .2s;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-previous {
        left: 0;
        &:hover {
          background-color: #47ccdf;
          transform: scale(1.25);
        }
      }

      &-next {
        right: 0;
        &:hover {
          transform: scale(1.25);
          background-color: #47ccdf;

        }
      }
      &-slide {
        display: flex;
        margin: 0 auto;
        text-align: center;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        
        @media (min-width: 640px) {
       
        }
    
        @media (min-width: 768px) {
          
        }
      
        @media (min-width: 1024px) {
        
        }
      
        @media (min-width: 1280px) {
        
        }
      
        @media (min-width: 1600px) {
          height:300px;
        }
        &--hide{
          display:none;
        }

        @media screen and (min-width: 1024px) { 
          position: relative;
          text-align: left;
          display: flex;
          flex-direction: row;
        }
      }
      &-rating {
        display: flex;
        flex-direction: row;
        margin:24px 0px;
        justify-content: center;
        @media screen and (min-width: 1024px) {
          justify-content: left;
        }
  
        &__icons{
          display: flex;
        }
        &__label{
          display: flex;
          color:#282828;
          padding-left: 16px;
          font-weight: bold;
          font-size: 18px;
          position: relative;
          top:1px;
        }
      }
      &-testimonial {
        blockquote {
                font-size: 24px;
                font-weight: bold;
                line-height: 1.3;
                color:rgba(#282828, 0.75);

                display: flex;
                align-items: center;

                @media screen and (min-width: 1024px) { 
                  font-size: 36px;
                  line-height: 1.3;
                  max-width: 90%;
                  height:600px;
                }
                
                @media screen and (min-width: 1280px) { 
                  font-size: 36px;
                  line-height: 1.3;
                  max-width: 90%;
                  height:360px;
                }
            }
      }
      &-author {
        margin:16px 0 8px;
        &-name {
          font-size: 24px;
        font-weight: bold;
        color: #282828;
        position: relative;
        letter-spacing: -0.5px;
        z-index: 1;
        }
        &-title {
          font-size:16px;
            display: block;
            color: #666;
            font-weight: 600;


        position: relative;
        z-index: 1;
            @media screen and (min-width: 1024px) { 
              display: block;
            }
        }
        &-brand {
          font-size:12px;
          display: block;
          color: #AAA;
          font-family: Eina03;
          font-weight: 900;
          letter-spacing: 0.5px;
        }

      }
      &-slider-img {
        width: 100%;
        display: flex;
        margin-bottom: 40px;
        align-items: center;
        justify-content: right;
        img {
            background-color: #282828;
            min-height:100%;
            max-width:100%;
            display: block;
            border-radius: 0px;
            box-shadow: 0px 16px 40px 0px rgba(135, 105, 210, 0.4);
            @media screen and (min-width: 1024px) { 
              //width: 400px;
            }
        }
      }
    }
    
  }
}