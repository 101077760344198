.solution-details-cover {
    display: flex;
    min-height: auto;
    align-items: center;
    padding:150px 0px 50px;
    position: relative;
    border-top:1px solid #ddd;
    

    @media screen and (min-width: 768px) { 
        padding:150px 0px 100px;
    }
    @media screen and (min-width: 1024px) { 
        padding:200px 0px;
        
        background-position: top -200px center;
    }

    @media screen and (min-width: 1280px) { 
        background-position: top center;
    }
    @media screen and (min-width: 1440px) { 
        background-size: 100% auto;
        background-position: center center;
    }
    &__wrapper{
      

    }

    &__title{
        grid-area: 1 / 1 / 2 / 5;
        @media screen and (min-width: 1024px) { 
            grid-area: 1 / 2 / 2 / 8;
        }
        @media screen and (min-width: 1280px) { 
            grid-area: 1 / 2 / 2 / 8; 
        }
        &-wrapper{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(2, auto);
            grid-column-gap: 0px;
            grid-row-gap: 16px;
            
            @media screen and (min-width: 768px) { 
            }
    
            @media screen and (min-width: 1024px) { 
                grid-template-columns: repeat(8, 1fr);
                grid-template-rows: repeat(2, auto);
            }
            
            @media screen and (min-width: 1280px) { 
                grid-template-columns: repeat(8, 1fr);
                grid-template-rows: repeat(2, auto);
            }
            
        }
        &-desc{
            grid-area: 2 / 1 / 2 / 5;
            @media screen and (min-width: 1024px) { 
                grid-area: 2 / 2 / 2 / 8;
            }
            @media screen and (min-width: 1280px) { 
                grid-area: 2 / 2 / 2 / 8; 
            }
            margin-top: 8px;
            color:rgba(#282828, 0.6);
            font-family: Eina01-Bold;
            letter-spacing: -0.07em;
            line-height: 1.1em;
            font-size:21px;
            text-align: center;   
            @media screen and (min-width: 768px) { 
                font-size:32px; 
            }
            b{
                color:rgba(#47ccdf,1);
            }
        }

        
        color:#282828;
        font-family: Eina01-Bold;
        letter-spacing: -0.07em;
        line-height: 1em;
        font-size:32px;
        text-align: center;
        @media screen and (min-width: 768px) { 
            font-size:56px;
        }
        @media screen and (min-width: 1024px) { 
            font-size:64px;
        }
        @media screen and (min-width: 1280px) { 
            font-size:48px;
        }
        @media screen and (min-width: 1440px) { 
            font-size:72px;
        }
        b{
            color:rgba(#282828,0.6);
        }


    }
    &__content{
        &-wrapper{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(6, auto);     
            grid-column-gap: 1px;
            grid-row-gap: 1px;
            margin-top:64px;
            .div1 { grid-area: 1 / 1 / 2 / 5; }
            .div2 { grid-area: 2 / 1 / 3 / 5; }
            .div3 { grid-area: 3 / 1 / 4 / 5; }
            .div4 { grid-area: 4 / 1 / 5 / 5; }
            .div5 { grid-area: 5 / 1 / 6 / 5; }
            .div6 { grid-area: 6 / 1 / 7 / 5; }

            @media screen and (min-width: 1024px) { 
                grid-template-columns: repeat(8, 1fr);
                grid-template-rows: repeat(3, auto);
                .div1 { grid-area: 1 / 2 / 3 / 4; }
                .div2 { grid-area: 1 / 4 / 2 / 8; }
                .div3 { grid-area: 2 / 4 / 3 / 8; }
                .div4 { grid-area: 3 / 2 / 4 / 4; }
                .div5 { grid-area: 3 / 4 / 4 / 6; }
                .div6 { grid-area: 3 / 6 / 4 / 8; }
            }


           
        }

       
    &-feature{
        color:#fff;
        background-color: #282828;
        display: flex;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
        transition:all ease .2s;
        transform:scale(1);
        border:1px solid transparent;
        position: relative;
        z-index: 0;
        &:hover {
            transform:scale(1.05);
            border:1px solid rgba(255,255,255,0.05);
            box-shadow: 0px 0px 10px rgba(0,0,0,0.25);
            z-index: 1;
            transform-origin: center center;
            @media screen and (min-width: 1024px) { 
                transform-origin: right bottom;
            }

        }
        &--vertical-image {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(2, 1fr);
            @media screen and (min-width: 1024px) { 
                //grid-template-columns:1fr;
                //grid-template-rows:1fr 1fr;
            }
        }
        &--horizontal-image {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(2, 1fr);
           
            @media screen and (min-width: 1024px) { 
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(1, 1fr);
            }
        }
        
        &__details{
        padding:48px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        //justify-content: center;
        @media screen and (min-width: 1024px) { 
            padding:32px;
        }
        @media screen and (min-width: 1440px) { 
            padding:64px;
        }
            &-title{
                font-family: Eina01-Bold;
                font-size: 20px;
                color:#fff;
                font-weight: bold;
                letter-spacing: -0.05em;
                line-height: 1.1em;
            }
            &-desc{
                font-size: 16px;
                color:rgba(255,255,255,0.5);
                font-weight: 600;
                letter-spacing: -0.025em;
                line-height: 1.3em;
            }
        }
        &__image{
            display: flex;
            align-items: center;
            justify-content: center;
            //background-color: #47ccdf;
            img {
                //box-shadow:0px 0px 50px rgba(0,0,0,0.15);
                //mix-blend-mode: lighten;
            }
            @media screen and (min-width: 1024px) { 
                border:0px;
            }
        }
    }
    }

}