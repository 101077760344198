.signature-page {
    h1 {
        line-height: 1.14px;
    }
    h5 {
        line-height: 36px;
        max-width: 740px;
        text-align: center;
    }

    h6 {
        line-height: 18px;
        max-width: 740px;
        text-align: center;
        margin:2rem 0;
    }
    .logo {
        padding-bottom: 4.875rem;
    }
    button {
        margin-top: 3.125rem;
        background-color: #181818;
        height: 50px;
        width: 200px;
        border-radius: 25px;
        span {
            margin-left: 10px;
        }

    }

    form{
        text-align: center;
        margin:1rem 0;
        input {
            color:#282828;
            padding:1rem;
            margin:1rem 0;
            width: 100%;
        }
    }
}