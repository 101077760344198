.browser-frame {
    background-color: #28292B;
    box-shadow: 0px 0px 25px rgba(0,0,0,0.5);
  &__top{
    padding:0 24px;
    border-bottom:1px solid rgba(255,255,255,0.1);
    &-content{
        background-color: #28292B;
        height: 36px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (min-width: 1024px) {
            height: 48px;
        }
    }
    
    &-btns{
        display: flex;
        gap:4px;
        span {
            width:8px;
            height:8px;
            background-color: #47ccdf;
            border-radius: 100%;
            @media screen and (min-width: 1024px) {
                width:16px;
                height:16px;
            }
        }

        @media screen and (min-width: 1024px) {
            gap:8px;
        }
    }
    &-bar{
        display: flex;
        color:#fff;
        font-size: 12px;
        font-weight: bold;
        color:#aaa;
        line-height: 1;
        span.highlight{
            color:#47ccdf;
        }
        @media screen and (min-width: 1024px) {
            font-size: 14px;
        }
    }
    &-close{
        display: flex;
        color:#fff;
        visibility: hidden;
        svg {
            fill:#fff;
            width:px;
        }
    }
  }
  &__content {
    position: relative;
    border:4px solid #28292B;
    border-top:0px;
    box-shadow: inset 0px 0px 25px #000;
    cursor: pointer;
    &-actions {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: all ease .5s;
        left:0;
        top:0;
        display: flex;
        align-items: center;
        justify-content: center;
        &__btn{
            transform:scale(1);
            color:#fff;
            font-weight: bold;
            font-family: Eina01;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-decoration: underline;
            transition: all ease .5s;
            gap:8px;
            svg {
                height:64px;
            }
        }
        &:before{
            position: absolute;
            content:"";
            width: 100%;
            height: 100%;
            opacity: 1;
            background: linear-gradient(0deg, rgba(#28292B,1) 10%, rgba(#28292B,0.5) 100%);
            z-index: -1;
            transition: all ease .5s;
        }
        
        &:hover{
            &:before{
               opacity: 0.25;
            }

            .browser-frame__content-actions__btn{
                transform:scale(1.25);
            }

        }
    }
    
   
    
    img{
        border-top: 0px;
        border:1px solid transparent;
        
    }
  }
  &__wrapper{}
}