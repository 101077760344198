
.workflow-scheme{
    &-bg{
        background-color: #fff;
        position: relative;
        color:#282828;
        padding:128px 0px 128px;
        border-top:1px solid rgba(0,0,0,0.05);
        color:#282828;
    }
    &__container{
      position: relative;
    }
  

    &__wrapper{
    }
    &__title{
      &-wrapper{
        width:100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      font-size:32px;
      line-height: 1em;
      max-width: 100%;
      letter-spacing:-0.05em;
      font-family: Eina01-Bold;
      color:#282828;
      text-align: center;
      position: relative;
   
  
      @media screen and (min-width: 768px) {
        font-size:48px;
        line-height: 1em;
        max-width: 640px;
        letter-spacing:-0.05em;
      }
  
      @media screen and (min-width: 1024px) {
        font-size:60px;
        line-height: 1em;
        max-width: 768px;
        letter-spacing:-0.05em;
      }
  
      @media screen and (min-width: 1280px) {
        font-size:72px;
        line-height: 1em;
        max-width: 840px;
        letter-spacing:-0.05em;
      }
      
      span.highlight{
        color:rgba(40,40,40,0.5);
      }
      &-wrapper{}
    }
    &__inner{
      display: flex;
      align-items: center;
      justify-content: center;
      //transform: rotate(-45deg);
      .parent {
        display: grid;
        grid-template-columns: repeat(7, 48px);
        grid-template-rows: repeat(7, 48px);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        position: relative;

          //grid-template-columns: repeat(4, minmax(25%, 1fr));
  
          @media screen and (min-width: 640px) {
            grid-template-columns: repeat(7, 64px);
            grid-template-rows: repeat(7, 64px);
          }
          
          @media screen and (min-width: 768px) {
            grid-template-columns: repeat(7, 96px);
            grid-template-rows: repeat(7, 96px);
          }
          
          @media screen and (min-width: 1024px) {
            grid-template-columns: repeat(7, 100px);
          }
          
          @media screen and (min-width: 1280px) {
            grid-template-columns: repeat(7, 100px);
          }
          
          @media screen and (min-width: 1600px) {
            grid-template-columns: repeat(7, 100px);
            grid-template-rows: repeat(7, 100px);
          }
      }
        
       
          
        .div1 { grid-area: 3 / 1 / 4 / 2; }
        .div2 { grid-area: 2 / 1 / 3 / 5; }
        .div3 { grid-area: 3 / 4 / 4 / 5; }
        .div4 { grid-area: 4 / 4 / 5 / 5; }
        .div5 { grid-area: 6 / 4 / 7 / 5; }
        .div6 { grid-area: 7 / 4 / 8 / 5; }
        .div7 { grid-area: 8 / 4 / 9 / 8; }
        .div8 { grid-area: 7 / 7 / 8 / 8; }
        .div9 { grid-area: 6 / 7 / 7 / 8; }
        .div10 { grid-area: 5 / 1 / 6 / 8; }
        .div11 { grid-area: 4 / 1 / 5 / 2; }
        .div12 { grid-area: 2 / 1 / 3 / 2; }
        .div13 { grid-area: 2 / 4 / 3 / 5; }
        .div14 { grid-area: 8 / 4 / 9 / 5; }
        .div15 { grid-area: 8 / 7 / 9 / 8; }
        .div16 { grid-area: 5 / 7 / 6 / 8; }
        .div17 { grid-area: 5 / 1 / 6 / 2; }
        .div18 { grid-area: 2 / 5 / 5 / 8; }
         
        
        .box{
          display: flex;
          align-items: center;
          justify-content: center;
          position:relative;
          transform: scale(1);
          transition: all ease .2s;
          cursor: pointer;
          border: 1px solid transparent;
          z-index: 0;
          span.num {
            position: absolute;
            left:4px; top:4px; font-size:10px;
            font-weight: bold;
            opacity: 0.5;
           display: none;
          }
          &:hover {
            border: 1px solid rgba(0,0,0,0.15);
            transform:scale(1.05);
            box-shadow: 0px 0px 25px rgba(0,0,0,0.15);
            z-index: 1;
          }
        }

        .info-box{
          display: flex;
          align-items: center;
          justify-content: center;
          //transform:scale(0) rotate(90deg) translate(-50%, -50%);
          transition: all ease .2s;
          transform:scale(0);
          transform-origin: center center;
          opacity: 0;
          max-width: 100%;
          
          &.show {
           //transform: scale(1) rotate(45deg) translate(-50%, -50%);
           //transform:scale(1) rotate(45deg) translate(-50%, -50%);
           transform: scale(1);
            opacity: 1;
            box-shadow: 0px 0px 10px rgba(0,0,0,0.05);
          }
          &__inner{
            padding:16px;
            @media screen and (min-width: 640px) {
              padding:32px;

            }
            
          }
          &__title{
            font-family: Eina01-Bold;
            letter-spacing: -0.05em;
            font-size:16px;
            line-height: 1em;
            margin-bottom:8px;
            @media screen and (min-width: 768px) {
              font-size:32px;
              margin-bottom:32px;
            }
            
          }
          &__description{
            font-family: Eina01;
            letter-spacing: -0.025em;
            font-size:12px;
            font-weight: bold;
            line-height: 1.3em;
            opacity: 0.5;
            @media screen and (min-width: 768px) {
              font-size:18px;
            }
          }
        }

        .no-action{
          cursor: default;
          pointer-events: none;
        }
        
        .content {
          font-size:16px;
          font-family: Eina01-Bold;
          letter-spacing: -0.025em;
          color:#282828;
          @media screen and (min-width: 768px) {
            font-size:24px;
          }
        }
        .image {
          display: flex;
          img {
            top:50% !important;
            left:50% !important;
            transform:translate(-50%, -50%) !important;
            width: 16px;
            max-width: 16px;
            @media screen and (min-width: 640px) {
              width: 24px;
              max-width: 24px;
            }

            @media screen and (min-width: 768px) {
              width: 32px;
              max-width: 32px;
            }
          }
        }

        .inverted {
          img {
            filter:invert(1);
          }
        }
       
        .bg-white{background-color: #f7f7f7; color:#282828;}
        .bg-cyan{background-color: #47ccdf; color:#282828;}
        .bg-black{background-color: #282828; color:#fff;}
        .bg-cyan-20{background-color: rgba(#47ccdf,0.2); color:#282828;}
        .bg-cyan-40{background-color: rgba(#47ccdf,0.4); color:#282828;}
        .bg-cyan-60{background-color: rgba(#47ccdf,0.6); color:#282828;}
        .bg-cyan-80{background-color: rgba(#47ccdf,0.8); color:#282828;}
        
        
    }
}




