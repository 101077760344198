.marquee{
    color:#fff;
    &-container{
        height:64px;
        background:rgba(40,40,40, 1);
        //border-bottom:1px solid rgba(255,255,255,0.05)
    }
    div {
        margin:0 25px;
        span {
            margin:0 10px;
            strong {
                margin: 0 3px;
            }
        }
         a {
             margin: 0 3px;
             text-decoration: underline;
             
         }
    }
    &-close-icon{
        background-color: transparent;
        position: absolute;
        top: 50%;
        right: 24px;
        z-index: 11;
        transform: translateY(-50%) scale(1);
        transition:all ease 200ms;
        width:24px;
        height:24px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            transform:translateY(-50%) scale(1.2);
            background-color:#47ccdf;
            svg {
                fill:#282828;
            }
        }
        svg {
            fill:#fff;
            width: 16px;
        }
    }
    &-general-wrapper{
        position: relative;
    }
}