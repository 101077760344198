.cookie {
    &-wrapper{
        transition: max-height 0.3s ease-out;
        max-height: 0;
        overflow:hidden;
        position: fixed;
        bottom:32px;
        right:32px;
        z-index: 2;
        width:380px;
        max-width: 100%;

        &.show{
            max-height: 500px;
            transition: max-height 0.3s ease-in;
            box-shadow: 0px 0px 25px rgba(0,0,0,0.25);
        }
        &.hide{
            transition: max-height 0.3s ease-out;
            max-height: 0;
        }
    }
    &-close {
        position: absolute;
        right:32px;
        top:32px;
        cursor: pointer;
        svg {
            width: 24px;
            height: 24px;
            opacity: .6;
            transition: all ease-in-out .2s;
            &:hover {
                opacity: 1;
            }
        }
    }

    &-inner{
        transition: opacity 0.25s linear;
        transition-delay:0.5s;
        opacity: 0;
        .show & {
            opacity:1;
        }
    }
    &-container{
        padding:72px 36px 48px;
        border:1px solid rgba(255,255,255,0.05);
        

    }
    &-content{
        span {
            font-family: Eina03;
            font-size: 14px;
            color: #fff;
    
                a {
                    text-decoration: underline;
                    color:#47ccdf;
                }
        }
    }
    &-buttons{
      button{
        padding:16px 36px;
        background-color: #47ccdf;
        color:#282828;
        font-family: Eina01;
        font-weight: bold;
        font-size: 16px;
        letter-spacing: -0.025em;
        transform:scale(1);
        transition: all ease-in-out .2s;
        &:hover {
            transform:scale(1.1);
            background-color: #fff;
            color:#282828
        }
        &.link{
            background-color: transparent;
            color:#fff;
            text-decoration: underline;
            &:hover {
                color:#47ccdf;
            }
        }
      }
    }
   
}
