.product-list-page{
  &-bg{}

  &-header {
    padding:200px 0 100px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#282828;
    .parent {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, auto);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      .div1 { grid-area: 2 / 1 / 3 / 5; }
      .div2 { grid-area: 3 / 1 / 4 / 5; }
        @media screen and (min-width: 1024px) {
          grid-template-columns: repeat(7, 1fr);
          grid-template-rows: repeat(4, auto);
          
          .div1 { grid-area: 2 / 2 / 3 / 6; }
          .div2 { grid-area: 3 / 4 / 4 / 7; }
    
        }
      }
      
     

     

      &__title{
        font-size:48px;
        font-family: Eina01-Bold;
        color:#282828;
        letter-spacing: -0.05em;
        line-height: 1;
        span.shine{
          color:#aaa;
        }
        @media screen and (min-width: 768px) {
          font-size:64px;
        }
        @media screen and (min-width: 1024px) {
          font-size:96px;
        }
        @media screen and (min-width: 1280px) {
          font-size:128px;
        }
      }
      &__content{
        &-desc{
          font-size:24px;
          font-weight: 600;
          font-family: Eina01;
          letter-spacing: -0.05em;
          line-height: 1.3em;
          color:rgba(#282828, 0.5);
          margin-top:32px;
          margin-bottom:32px;
          @media screen and (min-width: 1024px) {
            font-size:24px;
          }
          @media screen and (min-width: 1280px) {
            font-size:28px;
          }
        }
        &-cta{
          margin-top:24px;
          button {
            padding:16px 48px;
            font-size:16px;
            font-weight: 600;
            font-family: Eina01;
            color:#fff;
            background-color: #282828;
            transition:all ease .2s;
            transform:scale(1);
            &:hover {
              color:#282828;
              background-color: #47ccdf;
              transform: scale(1.05);
            }
          }
        }
      }
  }
  &-content{
    
    
    &__list{
     

      &-item {
        
        padding:100px 0px;
          min-height: 100vh;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(4, auto);
          grid-column-gap: 0px;
          grid-row-gap: 0;
          position: relative;
          z-index: 1;

          @media screen and (min-width: 1024px) {
            grid-template-columns: repeat(8, 1fr);
            grid-template-rows: auto;
          }
          &__wrapper{
            position: relative;
            background-color: #282828;
            border-top:1px solid rgba(255,255,255, 0.1);
            &:nth-child(1) {
              background-color: #282828;
              .solution-list-content__list-item__image{
                grid-area: 1 / 2 / 2 / 4;

                img {
                  border:1px solid rgba(255,255,255,0.05)
                }
                
              }
              .solution-list-content__list-item__content{
                grid-area: 1 / 5 / 2 / 8;
                //padding-left:96px;
                &-title{
                  color:#fff;
                }
                &-desc{
                  color:rgba(255,255,255,0.75);
                }
                button {
                  color:#282828;
                  background-color: #47ccdf;
                  border:2px solid transparent;
                  &:hover {
                    color:#47ccdf;
                    background-color: transparent;
                    box-shadow: 0px 0px 10px rgba(0,0,0,0.05);
                    border-color: #47ccdf;
                  }
                }
              }
              &:before {
                left:0;
                //background-color: #47ccdf;
              }
            }
            &:before{
              position: absolute;
              content:"";
              width:50%;
              right:0;
              top:0;
              //background-color: #282828;
              height: 100%;
              z-index: 0;
            }
          }
       
        &__content{
          grid-area: 2 / 1 / 3 / 5;
          @media screen and (min-width: 1024px) {
          grid-area: 1 / 2 / 2 / 5;
            
          }
          display: flex;
          flex-direction: column;
          gap: 48px;
          justify-content: center;
         
          @media screen and (min-width: 1024px) {
            max-width: 80%;
          }
          &-title{
            font-size:32px;
            font-family: Eina01-Bold;
            color:#fff;
            letter-spacing: -0.05em;
            line-height: 1;
            @media screen and (min-width: 1024px) {
                font-size:36px;
            }
            @media screen and (min-width: 1280px) {
              font-size:48px;
            }
            @media screen and (min-width: 1440px) {
              font-size:52px;
            }
          }
          &-desc{
            font-size:16px;
            font-family: Eina01;
            letter-spacing: -0.05em;
            line-height: 1.3em;
            font-weight: bold;
            color:rgba(255,255,255,0.5);
            @media screen and (min-width: 768px) {
              font-size:18px;
            }
            @media screen and (min-width: 1024px) {
              font-size:16px;
            }
            @media screen and (min-width: 1280px) {
              font-size:20px;
            }

            @media screen and (min-width: 1440px) {
              font-size:21px;
            }
          }
         
          &-cta{
            button {
              padding:16px 48px;
              font-size:16px;
              font-weight: 600;
              font-family: Eina01;
              color:#fff;
              background-color: transparent;
              transition:all ease .2s;
              transform:scale(1);
              border:2px solid #fff;
              &:hover {
                color:#282828;
                background-color: #fff;
                transform: scale(1.05);
                box-shadow: 0px 0px 10px rgba(0,0,0,0.05);
                border-color: #fff;
              }
            }
          }
        }
        &__image{
          grid-area: 1 / 1 / 2 / 5;
          @media screen and (min-width: 1024px) {
            grid-area: 1 / 5 / 2 / 8;
          }


          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          margin-bottom:64px;
          @media screen and (min-width: 1024px) {
           margin-bottom:0px;
           }
         
          img{
          background-color: #fff;

            box-shadow: 0px 0px 50px rgba(0,0,0,0.05);
            border:1px solid rgba(0,0,0,0.05);
            position: relative;
            z-index: 1;
            transition:all ease .2s;
            transform:scale(1);
            width: 100%;
            &:hover {
              box-shadow: 0px 0px 50px rgba(0,0,0,0.25);
              transform:scale(1.2);
            }
            
          }
        }
      }
    }
  }
}