.security {
  &-coverage {
    padding: 100px 0px;
    background-color: rgba(248, 248, 249, 1);

    @media screen and (min-width: 1024px) {
      padding: 200px 0px 100px;
    }

    &__wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;

      @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(8, 1fr);

      }

    }

    &__inner {
      grid-area: 1 / 1 / 2 / 5;

      @media screen and (min-width: 1024px) {
        grid-area: 1 / 2 / 2 / 8;
      }
    }

    &__title {
      font-size: 32px;
      color: #282828;
      font-family: Eina01-Bold;
      letter-spacing: -0.05em;
      line-height: 1.1em;
      text-align: center;
      margin-bottom: 32px;

      @media screen and (min-width: 1024px) {
        font-size: 48px;
      }

      @media screen and (min-width: 1280px) {
        font-size: 72px;
      }

    }

    &__desc {
      font-size: 16px;
      color: rgba(#282828, 0.6);
      font-family: Eina01-Bold;
      letter-spacing: -0.025em;
      line-height: 1.3em;
      text-align: center;
      margin-top: 32px;

      @media screen and (min-width: 1024px) {
        font-size: 21px;
        margin-top: 0px;
        max-width: 60%;
        margin: 32px auto 0;
      }

      @media screen and (min-width: 1280px) {
        font-size: 32px;
        margin-top: 0px;
      }

    }

    &__content {

      margin: 32px 0px 0px;
      padding: 64px 24px 0px;

      @media screen and (min-width: 1280px) {
        margin: 64px 0px 0px;
        padding: 64px 0px;

      }

      &__wrapper {}

      &-title {
        font-size: 32px;
        color: #282828;
        font-family: Eina01-Bold;
        letter-spacing: -0.025em;
        line-height: 1.3em;
        margin-bottom: 0px;
        text-align: center;
      }

      &-desc {
        font-size: 24px;
        color: #aaa;
        font-family: Eina01;
        font-weight: bold;
        letter-spacing: -0.025em;
        line-height: 1.3em;
        margin-top: 16px;
        text-align: center;

        @media screen and (min-width: 1024px) {
          max-width: 60%;
          margin: 32px auto 0;

        }
      }

      &-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto;
        grid-column-gap: 16px;
        grid-row-gap: 16px;

        @media screen and (min-width: 1280px) {
          grid-template-columns: repeat(1, 1fr);
          grid-row-gap: 16px;
        }

        &__wrapper {
          margin: 0px 0px 0px;
        }

        &__item {
          color: #282828;
          font-family: Eina01;
          font-weight: bold;
          letter-spacing: -0.025em;
          line-height: 1.3em;
          text-align: center;
        }
      }
    }

  }
}