
.bg-footer {
    background-color: #fff;
    border-top:1px solid rgba(0,0,0,0.05);
    position: relative;
    color:#282828;
  
}

.footer {
    padding-top:64px;
    display: flex;
    @media screen and (min-width: 1024px) { 
        padding-top:128px;
    }
    .parent {
        display: grid;
        grid-template-columns: repeat(4, minmax(25%, 1fr));
        grid-template-rows: repeat(8, auto);
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        .div1 { grid-area: 1 / 1 / 2 / 5; }
        .div2 { grid-area: 2 / 1 / 3 / 5; }
        .div3 { grid-area: 4 / 1 / 5 / 5; }
        .div4 { grid-area: 7 / 1 / 8 / 5; }
        .div5 { grid-area: 3 / 1 / 4 / 3; }
        .div6 { grid-area: 3 / 3 / 4 / 5; }
        .div7 { grid-area: 5 / 1 / 6 / 5; }
        .div8 { grid-area: 6 / 1 / 7 / 5; }




        @media screen and (min-width: 640px) {
        grid-template-columns: repeat(4, minmax(128px, 1fr));
        }

        @media screen and (min-width: 768px) {
        .div1 { grid-area: 1 / 1 / 2 / 2; }
        .div2 { grid-area: 1 / 2 / 2 / 5; }
        .div3 { grid-area: 3 / 1 / 4 / 5; }
        .div4 { grid-area: 5 / 1 / 6 / 5; }
        .div5 { grid-area: 2 / 1 / 3 / 3; }
        .div6 { grid-area: 2 / 3 / 3 / 5; }
        .div7 { grid-area: 4 / 1 / 5 / 3; }
        .div8 { grid-area: 4 / 3 / 5 / 5; }
        grid-template-columns: repeat(4, minmax(153.39px, 1fr));
      

        }
        @media screen and (min-width: 1024px) {
        grid-template-rows: repeat(8, 0.5fr);
        grid-template-columns: repeat(8, minmax(128px, 1fr));
       
        .div1 { grid-area: 2 / 2 / 3 / 4; }
        .div2 { grid-area: 2 / 5 / 3 / 8; }
        .div3 { grid-area: 4 / 2 / 6 / 4; }
        .div4 { grid-area: 7 / 2 / 8 / 4; }
        .div5 { grid-area: 4 / 5 / 6 / 6; }
        .div6 { grid-area: 4 / 7 / 6 / 8; }
        .div7 { grid-area: 7 / 5 / 8 / 6; }
        .div8 { grid-area: 7 / 7 / 8 / 8; }

        }
        @media screen and (min-width: 1280px) {        
        grid-template-columns: repeat(8, minmax(160px, 1fr));
        .div1 { grid-area: 2 / 1 / 3 / 3; }
        .div2 { grid-area: 2 / 4 / 3 / 9; }
        .div3 { grid-area: 4 / 1 / 6 / 3; }
        .div4 { grid-area: 7 / 1 / 8 / 3; }
        .div5 { grid-area: 4 / 4 / 6 / 6; }
        .div6 { grid-area: 4 / 7 / 6 / 9; }
        .div7 { grid-area: 7 / 4 / 8 / 6; }
        .div8 { grid-area: 7 / 7 / 8 / 9; }

        }
        @media screen and (min-width: 1600px) {
        grid-template-columns: repeat(8, minmax(200px, 1fr));
        .div1 { grid-area: 2 / 1 / 3 / 3; }
        .div2 { grid-area: 2 / 4 / 3 / 9; }
        .div3 { grid-area: 4 / 1 / 6 / 3; }
        .div4 { grid-area: 7 / 1 / 8 / 3; }
        .div5 { grid-area: 4 / 4 / 6 / 6; }
        .div6 { grid-area: 4 / 7 / 6 / 9; }
        .div7 { grid-area: 7 / 4 / 8 / 6; }
        .div8 { grid-area: 7 / 7 / 8 / 9; }
        }


        }
  

    &-logo{
        display: flex;
        align-items: center;
        height: 100%;
        &__wrapper{
            margin:32px 0px;
            @media screen and (min-width: 1024px) { 
                margin:0;

            }
        }
       
    }
    &-cta-slogan{
        display: flex;
        align-items: center;
        height: 100%;
        &__content {
            font-family: Eina01-Bold;
            font-size:24px;
            @media screen and (min-width: 1024px) { 
                max-width:360px;
 
            }

            @media screen and (min-width: 1280px) { 
                max-width:480px;
 
            }

            font-weight: bold;
            color:rgba(#202020,0.5);
            @media screen and (min-width: 1280px) { 
                font-size:36px;

            }
        }
        &__wrapper{
            margin:32px 0px;
            @media screen and (min-width: 1024px) { 
                margin:0;

            }
        }
    }
    &-newsletter-block{
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        height: 100%;
        &__title{
            font-family: Eina01-Bold; 
            font-size:21px;
            font-weight: bold;
        }
        &__component{
            .input-wrapper {
                width: 100%;
                height: 60px !important;
                display: flex;
                justify-content: space-between;
                position: relative;
                
                input {
                  display: inline;
                  background-color: transparent;
                  width: 100%;
                  font-family: Eina01;
                  color:#282828;
                  border-bottom:1px solid rgba(0,0,0,0.1);
                  font-size: 16px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  
                  @media screen and (min-width: 1280px) {
                    font-size: 24px;
                  }
                  color: rgba(255,255,255,0.5);
                  &::placeholder {
                    padding-left: 0px !important;
                    margin-left: 0px !important;
                  }
                  &:focus {
                    outline: none;
                    color: black;
                  }
                  &:invalid {
                    color: #fa6400;
                  }
                  &:valid {
                    color: #333;
                  }
                }
                button {
                  display: inline;
                  width: 60px !important;
                  height: 60px;
                  background-color: #181818;
                  display: flex;
                  align-items: center;
                  transition:transform .2s ease;
                  justify-content: center;
                  &:focus,
                  &:hover,
                  &:active {
                    outline: 0;
                    transform: scale(1.05);
                  }
                }
              }
              p.error-message {
                color: #fa6400;
                font-family: Eina01;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.5px;
                margin-top: 10px;
                @media screen and (max-width: 768px) {
                  bottom: -65%;
                  font-size: 10px;
                  z-index: 1;
                  position: relative;
                }
                //position: relative;
                //z-index: 1;
              }
          
              p.response-message {
                color: #7aac46;
                font-family: Eina01;
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                margin-top: 10px;
                @media screen and (max-width: 768px) {
                  bottom: -65%;
                  font-size: 10px;
                  z-index: 1;
                  position: relative;
                }
                //position: relative;
                //z-index: 1;
              }

        }
        &__wrapper{
            margin:32px 0px;
            @media screen and (min-width: 1024px) { 
                margin:0;

            }
        }
    }
    &-stay-in-touch{
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        height: 100%;
        &__title{
            font-family: Eina01-Bold; 
            font-size:11px;
            font-weight: bold;
            max-width:100%;
            @media screen and (min-width: 1024px) { 
                max-width:75%;

            }
        }
        &__wrapper{
            margin:32px 0px;
            @media screen and (min-width: 1024px) { 
                margin:0;

            }
        }
    }
    &-navigate-menu{
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        height: 100%;
        &__title{
            font-family: Eina01-Bold; 
            font-size:21px;
            font-weight: bold;
        }
        &__item{
                width:100%;
                display: inline-flex;
                justify-content: left;
                height:auto;
                margin:16px 0 0;
                @media screen and (min-width: 768px) {
                width:100%;
                }
                @media screen and (min-width: 1280px) {
                width:50%;
                margin:24px 0 0;
                }

                @media screen and (min-width: 1440px) {
                width:33.3%;
                margin:32px 0 0;
                }

                a {
                    color:rgba(#28292B, 0.5);
                    font-size:12px;
                    font-weight: bold;
                    transition:color ease .2s;
                    &:hover {
                        color:#282828;
                    }

                }
            
        }
        &__wrapper{
            margin:32px 0px;
            @media screen and (min-width: 1024px) { 
                margin:0;

            }
        }
    }
    &-resource-menu{
        &__wrapper{
            margin:32px 0px;
            @media screen and (min-width: 1024px) { 
                margin:0;

            }
        }
    }
    &-terms {
        
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;

       


        &__title {
            font-family: Eina01-Bold; 
            font-size:21px;
            font-weight: bold;
        }
        &-menu{
            &__list{
                display: flex;
                flex-direction: column;
                @media screen and (min-width: 1280px) {
                    gap:24px;
                    flex-direction: row;
                }
                &-item{
                    a {
                        color:rgba(#28292B, 0.5);
                        font-size:12px;
                        text-decoration: underline;
                        transition:color ease .2s;
                        &:hover {
                            color:#282828;
                        }
        
                    }
                }
            }
        }
       
       
        &__wrapper{
            margin:32px 0px;
                @media screen and (min-width: 768px) { 
                    margin:0;
                }
        }
    }
    &-social-media{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        &__title{
            font-family: Eina01-Bold; 
            font-size:21px;
            font-weight: bold;
        }
        &__wrapper{
            .footer-social-media__list {
                display: flex;
                flex-direction: row;
                gap:8px;
                @media screen and (min-width: 1280px) {
                gap:16px;
                }
                &-item{
                    display: inline-flex;
                    border:2px solid rgba(#282828,1);
                    cursor: pointer;
                    svg {
                        path{
                            fill:rgba(#282828, 1);
                        }
                        rect{
                            fill:rgba(#fff, 0);
                        }
                    }
                    &:hover {
                        svg {
                            path{
                                fill:rgba(#fff, 1);
                            }
                            rect{
                                fill:rgba(#282828, 1);
                            }
                        }
                    }
                }
            }

                margin:32px 0px;
                @media screen and (min-width: 768px) { 
                    margin:0;
                }
           
        }
    }

    &-bottom {
        &__wrapper {
            padding:16px 0px;
            background-color: #282828;
            font-size: 12px;
            color:#fff;
            font-weight: bold;
            text-align: center;
        }
    }


}