.updates-page{
  &-bg{}

  &-header {
    padding:200px 0 100px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#282828;
    border-bottom:1px solid rgba(0,0,0,0.05);
    .parent {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, auto);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      .div1 { grid-area: 2 / 1 / 3 / 5; }
      .div2 { grid-area: 3 / 1 / 4 / 5; }
        @media screen and (min-width: 1024px) {
          grid-template-columns: repeat(8, 1fr);
          grid-template-rows: repeat(4, auto);
          
          .div1 { grid-area: 2 / 2 / 3 / 6; }
          .div2 { grid-area: 3 / 4 / 4 / 7; }
    
        }
      }
      
     

     

      &__title{
        font-size:48px;
        font-family: Eina01-Bold;
        color:#282828;
        letter-spacing: -0.05em;
        line-height: 1;
        span.shine{
          color:#aaa;
        }
        @media screen and (min-width: 768px) {
          font-size:64px;
        }
        @media screen and (min-width: 1024px) {
          font-size:96px;
        }
        @media screen and (min-width: 1280px) {
          font-size:128px;
        }
      }
      &__content{
        &-desc{
          font-size:24px;
          font-weight: 600;
          font-family: Eina01;
          letter-spacing: -0.05em;
          line-height: 1.3em;
          color:rgba(#282828, 0.5);
          margin-top:32px;
          margin-bottom:32px;
          @media screen and (min-width: 1024px) {
            font-size:28px;
          }
          @media screen and (min-width: 1280px) {
            font-size:32px;
          }
          span.date{
            font-size:16px;
            display: block;
            color: #47ccdf;
            font-family: Eina01-Bold;
            letter-spacing: -0.025em;
          }
        }
        &-cta{
          margin-top:24px;
            font-size:16px;
            font-weight: 600;
            font-family: Eina01;
            color:#282828;
            transition:all ease .2s;
            transform:scale(1);
            text-decoration: underline;
            display: flex;
            gap: 8px;
            &:hover {
              color:#282828;
              transform: scale(1.05);
            }
        }
      }
  }
  &-content{
    padding:100px 0;
      &--bg-light{
        background-color: #f7f7f7;
      }
    
    &__list{
     

      &-item {
        
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(1, auto);
          grid-column-gap: 0px;
          position: relative;
          z-index: 1;
          background-color: #fff;
          box-shadow: 0px 0px 25px rgba(0,0,0,0.1);
          padding:64px;
          transition: all ease .2s;
          transform:scale(1);
          cursor: pointer;
          position: relative;
          z-index: 1;
          &:before{
            position: absolute;
            content:"";
            width:32px;
            height: 32px;
            left:0;
            top:0;
            background-color: #47ccdf;
            z-index: -1;
            transition: all ease .2s;
          }
          &:after{
            position: absolute;
            //content:"";
            width:16px;
            height: 16px;
            left:24px;
            top:0px;
            background-color: #282828;
            z-index: -1;
            transition: all ease .2s;
          }
          &:hover {
            transform:scale(1.05);
            box-shadow: 0px 0px 25px rgba(0,0,0,0.25);
            .updates-page-content__list-item__content-cta-link{
              color:#282828;
                .icon {
                 transform:rotate(45deg);
                 top: 0px;
                  left: 8px;
                  svg {
                    fill:#282828;
                  }
                }
                .label {
                  top: 0px;
                  left: 2px;
                 }
            }
            &:before{
              width:48px;
              height: 48px;
            }
            &:after{
              width:24px;
              height: 24px;
            }
          }

          @media screen and (min-width: 1024px) {
            grid-template-columns: repeat(8, 1fr);
            grid-template-rows: auto;
            padding:64px 0;
          }
          &__wrapper{
            position: relative;
            background-color: #282828;
            margin:64px 0;
            //border-top:1px solid rgba(0,0,0, 0.1);
            /*&:nth-child(1) {
              background-color: #282828;
              .reports-page-content__list-item__image{
                grid-area: 1 / 2 / 2 / 4;

                img {
                  border:1px solid rgba(255,255,255,0.05)
                }
                
              }
              .reports-page-content__list-item__content{
                grid-area: 1 / 5 / 2 / 8;
                //padding-left:96px;
                &-title{
                  color:#fff;
                }
                &-desc{
                  color:rgba(255,255,255,0.75);
                }
                button {
                  color:#282828;
                  background-color: #47ccdf;
                  border:2px solid transparent;
                  &:hover {
                    color:#47ccdf;
                    background-color: transparent;
                    box-shadow: 0px 0px 10px rgba(0,0,0,0.05);
                    border-color: #47ccdf;
                  }
                }
              }
              &:before {
                left:0;
                //background-color: #47ccdf;
              }
            }*/
            &:before{
              position: absolute;
              content:"";
              width:50%;
              right:0;
              top:0;
              //background-color: #282828;
              height: 100%;
              z-index: 0;
            }
          }
       
        &__content{
          grid-area: 2 / 1 / 2 / 5;
          @media screen and (min-width: 1024px) {
            grid-area: 1 / 2 / 2 / 7;
            
          }
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 24px;
         
          
          &-title{
            font-size:24px;
            font-family: Eina01-Bold;
            color:#282828;
            letter-spacing: -0.05em;
            line-height: 1;
            
            @media screen and (min-width: 1024px) {
                font-size:32px;
                max-width: 640px;
            }
            @media screen and (min-width: 1280px) {
              font-size:48px;
            }
            @media screen and (min-width: 1440px) {
              font-size:42px;

            }
          }
          &-desc{
            font-size:16px;
            font-family: Eina01;
            letter-spacing: -0.05em;
            line-height: 1.3em;
            font-weight: bold;
            color:rgba(0,0,0,0.5);
            @media screen and (min-width: 768px) {
              font-size:18px;
            }
            @media screen and (min-width: 1024px) {
              font-size:16px;
            }
            @media screen and (min-width: 1280px) {
              font-size:20px;
            }

            @media screen and (min-width: 1440px) {
              font-size:21px;
            }
          }
         
          &-cta{
            &-link{
              font-size:16px;
              line-height: 1.5em;
              letter-spacing:-0.025em;
              font-family: Eina01-Bold;
              color:#47ccdf;
              display: flex;
              cursor:pointer;
              &:hover {
                color:#282828;
                .icon {
                 transform:rotate(45deg);
                 top: 0px;
                  left: 8px;
                  svg {
                    fill:#282828;
                  }
                }
                .label {
                  top: 0px;
                  left: 2px;
                 }
              }
              .label {
                position: relative;
                transform-origin: center center;
                left:0;
                transition: all ease .2s;
                
              }
              .icon{
                position: relative;
                top: -2px;
                left: 4px;
                transition: all ease .2s;
                transform-origin: center center;
                svg {
                  fill:#47ccdf;
                }
              }
              
            }
          }
        }

        &__date{
          grid-area: 1 / 1 / 2 / 5;
          display: flex;
          flex-direction: column;
          justify-content: center;
          

          @media screen and (min-width: 1024px) {
            grid-area: 1 / 7 / 2 / 8; 
          }

          &-content{
            color:#aaa;
            font-family: Eina01-Bold;
            font-size:16px;
            letter-spacing: -0.05em;
            margin-bottom: 16px;
           
            @media screen and (min-width: 1024px) {
              grid-area: 1 / 7 / 2 / 8; 
              width: 128px;
              height: 128px;
              font-size:32px;
              letter-spacing: -0.05em;
              margin-bottom: 0;
            }
  
          }
         
        }
      
      }
    }


    &__details {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(8, 1fr);
      }
      &-content{
        padding:128px 0px;
        grid-area: 1 / 1 / 2 / 5;
        @media screen and (min-width: 1024px) {
          grid-area: 1 / 2 / 2 / 8; 
        }
        font-size:24px;
          font-weight: 600;
          font-family: Eina01;
          letter-spacing: -0.05em;
          line-height: 1.3em;
          color:rgba(#282828, 0.5);

        p {
          font-size:24px;
          font-weight: 600;
          font-family: Eina01;
          letter-spacing: -0.05em;
          line-height: 1.3em;
          color:rgba(#282828, 0.5);
          margin-bottom:32px;
          b, strong {
            color:rgba(#282828, 1);
            text-decoration: underline;
          }
        }
        ul {
          margin-bottom:32px;
          list-style: square;
          list-style-position: inside;
          li{
            font-size:24px;
            list-style-type: square;
          font-weight: 600;
          font-family: Eina01;
          letter-spacing: -0.05em;
          line-height: 1.3em;
          color:rgba(#282828, 1);
          }
        }
      }
    }
  }
}